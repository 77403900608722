<script setup lang="ts">

import { useGameStore } from "@/stores/gameStore.ts";
import { useShipStore } from '@/stores/shipStore.ts';
import { computed, ref } from 'vue';
import MapSelectPlayerShips from '@/components/Interface/Modal/Models/SelectPlayerShips/MapSelectPlayerShips.vue'
import MapSelectPlayerFleets from '@/components/Interface/Modal/Models/SelectPlayerShips/MapSelectPlayerFleets.vue'
import LargeWideModel from '@/components/Interface/Modal/ModelSizes/LargeWideModel.vue'

// Get the planet data
const gameStore = useGameStore();
const shipStore = useShipStore();

const currentTab = ref('ships');

const tabStats = computed(() => {
  let stats = { fleets: 0, ships: 0 };
  let fleets = {}

  for (let ship of shipStore.ships.values()) {
    if(ship.xPos !== gameStore.modalData.xPos || ship.yPos !== gameStore.modalData.yPos) {
      continue;
    }
    if(ship.fleetId == 0) {
      stats.ships++
    } else {
      fleets[ship.fleetId] = true;
    }
  }

  stats.fleets = Object.keys(fleets).length;

  if(stats.fleets > 0) {
    currentTab.value = 'fleets';
  }

  return stats;
});


</script>

<template>
  <LargeWideModel :title="'Select ships or a fleet @ ' + gameStore.modalData.xPos + 'x' + gameStore.modalData.yPos">
    <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
      <ul class="flex flex-wrap">
        <li class="me-2">
          <a href="#"
             :class="{
               'active': currentTab === 'ships',
               'text-blue-500 border-blue-500': currentTab === 'ships',
               'text-gray-500 border-transparent': currentTab !== 'ships',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
             @click="currentTab = 'ships'">
            Ships <span v-if="tabStats.ships>0" class="text-white">({{ tabStats.ships }})</span>
          </a>
        </li>
        <li class="me-2">
          <a href="#"
             :class="{
               'active': currentTab === 'fleets',
               'text-blue-500 border-blue-500': currentTab === 'fleets',
               'text-gray-500 border-transparent': currentTab !== 'fleets',
               'inline-block p-4 border-b-2 rounded-t-lg  hover:border-gray-300 hover:text-gray-300': true
           }"
             @click="currentTab = 'fleets'">
            Fleets <span v-if="tabStats.fleets>0" class="text-white">({{ tabStats.fleets }})</span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="currentTab === 'ships'">
      <MapSelectPlayerShips />
    </div>
    <div v-else-if="currentTab === 'fleets'">
      <MapSelectPlayerFleets />
    </div>
  </LargeWideModel>
</template>

<style scoped lang="scss">

</style>
