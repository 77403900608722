<script setup lang="ts">

import ShipStatIcons from '@/components/Objects/IconsWithCounters/ShipStatIcons.vue'
import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'
import { computed } from 'vue'

const shipDesignStore = useShipDesignCreationStore()

//Calculate the percentage of the ship size in use (make reactive)
const shipSizePercentage = computed(() => {
  if(shipDesignStore.shipFrame === 0) return 0
  const percentage = (shipDesignStore.shipMass / shipDesignStore.shipFrame) * 100
  if(percentage > 100) return 100
  return percentage
})

</script>

<template>
  <!-- Ship size -->
  <div class="p-2">
    <div class="block mb-2 text-sm font-medium text-white">Ship size</div>
    <div class="flex">
      <div class="pr-2">
        <ShipStatIcons type="ship_frame" :amount="shipDesignStore.shipFrame" />
      </div>
      <div class="flex-grow mt-1">
        <div class="w-full rounded-full h-4 mb-4 bg-gray-700">
          <div :class="{
            'bg-blue-600': shipDesignStore.shipFrame > shipDesignStore.shipMass,
            'bg-red-600': shipDesignStore.shipFrame < shipDesignStore.shipMass,
            'bg-green-600': shipDesignStore.shipFrame === shipDesignStore.shipMass,
            'h-4 rounded' : true
          }" :style="'width: '+ shipSizePercentage +'%'"></div>
        </div>
      </div>
      <div class="pl-2">
        <ShipStatIcons type="ship_mass" :amount="shipDesignStore.shipMass" />
      </div>
    </div>
  </div>
  <!-- Ship combat -->
  <div class="p-2">
    <div class="block mb-2 text-sm font-medium text-white">Combat</div>
    <div class="grid grid-cols-2 xl:grid-cols-4 gap-2">
      <div class="bg-slate-900 text-subtext text-center p-2">
        Attack vs Ships
        <div class="flex justify-center p-5">
          <ShipStatIcons type="ship_attack_ships" :amount="shipDesignStore.shipAttackShips" />
        </div>
      </div>
      <div class="bg-slate-900 text-center text-subtext p-2">
        Attack vs Planets
        <div class="flex justify-center p-5">
          <ShipStatIcons type="ship_attack_planets" :amount="shipDesignStore.shipAttackPlanets" />
        </div>
      </div>
      <div class="bg-slate-900 text-center text-subtext p-2">
        Shields
        <div class="flex justify-center p-5">
          <ShipStatIcons type="ship_shields" :amount="shipDesignStore.shipShields" />
        </div>
      </div>
      <div class="bg-slate-900 text-center text-subtext p-2">
        Hit points
        <div class="flex justify-center p-5">
          <ShipStatIcons type="ship_hit_points" :amount="shipDesignStore.shipHitPoints" />
        </div>
      </div>
    </div>
  </div>
  <!-- Misc -->
  <div class="p-2">
    <div class="block mb-2 text-sm font-medium text-white">Miscellaneous</div>
    <div class="grid grid-cols-2 xl:grid-cols-4 gap-2">
      <div class="bg-slate-900 text-subtext text-center p-2">
        Speed
        <div class="flex justify-center p-5">
          <ShipStatIcons type="ship_max_speed" :amount="shipDesignStore.shipMaxSpeed" />
        </div>
      </div>
      <div class=" bg-slate-900 text-center text-subtext p-2">
        Scanning range
        <div class="flex justify-center p-5">
          <ShipStatIcons type="ship_scanning_range" :amount="shipDesignStore.shipScanningRange" />
        </div>
      </div>
      <div class=" bg-slate-900 text-center text-subtext p-2">
        Repair
        <div class="flex justify-center p-5">
          <ShipStatIcons type="ship_repair" :amount="shipDesignStore.shipRepair" />
        </div>
      </div>
      <div class=" bg-slate-900 text-center text-subtext p-2">
        Production cost
        <div class="flex justify-center p-5">
          <ShipStatIcons type="ship_production_cost" :amount="shipDesignStore.shipProductionCost" />
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

</style>