import { Camera, type Intersection } from 'three'
import * as THREE from 'three'
import { useMapStore } from '@/stores/mapStore.ts'
import { nextTick } from 'vue'
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useMapShipStore } from '@/stores/mapShipStore.ts'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'
import { useMapPlanetOwnerStore } from '@/stores/mapPlanetOwnerStore.ts'
import { zoomFactor } from '@/enums/zoomFactor.ts'
import { MapShipActionPaths } from '@/models/Map/MapShipActionPaths.ts'
import { useTableStore } from '@/stores/tableStore.ts'
import { MapCrossHairUpdate } from '@/models/Map/MapCrosshair.ts'

export class MapMouseController {

  isDragging: boolean;
  startMousePosition = { x: 0, y: 0 };
  dragMousePosition = { x: 0, y: 0 };
  container: HTMLElement;
  camera: Camera;
  scene: THREE.Scene;
  raycaster: THREE.Raycaster;
  mouse: THREE.Vector2;
  mapStore: any;
  gameStore: any;
  tableStore: any;
  shipActionPaths: any;
  hoverXpos: number;
  hoverYpos: number;
  shipActionStore: any;
  mapPlanetOwnerStore: any;
  mapCoordinateStore: any;
  mapShipStore: any
  renderer: THREE.WebGLRenderer;
  isTouchDragging: boolean;
  lastTouchPosition: { x: number, y: number };

  constructor(
      container: HTMLElement,
      camera: THREE.Camera,
      scene: THREE.Scene,
      renderer: THREE.WebGLRenderer
  ) {
    this.container = container;
    this.camera = camera;
    this.scene = scene;
    this.renderer = renderer;
    this.isDragging = false;
    this.isTouchDragging = false;
    this.lastTouchPosition = { x: 0, y: 0 };

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();

    this.mapStore = useMapStore();
    this.gameStore = useGameStore();
    this.shipActionStore = useShipActionStore();
    this.mapPlanetOwnerStore = useMapPlanetOwnerStore();
    this.mapCoordinateStore = useMapCoordinateStore();
    this.mapShipStore = useMapShipStore();
    this.tableStore = useTableStore();

    // Ship Action paths, for when hovering over ships (to show their paths)
    this.shipActionPaths = new MapShipActionPaths(this.scene)
    this.hoverXpos = 0;
    this.hoverYpos = 0;


    // Define the area selector box
    const boxVertices = new Float32Array([
      0, 0, 0.09,  // Top-left corner
      0, 0, 0.09,  // Top-right corner
      0, 0, 0.09,  // Bottom-right corner
      0, 0, 0.09,  // Bottom-left corner
      0, 0, 0.09   // Back to Top-left to close the loop
    ]);
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(boxVertices, 3));
    const material = new THREE.LineBasicMaterial({ color: 0x0000ff });
    this.mapStore.areaBox = new THREE.Line(geometry, material);
    this.mapStore.areaBox.visible = false;
    this.mapStore.areaBox.frustumCulled = false;
    this.scene.add(this.mapStore.areaBox)

    // Bind the methods to the instance
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onWheel = this.onWheel.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);

    this.container.addEventListener('mousedown', this.onMouseDown);
    this.container.addEventListener('mouseleave', this.onMouseLeave);
    this.container.addEventListener('mousemove', this.onMouseMove);
    this.container.addEventListener('mouseup', this.onMouseUp);
    this.container.addEventListener('click', this.onClick);
    this.container.addEventListener('wheel', this.onWheel);
    this.container.addEventListener('touchstart', this.onTouchStart, { passive: false });
    this.container.addEventListener('touchmove', this.onTouchMove, { passive: false });
    this.container.addEventListener('touchend', this.onTouchEnd);
  }

  destroy() {
    // Remove event listeners when destroying the instance
    this.container.removeEventListener('mousedown', this.onMouseDown);
    this.container.removeEventListener('mouseleave', this.onMouseLeave);
    this.container.removeEventListener('mousemove', this.onMouseMove);
    this.container.removeEventListener('mouseup', this.onMouseUp);
    this.container.removeEventListener('click',this.onClick);
    this.container.removeEventListener('wheel', this.onWheel);
  }

  onWheel(event: WheelEvent) {
    event.preventDefault(); // Prevent default scrolling behavior

    // Calculate scroll deltas
    const deltaX = event.deltaX * (0.2 / this.mapStore.zoomFactor);
    const deltaY = event.deltaY * (0.2 / this.mapStore.zoomFactor);

    // Apply the deltas to move the camera
    this.camera.position.x -= deltaX;
    this.camera.position.y += deltaY;

    // Update the rendering to reflect the new camera position
    this.renderMap();
  }

  onTouchStart(event: TouchEvent) {
    if (event.touches.length === 1) {
      // Start one-finger dragging
      this.isTouchDragging = true;
      this.lastTouchPosition = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    }
  }

  onTouchMove(event: TouchEvent) {
    if (event.touches.length === 1 && this.isTouchDragging) {
      event.preventDefault(); // Prevent default scrolling behavior

      // Calculate the movement delta
      const touch = event.touches[0];
      const deltaX = (touch.clientX - this.lastTouchPosition.x) * (0.5 / this.mapStore.zoomFactor);
      const deltaY = (touch.clientY - this.lastTouchPosition.y) * (0.5 / this.mapStore.zoomFactor);

      // Update the camera position
      this.camera.position.x -= deltaX;
      this.camera.position.y += deltaY;

      // Store the new position for the next move event
      this.lastTouchPosition = { x: touch.clientX, y: touch.clientY };

      // Update the rendering to reflect the new camera position
      this.renderMap();
    }
  }

  onTouchEnd(event: TouchEvent) {
    if (event.touches.length === 0) {
      // End one-finger dragging
      this.isTouchDragging = false;
    }
  }

  onMouseLeave(event: MouseEvent) {
    this.stopDrag(event)
  }

  onMouseMove(event: MouseEvent) {
    if(this.isDragging) {
      this.dragMap(event)
    }
    this.updateMousePosition(event)
    this.mapMove(event)
    this.areaSelectMove(event)
    this.hoverEvent(event)
  }

  onMouseUp(event: MouseEvent) {
    this.stopDrag(event)
    this.areaSelectEnd(event)
    //this.mapStore.loadVisibleSectors();
  }

  onMouseDown(event: MouseEvent) {
    this.mapStore.playerNameTooltipVisible = false;
    this.startDrag(event);
    this.mapClick(event)// moved to mouse down because of the area selection
  }

  onClick(event: MouseEvent) {
    this.updateMousePosition(event)
  }
  /*************************** Hover *************************/

  hoverEvent(event: MouseEvent) {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);
    let found = false
    for (const intersect of intersects) {
      if(intersect.object.name === 'blackHole') {
        //this.setCursor('pointer');
      }
      const data = this.getCoordinateData(intersect);
      if (data.hasPlayerShips) {
        // Prevent creating a new path if the mouse is still on the same coordinate
        if(this.hoverXpos !== data.xPos || this.hoverYpos !== data.yPos) {
          this.shipActionPaths.create(data.xPos, data.yPos);
          this.hoverXpos = data.xPos;
          this.hoverYpos = data.yPos;
        }
        found = true;
      }
      if(data.isPlanet) {
        //If this is not the player or no player, show tooltip with name
        if(data.planetOwnerId !== 0) {
          // Show tooltip with name
          this.mapStore.playerNameTooltipVisible = true;
          this.mapStore.playerNameTooltipOwnerId = data.planetOwnerId;
          this.mapStore.playerNameTooltipShipCount = data.ships[data.planetOwnerId]
          found = true;
        }
        //Auto select planet if it is the player
        if(data.planetOwnerId == this.gameStore.player.id) {
            this.tableStore.planets.singleSelectedId = data.id;
        }

      } else if(Object.keys(data.ships).length > 0) {
        this.mapStore.playerNameTooltipVisible = true;
        this.mapStore.playerNameTooltipOwnerId = Object.keys(data.ships).pop()
        this.mapStore.playerNameTooltipShipCount = data.ships[this.mapStore.playerNameTooltipOwnerId]
        found = true
      }
    }
    if(this.shipActionPaths !== null && !found) {
      this.shipActionPaths.deleteLines()
      this.hoverXpos = 0;
      this.hoverYpos = 0;
    }
    if(!found) {
      this.mapStore.playerNameTooltipVisible = false;
    }
  }


  /*************************** Move *************************/

  mapMove(event: MouseEvent) {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);

    if(this.shipActionStore.pendingAction !== '') {
      this.mapPendingAction(event,intersects);
    } else {
      this.mapMoveToolTip(intersects)
    }

    this.getLocation(event,intersects)
  }

  getLocation(event: MouseEvent,intersects: Array<Intersection>) {
    for (const intersect of intersects) {
      if ((intersect as any).object.name === 'backPlane') {
        this.mapStore.mouseXPos = Math.ceil((intersect as any).point.x)
        this.mapStore.mouseYPos = Math.ceil((intersect as any).point.y)
        break;
      }
    }
  }

  /**
   * Handle pending action when moving mouse
   *
   * @param event
   * @param intersects
   */
  mapPendingAction(event: MouseEvent,intersects: Array<Intersection>) {
    for (const intersect of intersects) {
      //Detect if this is the backPlane
      if(intersect.object.name === 'backPlane') {
        const data = this.getCoordinateData(intersect);
        if (this.shipActionStore.pendingAction === 'move') {
          if (data.xPos == 0) {
            if (event.shiftKey) {
              // Free Movement Enabled
              this.setCursor('move')
              this.mapStore.shipActionPath.removePendingAction()
              this.mapStore.shipActionPath.setPendingAction((intersect as any).point.x, (intersect as any).point.y);
              this.renderMap()
              break;
            } else {
              // Clear Selection for grid movement
              this.setCursor('default')
              this.mapStore.shipActionPath.removePendingAction()
              this.renderMap()
              break;
            }
          } else {
            // Grid Movement Enabled
            this.setCursor('move')
            this.mapStore.shipActionPath.setPendingAction(data.xPos, data.yPos);
            this.renderMap()
            break;
          }
        } else if (this.shipActionStore.pendingAction === 'follow') {
          if (data.xPos == 0) {
            // Clear Selection for grid movement
            this.setCursor('default')
            this.mapStore.shipActionPath.removePendingAction()
            this.renderMap()
            break;
          } else if(data.catBeFollowed) {
            // Grid Movement Enabled
            this.setCursor('move')
            this.mapStore.shipActionPath.setPendingAction(data.xPos, data.yPos);
            this.renderMap()
            break;
          }
        } else if (this.shipActionStore.pendingAction === 'followAttack') {
          if (data.xPos == 0) {
            // Clear Selection for grid movement
            this.setCursor('default')
            this.mapStore.shipActionPath.removePendingAction()
            this.renderMap()
            break;
          } else if(data.catBeFollowed) {
            // Grid Movement Enabled
            this.setCursor('move')
            this.mapStore.shipActionPath.setPendingAction(data.xPos, data.yPos);
            this.renderMap()
            break;
          }
        } else if (this.shipActionStore.pendingAction === 'explore') {
          break
        } else if (this.shipActionStore.pendingAction === 'repair') {
          break
        } else if (this.shipActionStore.pendingAction === 'attack') {
          if (data.xPos == 0) {
            this.mapStore.shipActionPath.removePendingAction()
            this.renderMap()
          } else {
            // Attack the selected position
            this.mapStore.shipActionPath.setPendingAction(data.xPos, data.yPos);
            this.renderMap()
            this.setCursor('crosshair')
            break;
          }
        } else if (this.shipActionStore.pendingAction === 'settle') {
          if (data.xPos == 0) {
            this.mapStore.shipActionPath.removePendingAction()
            this.renderMap()
          } else if (data.canBeSettled) {
            // Settle the selected position
            this.mapStore.shipActionPath.setPendingAction(data.xPos, data.yPos);
            this.renderMap()
            this.setCursor('crosshair');
            break;
          }
        } else {
          this.setCursor('default')
        }
      }
    }
  }

  /**
   * Display cursor on planes that can do tooltips
   * @param intersects
   */
  mapMoveToolTip(intersects: Array<Intersection>) {
    for (const intersect of intersects) {
      const data = this.getCoordinateData(intersect);
      if (data.hasTooltip) {
        this.setCursor('pointer');
        break;
      } else {
        this.setCursor('default');
      }
    }
  }

  /**
   * Figure out what we are hovering on
   * @param intersect
   */
  getCoordinateData(intersect: Intersection) {
    if((intersect as any).object.name === 'backPlane') {
      const x = Math.ceil((intersect as any).point.x)
      const y = Math.ceil((intersect as any).point.y)
      let radius = 1;
      if(this.mapStore.zoomFactor === zoomFactor.Planet) {
        radius = 1;
      } else if(this.mapStore.zoomFactor === zoomFactor.Solar) {
        radius = 1;
      } else if(this.mapStore.zoomFactor === zoomFactor.Local) {
        radius = 3
      } else if(this.mapStore.zoomFactor === zoomFactor.Sector) {
        radius = 4;
      } else if(this.mapStore.zoomFactor === zoomFactor.Quadrant) {
        radius = 5;
      } else if(this.mapStore.zoomFactor === zoomFactor.Max) {
        radius = 6;
      }
      const list = this.getNearbyCoordinates(x, y, radius);
      for (const coords of list) {
        if (this.mapCoordinateStore.hasByCoordinates(coords.x, coords.y)) {
          return this.mapCoordinateStore.getDataForRayCasting(coords.x, coords.y);  // Exits the loop
        }
      }
      return {
        'id': 0,
        'isPlanet': false,
        'planetOwnerId': 0,
        'canBeSettled': false,
        'hasTooltip': false,
        'hasPlayerShips': false,
        'ships' : {},
        'xPos': 0,
        'yPos': 0,
      }
    } else {
      return {
        'id': 0,
        'isPlanet': false,
        'planetOwnerId': 0,
        'canBeSettled': false,
        'hasTooltip': false,
        'hasPlayerShips': false,
        'ships' : {},
        'xPos': 0,
        'yPos': 0,
      }
    }
  }

  getNearbyCoordinates(x:number, y:number, radius:number) {
    const nearbyCoords = [];

    // Iterate through all possible grid coordinates within the radius
    for (let dx = -radius; dx <= radius; dx++) {
      for (let dy = -radius; dy <= radius; dy++) {
        // Add nearby coordinates to the list
        nearbyCoords.push({ x: x + dx, y: y + dy });
      }
    }

    return nearbyCoords;
  }
  /*************************** click *************************/

  /**
   * Process click on map
   *
   * @param event
   */
  async mapClick(event: MouseEvent) {

    if(event.button === 0) {
      if (this.mapStore.toolTipVisible) {
        this.mapStore.toolTipVisible = false;
        await nextTick();
      }

      if (this.hasDragged(event)) {
        this.stopDrag(event)
      } else {
        if (this.shipActionStore.pendingAction !== '') {
          this.pendingActionClick(event)
        } else {
          if (!this.openToolTip(event)) {
            //no tooltip, so can start the area selection
            this.areaSelectStart(event)
          }
        }
      }
    }
  }

  areaSelectStart(event: MouseEvent) {
    if (event.button !== 0) return;
    if (this.shipActionStore.initialized) return;

    this.mapStore.areaStartTime = Date.now();
    this.mapStore.areaStart = { x: this.mapStore.mouseXPos, y: this.mapStore.mouseYPos }; // Can use mapStore if applicable
    this.mapStore.areaSelecting = true;
    this.mapStore.areaActionSelecting = false;

    // Set initial size of the box
    this.updateAreaBox(this.mapStore.areaStart.x, this.mapStore.areaStart.y, this.mapStore.areaStart.x, this.mapStore.areaStart.y);

    console.log('Area Select Start');
  }

  areaSelectMove(event: MouseEvent) {
    if (!this.mapStore.areaSelecting) return;
    if (this.shipActionStore.initialized) return;

    this.mapStore.areaBox.visible = true;

    this.mapStore.areaEnd = { x: this.mapStore.mouseXPos, y: this.mapStore.mouseYPos };

    // Dynamically resize the box
    this.updateAreaBox(this.mapStore.areaStart.x, this.mapStore.areaStart.y, this.mapStore.areaEnd.x, this.mapStore.areaEnd.y);

    console.log('Area Select Move');
  }

  areaSelectEnd(event: MouseEvent) {
    if (event.button !== 0) return;
    if (!this.mapStore.areaSelecting) return;
    if (this.shipActionStore.initialized) return;

    const elapsedTime = Date.now() - this.mapStore.areaStartTime;

    if (elapsedTime > 200) {
      console.log('Area Select End');
      this.mapStore.areaMouseEnd = { x: event.clientX, y: event.clientY };
      this.mapStore.areaSelecting = false;
      this.mapStore.areaActionSelecting = true;

      // Determine the top-left and bottom-right coordinates
      this.mapStore.areaBoxTL = {
        x: Math.min(this.mapStore.areaStart.x, this.mapStore.areaEnd.x),
        y: Math.max(this.mapStore.areaStart.y, this.mapStore.areaEnd.y)
      };

      this.mapStore.areaBoxRB = {
        x: Math.max(this.mapStore.areaStart.x, this.mapStore.areaEnd.x),
        y: Math.min(this.mapStore.areaStart.y, this.mapStore.areaEnd.y)
      };

      console.log('Area Select Time: ' + elapsedTime);
      console.log('Area Select Start: ' + JSON.stringify(this.mapStore.areaStart));
      console.log('Area Select End: ' + JSON.stringify(this.mapStore.areaEnd));

    } else {
      console.log('Area Select End (Too Fast)');
      this.mapStore.disableAreaSelection()
    }

  }

  updateAreaBox(x1: number, y1: number, x2: number, y2: number) {
    const boxVertices = new Float32Array([
      x1, y1, 1.09,  // Top-left corner
      x2, y1, 1.09,  // Top-right corner
      x2, y2, 1.09,  // Bottom-right corner
      x1, y2, 1.09,  // Bottom-left corner
      x1, y1, 1.09   // Back to Top-left to close the loop
    ]);

    const geometry = this.mapStore.areaBox.geometry as THREE.BufferGeometry;
    geometry.setAttribute('position', new THREE.BufferAttribute(boxVertices, 3));
    geometry.attributes.position.needsUpdate = true; // Ensure the update happens
  }
  pendingActionClick(event: MouseEvent) {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);
    for (const intersect of intersects) {
      if(intersect.object.name === 'backPlane') {
        const data = this.getCoordinateData(intersect);

        if (this.shipActionStore.pendingAction === 'move') {
          // Process Movement
          let x = 0;
          let y = 0;
          if (data.xPos == 0) {
            // Free Movement
            x = Math.ceil((intersect as any).point.x);
            y = Math.ceil((intersect as any).point.y);
          } else {
            // Grid Movement
            x = data.xPos
            y = data.yPos
          }
          this.mapStore.shipActionPath.queuePendingAction(
            x,
            y,
            0,
            0
          );
          this.renderMap()
          break;
        } else if (this.shipActionStore.pendingAction === 'follow') {
          if (data.xPos != 0) {
            this.gameStore.modalType = 'mapFollow'
            this.gameStore.modalData = {
              xPos: data.xPos,
              yPos: data.yPos,
            }
            break;
          }
        } else if (this.shipActionStore.pendingAction === 'followAttack') {
          if (data.xPos != 0) {
            this.gameStore.modalType = 'mapFollowAttack'
            this.gameStore.modalData = {
              xPos: data.xPos,
              yPos: data.yPos,
            }
            break;
          }
        } else if (this.shipActionStore.pendingAction === 'attack') {
          if (data.xPos != 0) {
            this.gameStore.modalType = 'mapAttack'
            this.gameStore.modalData = {
              xPos: data.xPos,
              yPos: data.yPos,
            }
            break;
          }
        } else if (this.shipActionStore.pendingAction === 'settle' && data.canBeSettled) {
          if (data.xPos != 0) {
            this.shipActionStore.queuePendingAction(
              data.xPos,
              data.yPos,
              0,
              0
            );
            this.renderMap()
            break;
          }
        }
      }
    }
  }

  /**
   * Open tooltip on map
   *
   * @param event
   */
  openToolTip(event: MouseEvent): boolean {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.scene.children);
    for (const intersect of intersects) {
      const data = this.getCoordinateData(intersect);
      if(data.hasTooltip) {
        this.mapStore.tooltipX = data.xPos;
        this.mapStore.tooltipY = data.yPos;
        this.mapStore.toolTipVisible = true;
        return true;
      }
      if(intersect.object.name === 'blackHole') {
        // Black Hole TODO: Add tooltip
        return true;
      }
    }
    return false;
  }

  /*************************** Dragging *************************/

  /**
   * Start Dragging
   *
   * @param event
   */
  startDrag(event: MouseEvent) {
    // Right click should start dragging
    if(event.button === 2) {
      this.startMousePosition = { x: event.clientX, y: event.clientY };
      this.dragMousePosition = { x: event.clientX, y: event.clientY };
      this.isDragging = true;
    }
  }

  /**
   * Stop Dragging
   *
   * @param event
   */
  stopDrag(event: MouseEvent) {
    // Right click should start dragging
      if (this.isDragging) {
        this.isDragging = false;
        this.setCursor('default');
      }

  }

  /**
   * After click, did we drag?
   *
   *
   * @param event
   * @param threshold
   */
  hasDragged(event: MouseEvent, threshold: number = 5): boolean {
    // Right click should start dragging
    if(event.button === 2) {
      const deltaX = Math.abs(this.startMousePosition.x - event.clientX);
      const deltaY = Math.abs(this.startMousePosition.y - event.clientY);

      return deltaX > threshold || deltaY > threshold;
    }
    return false;
  }


  /**
   * Process drag
   *
   * @param event
   */
  async dragMap(event: MouseEvent) {
      // Right click should start dragging
      this.setCursor('grabbing')
      const deltaX = event.clientX - this.dragMousePosition.x;
      const deltaY = event.clientY - this.dragMousePosition.y;
      this.camera.position.x -= deltaX * (0.5 / this.mapStore.zoomFactor);
      this.camera.position.y += deltaY * (0.5 / this.mapStore.zoomFactor);
      this.dragMousePosition = { x: event.clientX, y: event.clientY };
      this.renderMap()

      if(this.mapStore.crossHairVisible) {
        this.mapStore.crossHairVisible = false;
        new MapCrossHairUpdate(this.scene, this.mapStore.zoomFactor)
      }
  }

  /*************************** Tools *************************/

  /**
   * Update the mouse position on map
   *
   * @param event
   */
  updateMousePosition(event: MouseEvent) {
    const rect = this.container.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
  }

  /**
   * Render map
   */
  renderMap() {
    this.renderer.render(this.scene, this.camera);
  }

  /**
   * Set cursor on map
   * @param type
   */
  setCursor(type:string) {
    this.container.style.cursor = type;
  }
}