<script setup lang="ts">

import { ref } from 'vue'

const showButton = ref(false);

</script>

<template>
  <div
    class="flex justify-content-between bg-slate-800 border hover:border-slate-700 border-slate-800 p-1"
    @mouseover="showButton = true"
    @mouseleave="showButton = false"
  >
    <slot name="image"></slot>
    <div class="flex-1 relative ps-3">
      <div class="absolute top-0 right-0">
        <slot v-if="showButton" name="button"></slot>
      </div>
      <div class="pb-1"><slot name="title"></slot></div>
      <div class="text-subtext text-xs pe-2"><slot name="default"></slot></div>
      <div class="absolute bottom-0 w-full flex justify-between pe-3">
        <slot name="effects"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>