<script setup lang="ts">

import { computed } from 'vue'
import { useFleetStore } from '@/stores/fleetStore.ts'
import ShipStatIcons from '@/components/Objects/IconsWithCounters/ShipStatIcons.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import FleetDetailsTabs from '@/components/Sections/Command/Fleets/FleetDetails/FleetDetailsTabs.vue'
import FleetMenu from '@/components/Interface/Menu/FleetMenu.vue'
import router from '@/router'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import Position from '@/components/Interface/Position.vue'

const fleetStore = useFleetStore()
const gameStore = useGameStore()

const props = defineProps({
  fleetId: Number
});

const fleetData = computed(() => {
  return fleetStore.findFleetById(props.fleetId ?? 0)
})

</script>

<template>
  <div v-if="props.fleetId !==0 && fleetData">
    <div class="shipHeader flex flex-col justify-between bg-cover" :style="'background-image: url(' + gameStore.cdn + 'images/fleet/1.webp)'">

      <span class="p-2 text-2xl flex justify-between w-full items-center">
        <span class="flex items-center">
          <FleetMenu :fleetId="props.fleetId"></FleetMenu>
          {{ fleetData.name }}
          <Position :x="fleetData.xPos" :y="fleetData.yPos" class="ps-1"></Position>
        </span>
        <span class="self-end text-md block sm:hidden">
          <StandardButton
            color="red"
            @click="() => router.push('/command/fleets')"
            :end-margin="false"
          >X</StandardButton>
        </span>
      </span>
    </div>
    <div class="pt-1">
      <div class="grid grid-cols-4 sm:grid-cols-7 gap-1">
        <div class="bg-slate-800 text-subtext text-center p-2">
          <div class="flex justify-center p-1 sm:p-3">
            <ShipStatIcons type="ship_hit_points" :amount="fleetData.healthPercentage" :percentage="true" />
          </div>
        </div>
        <div class="bg-slate-800 text-subtext text-center p-2">
          <div class="flex justify-center p-1 sm:p-3">
            <ShipStatIcons type="ship_attack_ships" :amount="fleetData.attackShips" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext p-2">
          <div class="flex justify-center p-1 sm:p-3">
            <ShipStatIcons type="ship_attack_planets" :amount="fleetData.attackPlanets" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext p-2">
          <div class="flex justify-center p-1 sm:p-3">
            <ShipStatIcons type="ship_shields" :amount="fleetData.shields" />
          </div>
        </div>
        <div class="bg-slate-800 text-subtext text-center p-2">
          <div class="flex-col p-1 sm:p-3">
            <ShipStatIcons type="ship_max_speed" :amount="fleetData.maxSpeed" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext p-2">
          <div class="flex justify-center p-1 sm:p-3">
            <ShipStatIcons type="ship_scanning_range" :amount="fleetData.scanningRange" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext p-2">
          <div class="flex justify-center p-1 sm:p-3">
            <ShipStatIcons type="ship_repair" :amount="fleetData.repair" />
          </div>
        </div>
      </div>
    </div>
    <FleetDetailsTabs :fleetId="props.fleetId"></FleetDetailsTabs>
  </div>
</template>

<style scoped lang="scss">
.shipHeader{
  height: 8rem;
  background-size: cover;
  background-position: center;
}
</style>