<script setup lang="ts">

import PlanetOre from '@/components/Sections/Resources/Elements/PlanetOre.vue'
import { useOreConversionStore } from '@/stores/oreConversionStore.ts'

const oreConversionStore = useOreConversionStore()

const debug = false
</script>

<template>
  <PlanetOre
    :planetType="1"
    :oreLeft="1"
    :oreRight="2"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="3"
    :left="2.5"
    :debug="debug"
  ></PlanetOre>

  <PlanetOre
    :planetType="2"
    :oreLeft="3"
    :oreRight="4"
    :conversionLeft="oreConversionStore.data.planetDesertToNucloid"
    :conversionRight="oreConversionStore.data.planetDesertToVerrotan"
    :top="3"
    :left="22.5"
    :debug="debug"
  ></PlanetOre>

  <PlanetOre
    :planetType="3"
    :oreLeft="5"
    :oreRight="6"
    :conversionLeft="oreConversionStore.data.planetTemperateToBoturKoec"
    :conversionRight="oreConversionStore.data.planetTemperateToNagazal"
    :top="3"
    :left="42.5"
    :debug="debug"
  ></PlanetOre>

  <PlanetOre
    :planetType="4"
    :oreLeft="7"
    :oreRight="8"
    :conversionLeft="oreConversionStore.data.planetOceanicToFormets"
    :conversionRight="oreConversionStore.data.planetOceanicToGrindazine"
    :top="3"
    :left="62.5"
    :debug="debug"
  ></PlanetOre>

  <PlanetOre
    :planetType="5"
    :oreLeft="9"
    :oreRight="10"
    :conversionLeft="oreConversionStore.data.planetGasToPerunga"
    :conversionRight="oreConversionStore.data.planetGasToVeronium"
    :top="3"
    :left="82.5"
    :debug="debug"
  ></PlanetOre>

  <PlanetOre
    :planetType="6"
    :oreLeft="11"
    :oreRight="12"
    :conversionLeft="oreConversionStore.data.planetIceToBlazanter"
    :conversionRight="oreConversionStore.data.planetIceToTerrilliod"
    :top="3"
    :left="102.5"
    :debug="debug"
  ></PlanetOre>
</template>

<style scoped lang="scss">

</style>