<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'
import { computed } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import Position from '@/components/Interface/Position.vue'

const gameStore = useGameStore()

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const size = computed(() => {
  return props.newsItem.contentData.planetSize*2
})

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="gameStore.cdn + 'images/planet/new/128/planet_' + props.newsItem.contentData.planetType + '.png'"
    :toast-text="'You have settled ' + props.newsItem.contentData.planetName + '!'"
  >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="gameStore.cdn + 'images/planet/new/128/planet_' + props.newsItem.contentData.planetType + '.png'"
        :header="'You have settled ' + props.newsItem.contentData.planetName + '!'"
      >
        <p class="pb-2">You have settled {{ props.newsItem.contentData.planetName }}! The colony ship has landed and has become part of the new settlement.</p>
        <p class="pb-2" v-if="props.newsItem.contentData.planetSize === gameStore.player?.homeWorldPlanetSize">The planet's gravity is perfect for your people, they will be <span class="text-green-500">more productive</span>.</p>
        <p class="pb-2" v-if="props.newsItem.contentData.planetSize === gameStore.player?.homeWorldOppositePlanetSize">The planet's gravity not what your people are used to, they will be <span class="text-red-500">less productive</span>.</p>
        <p class="pb-2" v-if="props.newsItem.contentData.planetType === gameStore.player?.homeWorldPlanetType">This is a {{props.newsItem.contentData.planetTypeName}} planet, which is a very comfortable environment for your people, it will make them <span class="text-green-500">more productive</span>.</p>
        <p class="pb-2" v-if="props.newsItem.contentData.planetType === gameStore.player?.homeWorldOppositePlanetType">This is a {{props.newsItem.contentData.planetTypeName}} planet, which is very difficult for your people to live on, it will make them <span class="text-red-500">less productive</span>.</p>
      </NewsItemBaseStructure>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>