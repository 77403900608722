<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetActiveDefence from "@/components/Sections/Planets/PlanetTabs/DefencesTab/PlanetActiveDefence.vue";
import PlanetPassiveDefence from "@/components/Sections/Planets/PlanetTabs/DefencesTab/PlanetPassiveDefence.vue";

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()

const data = computed(() => {
  const planetData = planetStore.findPlanetById(props.planetId);

  // Calculate the percentage of the planet's hitpoints
  const percentage = Math.round((planetData.currentHitPoints / planetData.maxHitPoints) * 100);

  let color = 'bg-green-800';
  if(percentage < 50 && percentage > 25) {
    color = 'bg-yellow-800';
  } else if(percentage <= 25) {
    color = 'bg-red-800';
  }

  return {
    planetData: planetData,
    percentage: percentage,
    color: color
  }
})


</script>

<template>
  <div class="p-6">
    <div class="flex justify-between mb-1">
      <span class="">Defending troops</span>
      <span class="text-sm font-mediumtext-white">{{ data.planetData?.maxHitPoints }}</span>
    </div>
    <div class="w-full rounded h-4 bg-gray-700">
      <div :class="'h-4 rounded ' + data.color + ''" :style="'width: ' + data.percentage +'%'"></div>
    </div>
    <div class="text-center pb-3 pt-6 text-subtext">When you have no more troops left, your planet will be captured by the attacker.</div>
  </div>
  <div class="grid lg:grid-cols-2 xl:grid-cols-2 gap-1">
    <PlanetActiveDefence :planetId="props.planetId" />
    <PlanetPassiveDefence :planetId="props.planetId" />
  </div>
</template>

<style scoped lang="scss">
</style>
