<script setup lang="ts">

import { ref } from 'vue'
import MapSideBarMine from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/Tabs/MapPlanetSideBarMine.vue'
import MapSideBarFree from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/Tabs/MapPlanetSideBarFree.vue'
import MapSideBarOther from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/Tabs/MapPlanetSideBarOther.vue'
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore();

const currentTab = ref('mine');

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'mine',
               'text-blue-500 border-blue-500': currentTab === 'mine',
               'text-gray-500 border-transparent': currentTab !== 'mine',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'mine'">
          {{ gameStore.player?.nickName }}
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'free',
               'text-blue-500 border-blue-500': currentTab === 'free',
               'text-gray-500 border-transparent': currentTab !== 'free',
               'inline-block p-4 border-b-2 rounded-t-lg  hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'free'">
          Free
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'other',
               'text-blue-500 border-blue-500': currentTab === 'other',
               'text-gray-500 border-transparent': currentTab !== 'other',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'other'">
          Others
        </a>
      </li>
    </ul>
  </div>
  <div v-if="currentTab === 'mine'" class="pt-1">
    <MapSideBarMine/>
  </div>
  <div v-else-if="currentTab === 'free'" class="pt-1">
    <MapSideBarFree/>
  </div>
  <div v-else-if="currentTab === 'other'" class="pt-1">
    <MapSideBarOther/>
  </div>
</template>

<style scoped lang="scss">

</style>