<script setup lang="ts">

import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import ShipComponentEffects from '@/components/Objects/ship/ShipComponentEffects.vue'

const props = defineProps<{
  shipComponentCode: string
}>();

const assetStore = useAssetStore()

const shipComponent = computed(() => {
  return assetStore.findShipComponentByCode(props.shipComponentCode)
});
</script>

<template>
  <div class="content-header flex justify-between">
    <div>
    {{ shipComponent.name }}
    </div>
    <div class="text-slate-500">
      Ship Component
    </div>
  </div>
  <div class="p-2">
    <ShipComponentEffects :shipComponent="shipComponent"> </ShipComponentEffects>
  </div>
</template>

<style scoped lang="scss">

</style>