<script setup lang="ts">

import MediumModel from '@/components/Interface/Modal/ModelSizes/MediumModel.vue'
import { useGameStore } from '@/stores/gameStore'
import {useAssetStore} from "@/stores/assetStore";
import {computed} from "vue";
import ShipComponentEffects from '@/components/Objects/ship/ShipComponentEffects.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const shipComponentData = computed(() => assetStore.findShipComponentByCode(gameStore.modalData.shipComponentCode))


</script>

<template>
  <MediumModel :title="shipComponentData.name">
    <div class="p-3 md:p-3">
      <div class="flex flex-col sm:flex-row text-center sm:text-left">
        <ImageRounded
          type="ship-components"
          source-size="512"
          :file="shipComponentData.code"
          class="main-image self-center sm:self-start"
          :size="16"
        />
        <div class="flex-grow text-subtext lg:pl-3 pl-0 pt-2 sm:pt-0">
          <p class="pb-2">{{ shipComponentData.longDescription }}</p>

          <div v-for="effect in shipComponentData.effects" :key="effect.type" class="pb-2">
            <span v-if="effect.modifier>0 && effect.type == 'ship_production_cost'" class="pr-2">
              Cost : {{ effect.modifier }} <Icon type="production"></Icon>
            </span>
          </div>

          <ShipComponentEffects :ship-component="shipComponentData"/>
        </div>
      </div>
    </div>
  </MediumModel>

</template>

<style scoped lang="scss">
.main-image {
  width:15rem;
  height:15rem;
}
</style>