<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Icon from "@/components/Objects/Icons/Icon.vue";
import IconCount from "@/components/Objects/IconCount.vue";
const gameStore = useGameStore()

const props = defineProps({
  component: {},
});

</script>

<template>
  <div
      class="relative group max-w-sm bg-black border rounded-lg shadow border-gray-700">
    <div class="relative">
      <span class="absolute m-2 bottom-0 right-0 text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-gray-700 text-gray-300">{{ component.group }}</span>

        <div class="absolute flex justify-between w-full p-1">
           <template v-for="effect in component.effects" :key="effect.type">
              <div v-if="effect.modifier>0 && effect.type != 'ship_mass' && effect.type != 'ship_production_cost'">
                <Icon :type="effect.type"></Icon><IconCount :amount="effect.modifier" :no-plus="effect.type==='ship_max_speed'"></IconCount>
              </div>
            </template>
        </div>
      <img class="rounded-lg pb-9 pt-8 px-2" :src="'' + gameStore.cdn + '/images/ship-components/256/' + component.code + '.webp'" alt="" />
      <div class="absolute p-1 inset-0 bg-black bg-opacity-0 flex flex-col opacity-0 group-hover:bg-opacity-90 group-hover:opacity-100 transition-opacity duration-300">
        <div class="text-white line-clamp-4 text-center flex-1">
          <div class="mb-1 font-bold tracking-tight text-white">{{ component.name }}</div>
          <div class="hidden 2xl:block md:block text-sm text-subtext">{{ component.description }}</div>
        </div>
        <div class="flex justify-between">
          <template v-for="effect in component.effects" :key="effect.type">
            <div class="bg-black" v-if="effect.modifier>0 && (effect.type == 'ship_mass' || effect.type == 'ship_production_cost')">
              <Icon :type="effect.type"></Icon><IconCount :amount="effect.modifier"></IconCount>
            </div>
          </template>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>