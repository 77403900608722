<script setup lang="ts">

import FormSubmit from '@/components/Interface/Modal/Forms/FormSubmit.vue'
import FormInput from '@/components/Interface/Modal/Forms/FormInput.vue'
import FormGenericError from '@/components/Interface/Modal/Forms/FormGenericError.vue'
import Backend from '@/models/backend.ts'
import FormProcess from '@/models/formProcess.ts'
import * as yup from 'yup'
import { reactive, ref } from 'vue'
import { usePQAStore } from '@/stores/PQAStore.ts'

const PQAStore = usePQAStore()
const backend = new Backend()
const formProcess = new FormProcess()

const added = ref(false)

// Define the validation schema
const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, () => `Use at least 3 characters`)
    .max(32, () => `Do not use more than 32 characters`)
    .required(() => `This is required`)
})

// Default form values
const formValues = reactive<{ [key: string]: string }>({
  name: ''
})

// Form errors
const formErrors = reactive<{ [key: string]: string }>({
  generic: '',
  name: ''
});


// Handle form submission
const submit = () => {
  schema.validate(formValues)
    .then(() => {
      formProcess.resetFormErrors(formErrors)
      backend.createPQA(formValues.name)
        .then((response) => {
          PQAStore.selectQueue(response.data.id)
          formValues.name = ''
          added.value = true
        })
        .catch(error => formProcess.processBackendErrorResponse(error, formErrors))
    })
    .catch((error) => formProcess.processFrontendErrorResponse(error, formErrors))
}
</script>

<template>
  <div v-if="!added">
    <div class="content-header">Add new production automation</div>
    <div class="border border-slate-700 bg-slate-800">
      <form method="post" @submit.prevent="submit" class="pl-4 pr-4 pt-4">
        <FormInput
          label="Name"
          v-model="formValues.name"
          :error="formErrors.name"
        />
        <div class="flex justify-between">
          <FormGenericError :error="formErrors.generic"/>
          <FormSubmit text="Create" class="justify-end"/>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>