<script setup lang="ts">
import Icon from "@/components/Objects/Icons/Icon.vue";
import IconCount from "@/components/Objects/IconCount.vue";
import {useShipDesignStore} from "@/stores/shipDesignStore.ts";
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ShipDesignInUse from '@/components/Sections/Command/ShipDesigns/ShipDesignInUse.vue'
import ShipDesignInProduction from '@/components/Sections/Command/ShipDesigns/ShipDesignInProduction.vue'
import Backend from '@/models/backend.ts'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'

const shipDesignStore = useShipDesignStore();
const tableStore = useTableStore();
const gameStore = useGameStore()
const shipDesignCreationStore = useShipDesignCreationStore()
const assetStore = useAssetStore()

const deleteShipDesign = (id: number) => {
  const backend = new Backend();
  backend.deleteShipDesign(id);
}

const filters = (shipDesigns) => {
  return Array.from(shipDesigns.values())
    .filter((shipDesign) => {
      return !shipDesign.deleted
    })
    .filter((shipDesign) => {
      // If no search query, return all ships
      if (!tableStore.shipDesigns.filters.nameSearch || tableStore.shipDesigns.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return shipDesign.name.toLowerCase().includes(tableStore.shipDesigns.filters.nameSearch.toLowerCase());
    })
};

const cloneDesign = (id: number) => {
  gameStore.modalType = 'createShipDesign'
  const design = shipDesignStore.playerShipDesigns.get(id)
  if(!design) {
    return
  }
  shipDesignCreationStore.initFromDesign(design)
}


</script>

<template>
  <div class="pt-1">
  <FerionDataTable
    :data="shipDesignStore.playerShipDesigns"
    tableStoreName="shipDesigns"
    :selector="false"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-8rem)]'"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarBlock class="mr-1 pl-2 flex items-center">
        <ModalTrigger type="createShipDesign">
          <StandardButton>Create new design</StandardButton>
        </ModalTrigger>
      </ActionBarBlock>
      <ActionBarFiller class="mr-1"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.shipDesigns.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1"/>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderTextFlex min-width="12" column="shipName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <div class="pl-2">Name</div>
      </HeaderTextFlex>
      <HeaderFixedWidthText width="8" align="left" column="type" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        Type
      </HeaderFixedWidthText>
      <HeaderFixedWidthIcon width="5" column="hitPoints" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_hit_points"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="5" column="productionCost" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="production"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="5" column="attackShips" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_attack_ships"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="5" column="attackPlanets" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_attack_planets"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="5" column="shields" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_shields"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="5" column="maxSpeed" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_max_speed"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="5" column="scanningRange" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_scanning_range"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthIcon width="5" column="repair" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <Icon type="ship_repair"></Icon>
      </HeaderFixedWidthIcon>
      <HeaderFixedWidthTextNoSort width="5" align="center">
        Special
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthTextNoSort width="8" align="center">
        In production
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthTextNoSort width="5" align="center">
        In use
      </HeaderFixedWidthTextNoSort>
      <HeaderFixedWidthTextNoSort width="5" align="center">
        Action
      </HeaderFixedWidthTextNoSort>
    </template>
    <!--- Rows --->
    <template #rows="{ item }">
      <CellFlexWidth min-width="12" >
        <div class="flex-col pl-2">
          <div>{{ item.name }}</div>
        </div>
      </CellFlexWidth>
      <CellFixedWidth width="8" align="left">
        {{ item.typePretty }}
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <IconCount :amount="item.hitPoints" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <IconCount :amount="item.productionCost" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <IconCount :amount="item.attackShips" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <IconCount :amount="item.attackPlanets" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <IconCount :amount="item.shields" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <IconCount :amount="item.maxSpeed" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <IconCount :amount="item.scanningRange" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <IconCount :amount="item.repair" :no-plus="true"/>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <img v-if="item.canSettle" :src=" gameStore.cdn + '/images/ship/actions/settle.png'" alt="Colonize" title="Colonize" class="w-5 h-5">
      </CellFixedWidth>
      <CellFixedWidth width="8">
        <ShipDesignInProduction :shipDesignId="item.id"></ShipDesignInProduction>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <ShipDesignInUse :shipDesignId="item.id"></ShipDesignInUse>
      </CellFixedWidth>
      <CellFixedWidth width="5">
        <StandardButton  @click="cloneDesign(item.id)">Clone</StandardButton>
        <StandardButton @click.once="deleteShipDesign(item.id)" color="red">x</StandardButton>
      </CellFixedWidth>
    </template>
  </FerionDataTable>
  </div>
</template>

<style scoped lang="scss">

</style>