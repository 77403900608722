<script setup lang="ts">

import {computed, defineComponent, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetPopulationSetting from "@/components/Sections/Planets/PlanetPopulationSetting.vue";
import PlanetProductionIcon from '@/components/Objects/IconsWithCounters/PlanetProductionIcon.vue'
import PlanetResourceIcon from '@/components/Objects/IconsWithCounters/PlanetResourceIcon.vue'
import { useGameStore } from '@/stores/gameStore'
import PlanetOreCapacityIcon from "@/components/Objects/IconsWithCounters/PlanetOreCapacityIcon.vue";
import PlanetEnergyCapacityIcon from "@/components/Objects/IconsWithCounters/PlanetEnergyCapacityIcon.vue";
import PlanetTabs from "@/components/Sections/Planets/PlanetTabs.vue";
import Position from "@/components/Interface/Position.vue";
import PlanetMenu from '@/components/Interface/Menu/PlanetMenu.vue'
import PlanetScanningRangeIcon from '@/components/Objects/IconsWithCounters/PlanetScanningRangeIcon.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import router from '@/router'

/**
 * Planet Details component
 */
defineComponent({
  name: 'PlanetDetails',
})

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()
const gameStore = useGameStore()

const planetData = computed(() => planetStore.findPlanetById(props.planetId??0))

</script>

<template>
  <div v-if="props.planetId !==0 && planetData">
    <div class="planetHeader flex flex-col justify-between bg-cover relative" :style="'background-image: url(' + gameStore.cdn + 'images/planet/new/512/planet_' + planetData.type + '.png)'">
      <div class="p-2">
        <span class="text-2xl flex justify-between w-full items-center">
          <span class="flex items-center">
            <PlanetMenu :planetId="props.planetId"></PlanetMenu>
            {{ planetData.planetName }}
            <Position :x="planetData.xPos" :y="planetData.yPos" class="ps-1"></Position>
          </span>
          <span class="self-end text-md block sm:hidden"><StandardButton
            color="red"
            @click="() => router.push('/planets')"
            :end-margin="false"
          >X</StandardButton></span>
        </span>
        <p class="text-subtext">{{ planetData.starName }}</p>
      </div>
      <div class="absolute left-0 bottom-0 p-2">
        <PlanetScanningRangeIcon :planetId="planetData.id" class="absolute bottom-0 right-0"></PlanetScanningRangeIcon>
      </div>
      <div class="absolute right-0 bottom-0 pr-2 pb-1 text-lg" title="Population">
       <div class="flex justify-end">
         <div>
           <Icon type="population"></Icon>
         </div>
         <div class="pt-1 pl-2">
           <div>{{ planetData.population }}</div>
         </div>
       </div>
      </div>
    </div>
    <div class="flex justify-between w-full bg-slate-700 p-2">
      <span class="flex-1 whitespace-nowrap">
        <PlanetProductionIcon
          :planet-id="planetData.id"
          :amount="planetData.populationGrowthPerTurn"
          type="food"
          class="pr-1"
          :allowZero="true"
        ></PlanetProductionIcon>
        <PlanetProductionIcon
          :planet-id="planetData.id"
          :amount="planetData.productionPerTurn"
          type="production"
          class="pr-1"
          :allowZero="true"
        ></PlanetProductionIcon>
        <PlanetProductionIcon
          :planet-id="planetData.id"
          :amount="planetData.sciencePerTurn"
          type="science"
          class="pr-1"
          :allowZero="true"
        ></PlanetProductionIcon>
        <PlanetProductionIcon
          :planet-id="planetData.id"
          :amount="planetData.creditsPerTurn"
          type="credit"
          class="pr-1"
          :allowZero="true"
        ></PlanetProductionIcon>
      </span>
      <span class="flex-1 text-center lg:block hidden">
        <PlanetOreCapacityIcon
            :planet-id="planetData.id"
            class="pr-1"
        ></PlanetOreCapacityIcon>
        <PlanetEnergyCapacityIcon
            :planet-id="planetData.id"
        ></PlanetEnergyCapacityIcon>
      </span>
      <span class="flex-1 text-right">
        <PlanetResourceIcon
            :planet-id="planetData.id"
            type="energy"
            class="pr-1"
        ></PlanetResourceIcon>
        <PlanetResourceIcon
            :planet-id="planetData.id"
            type="primary_ore"
            class="pr-1"
        ></PlanetResourceIcon>
        <PlanetResourceIcon
            :planet-id="planetData.id"
            type="secondary_ore"
            class="pr-1"
        ></PlanetResourceIcon>
      </span>
    </div>

    <div class="bg-slate-800 mb-4">
      <PlanetPopulationSetting :planetId="planetData.id" class="pt-2 pb-2"></PlanetPopulationSetting>
    </div>
    <div>
      <PlanetTabs :planetId="planetData.id"></PlanetTabs>
    </div>
  </div>
  <div v-else>
    <div class="flex justify-center text-subtext p-10">No planet selected</div>
  </div>

</template>

<style scoped>
.planetHeader{
  height: 12rem;
  background-size: cover;
  background-position: top;
}
</style>
