<script setup lang="ts">

import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import type PlanetQueueAutomation from '@/types/PlanetQueueAutomation.ts'
import { ref } from 'vue'
import { usePQAStore } from '@/stores/PQAStore.ts'
import Backend from '@/models/backend.ts'
import { useGameStore } from '@/stores/gameStore.ts'

const props = defineProps<{
  pqa: PlanetQueueAutomation
}>()

const PQAStore = usePQAStore()
const gameStore = useGameStore()
const backend = new Backend()

const deleteVisible = ref(false)

const deletePQA = (id: number) => {


  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to delete this queue?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      PQAStore.selectQueue(0)
      backend.deletePQA(id).then(() => {
      })
    },
  };
}

</script>

<template>
  <div
    :class="{
    'flex justify-between border-slate-700 border hover:bg-slate-700 rounded p-2 mb-2 cursor-pointer' : true,
    'bg-slate-700': PQAStore.selectedQueue === pqa.id,
    'bg-slate-800': PQAStore.selectedQueue !== pqa.id,
    }"
    @mouseover="deleteVisible = true"
    @mouseleave="deleteVisible = false"
    @click.stop="PQAStore.selectQueue(pqa.id)"
  >
    <div>{{ pqa.name }}</div>
    <StandardButton v-if="deleteVisible" color="red" :end-margin="false" @click.once.stop="deletePQA(pqa.id)">x</StandardButton>
  </div>
</template>

<style scoped lang="scss">

</style>