import {defineStore} from 'pinia'
import { markRaw } from 'vue'
import * as THREE from 'three'
import type MapCoordinate from '@/types/MapCoordinate.ts'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { zoomFactor } from '@/enums/zoomFactor.ts'
import { useGameStore } from '@/stores/gameStore.ts'

export const useMapPlanetOwnerStore = defineStore('mapPlanetOwnerStore', {
    state: () => ({
        planetOwnerInstancedMesh: null as THREE.InstancedMesh<THREE.CircleGeometry, THREE.ShaderMaterial> | null,
        planetOwnerAvailableInstanceIds: markRaw<number[]>([]),
        planetOwnerMapCoordinateLookup: markRaw(new Map())
    }),
    actions: {
        initPlanetOwnerInstancedMesh(maxCount: number) {
            const geometry2 = new THREE.CircleGeometry(1, 32);
            const material2 = new THREE.ShaderMaterial({
                vertexShader: `
                    attribute vec3 instanceColor;
                    varying vec3 vColor;
                
                    void main() {
                      vColor = instanceColor;  // Pass the color to the fragment shader
                      vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
                      gl_Position = projectionMatrix * mvPosition;
                    }
                  `,
                                fragmentShader: `
                    varying vec3 vColor;
                
                    void main() {
                      gl_FragColor = vec4(vColor, 1.0);  // Use the passed color for the fragment
                    }
                  `,
                vertexColors: true,
            });

            const colors = new Float32Array(maxCount * 3);  // 3 values per instance (r, g, b)
            geometry2.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(colors, 3));

            this.planetOwnerInstancedMesh = markRaw(new THREE.InstancedMesh(geometry2, material2, maxCount));

            const dummy = new THREE.Object3D();

            for(let i = 0; i < maxCount; ++i) {
                dummy.position.set(-10000, -10000, -10000);  // Out of visible range
                dummy.updateMatrix();
                this.planetOwnerInstancedMesh.setMatrixAt(i, dummy.matrix);
                this.planetOwnerAvailableInstanceIds.push(i);
            }

            this.planetOwnerInstancedMesh.instanceMatrix.needsUpdate = true;

            return this.planetOwnerInstancedMesh
        },
        getColor(mapCoordinate: MapCoordinate): number {
          if(Number(mapCoordinate.type) === 2) {
            const gameStore = useGameStore();
            if(mapCoordinate.typePlayerId == gameStore.player?.id) {
                return 0x00FF00;
            }
            const playerRelationStore = usePlayerRelationStore();
            const relation = playerRelationStore.findRelationByPlayerId(mapCoordinate.typePlayerId);

              if(relation.relation == 'neutral') {
                  if(mapCoordinate.currentlyVisible) {
                      return 0xB57533;
                  } else {
                      return 0xA35200;
                  }
              } else if(relation.relation == 'enemy') {
                  if(mapCoordinate.currentlyVisible) {
                      return 0xFF0000;
                  } else {
                      return 0xCC0000;
                  }
              } else if(relation.relation == 'friend') {
                  if(mapCoordinate.currentlyVisible) {
                      return 0xCE93D8;
                  } else {
                      return 0xBA68C8;
                  }
              } else if(relation.relation == 'ally') {
                  if(mapCoordinate.currentlyVisible) {
                      return 0x00FFFF;
                  } else {
                      return 0x00CCCC;
                  }
              } else {
                  if(mapCoordinate.currentlyVisible) {
                      return 0x909090;
                  } else {
                      return 0xA0A0A0;
                  }
              }
          }
          return 0x123456;
        },
        addPlanetOwner(x:number,y:number,color:number): number {
            if(this.planetOwnerInstancedMesh) {
                const instanceId = this.getAvailablePlanetOwnerInstanceId();
                if (instanceId !== null && instanceId !== undefined) {
                    // Reuse the same dummy Object3D instance
                    const dummy = new THREE.Object3D();  // Or reuse a global dummy object

                    // Set the new position for the instance
                    dummy.position.set(x, y, 0.1);
                    dummy.scale.set(1, 1, 1);  // Apply uniform scaling
                    dummy.updateMatrix();

                    // Apply the matrix to the instanced mesh at the specific instance ID
                    this.planetOwnerInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

                    const colorAttribute = this.planetOwnerInstancedMesh.geometry.attributes.instanceColor;
                    if (colorAttribute) {
                        // Update the color buffer with the new color
                        const color2 = new THREE.Color(color);
                        colorAttribute.setXYZ(instanceId, color2.r, color2.g, color2.b);  // Assuming color is a THREE.Color object
                        colorAttribute.needsUpdate = true;
                    }

                    // Mark the instance matrix for update
                    this.planetOwnerInstancedMesh.instanceMatrix.needsUpdate = true;

                    // Store the instanceId for the mapCoordinate (so we can reverse lookup it later)
                    this.planetOwnerMapCoordinateLookup.set(instanceId, { x: x, y: y });
                    return instanceId
                }
            }
            return 0
        },
        removePlanetOwner(instanceId: number) {
            if(this.planetOwnerInstancedMesh) {
                const dummy = new THREE.Object3D();
                dummy.position.set(-10000, -10000, -10000);  // Out of visible range
                dummy.updateMatrix();
                this.planetOwnerInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                this.releasePlanetOwnerInstanceId(instanceId);
                this.planetOwnerInstancedMesh.instanceMatrix.needsUpdate = true;
            }
        },
        getAvailablePlanetOwnerInstanceId(): number {
            if(this.planetOwnerAvailableInstanceIds.length === 0) {
                throw new Error('No more available instance ids for planet owner objects');
            }
            return this.planetOwnerAvailableInstanceIds.pop()!;
        },
        releasePlanetOwnerInstanceId(instanceId: number) {
            this.planetOwnerAvailableInstanceIds.push(instanceId)
            this.planetOwnerMapCoordinateLookup.delete(instanceId);
        },
        scaleAndPositionPlanetOwner(instanceId: number, mapCoordinate: MapCoordinate, zoom: number) {
            if(this.planetOwnerInstancedMesh) {
                const dummy = new THREE.Object3D();
                let x = 0;
                let y = 0;
                let scale = 0;
                if (zoom === zoomFactor.Planet) {
                    x = mapCoordinate.xPos + 1;
                    y = mapCoordinate.yPos + 1;
                    scale = 0.25;
                } else if (zoom === zoomFactor.Solar) {
                    x = mapCoordinate.xPos + 1;
                    y = mapCoordinate.yPos + 1;
                    scale = 0.35;
                } else if (zoom === zoomFactor.Local) {
                    x = mapCoordinate.xPos;
                    y = mapCoordinate.yPos;
                    scale = 1.3;
                } else if (zoom === zoomFactor.Sector) {
                    x = mapCoordinate.xPos;
                    y = mapCoordinate.yPos;
                    scale = 2.2;
                } else {
                    x = mapCoordinate.xPos;
                    y = mapCoordinate.yPos;
                    scale = 3.5;
                }

                dummy.position.set(x, y, 0.1)  // You can modify the z position if needed
                dummy.scale.set(scale, scale, scale);  // Apply uniform scaling
                dummy.updateMatrix();
                this.planetOwnerInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

                this.planetOwnerInstancedMesh.instanceMatrix.needsUpdate = true;
            }
        },
        findCoordinatesByInstanceId(instanceId: number): { x: number, y: number } {
            if(this.planetOwnerMapCoordinateLookup.has(instanceId)) {
                return this.planetOwnerMapCoordinateLookup.get(instanceId);
            }
            return {x: 0, y: 0}
        }
    }
});