<script setup lang="ts">

import { useGameStore } from "@/stores/gameStore.ts";
import { computed } from 'vue';
import LargeWideModel from '@/components/Interface/Modal/ModelSizes/LargeWideModel.vue'
import { useFleetStore } from '@/stores/fleetStore.ts'
import MapSelectPlayerFleetShips
  from '@/components/Interface/Modal/Models/SelectPlayerShips/MapSelectPlayerFleetShips.vue'

// Get the planet data
const gameStore = useGameStore();
const fleetStore = useFleetStore();


const fleetData = computed(() => {
  return fleetStore.findFleetById(gameStore.modalData.fleetId);
});

</script>

<template>
  <LargeWideModel :title="'Ships from fleet \'' + fleetData?.name + '\' @ ' + gameStore.modalData.xPos + 'x' + gameStore.modalData.yPos">
    <MapSelectPlayerFleetShips :fleetId="gameStore.modalData.fleetId" :xPos="gameStore.modalData.xPos" :yPos="gameStore.modalData.yPos"/>
  </LargeWideModel>
</template>

<style scoped lang="scss">

</style>
