import { defineStore } from 'pinia';
import type ShipGroup from '@/types/ShipGroup.ts'

export const useShipGroupStore = defineStore('shipGroupStore', {
    state: () => ({
        shipGroups: new Map<number, ShipGroup>()
    }),
    getters: {
        findShipGroupById: (state) => (id: number): ShipGroup | undefined => {
            return state.shipGroups.get(id);
        }
    },
    actions: {
        updateShipGroupById(id: number, data: ShipGroup) {
            this.shipGroups.set(data.id, data);

        },
        deleteShipGroupById(id: number) {
            if (this.shipGroups.has(id)) {
                this.shipGroups.delete(id);
            }
        },
        getNameFromId(id: number): string {
            const shipGroup = this.findShipGroupById(id);
            if (shipGroup) {
                return shipGroup.name;
            } else {
                return '';
            }
        }
    },
});
