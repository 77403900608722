<script setup lang="ts">
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import PlayerRelation from '@/components/Sections/Diplomacy/Empires/PlayerRelation.vue'

const playerRelationStore = usePlayerRelationStore()

const props = defineProps<{
  type: string
}>()
</script>

<template>
  <div class="p-2 grid sm:grid-cols-4 xl:grid-cols-8 grid-cols-2 gap-2">
    <div v-for="([key, relation]) in playerRelationStore.getAllRelationsByType(props.type)" :key="key">
      <PlayerRelation :relation="relation"/>
  </div>
  </div>
  <div v-if="playerRelationStore.getAllRelationsByType(props.type).length === 0" class="p-2">
    <div v-if="props.type === 'friend'" class="bg-slate-800 p-6 text-subtext">
      Empires friendly to you will appear here.
    </div>
    <div v-else-if="props.type === 'enemy'" class="bg-slate-800 p-6 text-subtext">
      Hostile empires will appear here.
    </div>
    <div v-else-if="props.type === 'ally'" class="bg-slate-800 p-6 text-subtext">
      Empires allied to you will appear here.
    </div>
    <div v-else-if="props.type === 'neutral'" class="bg-slate-800 p-6 text-subtext">
      Neutral empires will appear here.
    </div>
  </div>
</template>

<style scoped lang="scss">
/* Your styles go here */
</style>
