<script setup lang="ts">

import type PlayerRelation from '@/types/PlayerRelation.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'

const gameStore = useGameStore()

const props = defineProps<{
  relation: PlayerRelation
}>()

const requestAudience = () => {
  gameStore.modalType = 'requestAudience'
  gameStore.modalData = {
    relation: props.relation
  }
}

</script>

<template>
  <div class="flex flex-col cursor-pointer border border-slate-800 hover:border-slate-700 " @click="requestAudience">
    <div class="content-header flex justify-between">
      <div class="flex justify-start">
        <div class="pt-0.5">
          <img v-if="props.relation.country" alt="Country" :src="'https://flagcdn.com/16x12/' + props.relation.country + '.webp'" class="pr-2">
        </div>
        <div>{{ props.relation.name }}</div>
      </div>
      <div>
        <span
          v-if="props.relation.otherRequested !== 'none'"
          class="rounded bg-red-500 text-white text-xs px-1 ml-1"
        >!</span>
      </div>
    </div>
    <div class="bg-slate-800">
      <div class="p-2">
        <ImageRoundedUrl
          :url="relation.profileImage"
        />
      </div>

      <div class="pb-2 text-center bg-slate-800">
        <StandardButton>Request Audience</StandardButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
 .background {
   background-size: cover; /* or 'contain' */
   background-repeat: no-repeat; /* prevent the image from repeating */
   background-position: center;
 }
</style>