<script setup lang="ts">
import {computed, defineComponent, defineProps, ref} from "vue";
import {useShipStore} from "@/stores/shipStore.ts";
import ShipActions from '@/components/Sections/Command/Ships/ShipDetails/ShipDetailTabs/ShipActions.vue'

const props = defineProps({
  shipId: Number
});

const shipStore = useShipStore()
const shipData = computed(() => shipStore.findShipById(props.shipId))
const currentTab = ref('actions')

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'actions',
               'text-blue-500 border-blue-500': currentTab === 'actions',
               'text-gray-500 border-transparent': currentTab !== 'actions',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'actions'">
          Actions
        </a>
      </li>
    </ul>
  </div>
  <div v-if="currentTab === 'actions'" class="content-height-limit-ship-details pr-1">
    <ShipActions :shipId="shipData.id" />
  </div>
</template>

<style scoped lang="scss">
.nav-item {
  cursor: pointer;
}
</style>
