<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Position from '@/components/Interface/Position.vue'
import PlanetIcon from '@/components/Objects/Icons/PlanetIcon.vue'
import StarIcon from '@/components/Objects/Icons/StarIcon.vue'

const gameStore = useGameStore()

const props = defineProps<{
  shipActionData: {
    action: string;
    targetX: number;
    targetY: number;
    targetPlayer: number;
    targetShip: number;
    data: {
      name: string;
      type: number;
      typeVariantId: number;
      targetPlayer: number;
    }
  },
  eta: number
}>();

</script>

<template>
  <div class="p-2 bg-slate-800 mt-1 mb-1 flex">
    <div class="flex-shrink">
      <img :src="'' + gameStore.cdn + '/images/ship/actions/search.png'" alt="Explore" class="h-12 w-12 mr-2" />
    </div>
    <div class="flex-grow">
      <div class="text-subtext flex justify-items-start align-middle">
        <div class="pt-3 pe-1">Exploring</div>
      </div>
    </div>
    <div v-if="eta>0" class="flex-shrink flex items-center text-subtext">
      {{ eta }} days
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>