<script setup lang="ts">

import FormInput from "@/components/Interface/Modal/Forms/FormInput.vue";
import FormSubmit from "@/components/Interface/Modal/Forms/FormSubmit.vue";
import Backend from "@/models/backend.ts";
import FormProcess from "@/models/formProcess.ts";
import * as yup from "yup";
import {reactive, watch} from "vue";
import { useShipDesignCreationStore } from '@/stores/shipDesignCreationStore.ts'

const shipDesignStore = useShipDesignCreationStore()

const backend = new Backend()
const formProcess = new FormProcess()


// Define the validation schema
const schema = yup.object().shape({
  ship_design_name: yup
      .string()
      .min(3, () => `Use at least 3 characters`)
      .max(32, () => `Do not use more than 32 characters`)
      .required(() => `This is required`)
})

// Default form values
const formValues = reactive<{ [key: string]: string }>({
  ship_design_name: ''
})

// Form errors
const formErrors = reactive<{ [key: string]: string }>({
  generic: '',
  ship_design_name: ''
});

// display the error message somewhere else
watch(() => formErrors.generic, (newValue, oldValue) => {
  // Perform your action here
  console.log('formErrors.generic changed from', oldValue, 'to', newValue)
  shipDesignStore.designError = newValue
});


// Handle form submission
const submitDesignName = () => {
  schema.validate(formValues)
      .then(() => {
        formProcess.resetFormErrors(formErrors)
        backend.createShipDesign(formValues.ship_design_name, shipDesignStore.getComponentCodes)
            .then(() => {
              formProcess.processBackendSuccessResponse()
              shipDesignStore.resetDesign()
            })
            .catch(error => formProcess.processBackendErrorResponse(error, formErrors))
      })
      .catch((error) => formProcess.processFrontendErrorResponse(error, formErrors))
}
</script>

<template>
  <form method="post" @submit.prevent="submitDesignName" class="p-2">
    <FormInput
        label="Design name"
        v-model="formValues.ship_design_name"
        :error="formErrors.ship_design_name"
    />
    <div class="flex justify-end">
      <FormSubmit v-if="shipDesignStore.designError===''" text="Save design" class="justify-end"/>
    </div>
  </form>
</template>

<style scoped lang="scss">

</style>