import { defineStore } from 'pinia';
import type ShipComponent from "@/types/ShipComponent";
import type SelectedShipComponent from "@/types/SelectedShipComponent";
import Backend from '@/models/backend.ts'
import type PlayerShipDesign from '@/types/PlayerShipDesign.ts'
import { useAssetStore } from '@/stores/assetStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'

export const useShipDesignCreationStore = defineStore('shipDesignCreationStore', {
    state: () => ({
        components: [] as SelectedShipComponent[],
        hasFrame: false,
        hasEngine: false,
        designError: '',
        shipEngineThrust: 0,
        shipMaxSpeed: 0,
        shipHitPoints: 0,
        shipShields: 0,
        shipAttackShips: 0,
        shipAttackPlanets: 0,
        shipRepair: 0,
        shipFrame: 0,
        shipMass: 0,
        shipScanningRange: 0,
        shipProductionCost: 0
    }),
    getters: {
        findSelectedComponentById: (state) => (id: number): SelectedShipComponent | undefined => {
            return state.components.find(c => c.id === id);
        },
        getComponentCodes: (state) => {
            return state.components.map(c => c.component.code);
        }
    },
    actions: {
        initFromDesign(design: PlayerShipDesign) {
            this.resetDesign()
            const assetStore = useAssetStore();
            const gameStore = useGameStore();
            for (const value of Object.values(design.components)) {
                const component = assetStore.findShipComponentByCode(value)
                if(!component) {
                    continue
                }
                //Check if the user has access to the compontent
                for(const code of gameStore.playerShipComponents.values()){
                    if(code === component.code) {
                        this.addComponent(component, false)
                    }
                }

            }
            this.recalculateDesign()
        },
        addComponent(component: ShipComponent,recalculate:boolean) {
            console.log('adding component', component);
            const id = Math.floor(Math.random() * 1000000);
            const newComponent: SelectedShipComponent = {
                id: id,
                component: component
            };
            this.components.push(newComponent);
            if (component.group === 'frame') {
                this.hasFrame = true;
            }
            if (component.group === 'engine') {
                this.hasEngine = true;
            }
            if(recalculate){
                this.recalculateDesign();
            }
        },
        removeComponent(id: number) {
            const index = this.components.findIndex(c => c.id === id);
            if (index !== -1 && this.components[index].component.group === 'frame') {
                this.components = [];
                this.hasFrame = false;
            } else {
                this.components.splice(index, 1);
            }
            // Check if there are any engines left
            if (this.components.findIndex(c => c.component.group === 'engine') === -1) {
                this.hasEngine = false;
            }
            this.recalculateDesign();
        },
        recalculateDesign(){
            const backend = new Backend();

            const componentCodes = this.components.map(c => c.component.code);

            backend.verifyShipDesign(componentCodes).then((response:any) => {

                this.shipEngineThrust = response.data.stats.ship_engine_thrust;
                this.shipMaxSpeed = response.data.stats.ship_max_speed;
                this.shipHitPoints = response.data.stats.ship_hit_points;
                this.shipShields = response.data.stats.ship_shields;
                this.shipAttackShips = response.data.stats.ship_attack_ships;
                this.shipAttackPlanets = response.data.stats.ship_attack_planets;
                this.shipRepair = response.data.stats.ship_repair;
                this.shipFrame = response.data.stats.ship_frame;
                this.shipMass = response.data.stats.ship_mass;
                this.shipScanningRange = response.data.stats.ship_scanning_range;
                this.shipProductionCost = response.data.stats.ship_production_cost;
                this.designError = response.data.error;

            }).catch((error) => {
                this.designError = error.response.data.message;
            })
        },
        resetDesign(){
            this.components = [];
            this.hasFrame = false;
            this.hasEngine = false;
            this.shipEngineThrust = 0;
            this.shipMaxSpeed = 0;
            this.shipHitPoints = 0;
            this.shipShields = 0;
            this.shipAttackShips = 0;
            this.shipAttackPlanets = 0;
            this.shipRepair = 0;
            this.shipFrame = 0;
            this.shipMass = 0;
            this.shipScanningRange = 0;
            this.shipProductionCost = 0;
        }
    }
});
