<script setup lang="ts">
// Import defineProps to define the component props
import {computed, defineProps, onMounted, ref, watch} from 'vue';
import ToolTip from "../../../../../../../galexion/frontend-web/galexion/src/components/Interface/ToolTip/ToolTip.vue";
import {usePlanetStore} from "@/stores/planetStore";
import Icon from "../../../../../../../galexion/frontend-web/galexion/src/components/Objects/Icons/Icon.vue";
import IconCount from '@/components/Objects/IconCount.vue'


// Define the props your component expects
const props = defineProps({
  type: String,
  planetId: Number
});

const planetStore = usePlanetStore()

const planetData = computed(() => planetStore.findPlanetById(props.planetId))
//const oreData = computed(() => assetStore.findOreById(props.type))

const url = ref('');
const amount = ref(0);
const oreId = ref(0);
const iconType = ref('');

onMounted(() => {
  updateAmount()
});

watch(() => planetData.value.timestamp, (newValue) => {
  if(newValue) {
      updateAmount()
  }
});

//update function
const updateAmount = () => {
  if(planetData.value) {
    if(props.type === 'primary_ore') {
      amount.value = planetData.value.primaryOrePerTurn || 0;
      url.value = 'planet/' + planetData.value.id + '/ore/primary';
      oreId.value = planetData.value.primaryOreId;
      iconType.value = 'ore';
    } else if(props.type === 'secondary_ore') {
      amount.value = planetData.value.secondaryOrePerTurn || 0;
      url.value = 'planet/' + planetData.value.id + '/ore/secondary';
      oreId.value = planetData.value.secondaryOreId;
      iconType.value = 'ore';
    } else if(props.type === 'energy') {
      amount.value = planetData.value.energyPerTurn || 0;
      url.value = 'planet/' + planetData.value.id + '/energy';
      oreId.value = 0;
      iconType.value = 'energy';
    } else {
      throw new Error('unknown type')
    }

  }
};

</script>

<template>
  <span v-if="amount != 0">
    <tool-tip :url="url">
       <Icon :type="iconType" :id="oreId"></Icon>
       <IconCount :amount="amount"></IconCount>
    </tool-tip>
  </span>
</template>

<style scoped>
</style>
