<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore'
import IconCount from '@/components/Objects/IconCount.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import CurrentTech from '@/components/Sections/Research/CurrentTech.vue'
import { usePlanetStore } from '@/stores/planetStore.ts'
import { RouterLink } from 'vue-router'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { formatNumber } from '@/models/formatNumber.ts'
import { useTableStore } from '@/stores/tableStore.ts'
import { usePlayerCreditStore } from '@/stores/playerCreditStore.ts'

const gameStore = useGameStore();
const assetStore = useAssetStore();
const planetStore = usePlanetStore();
const shipStore = useShipStore();
const tableStore = useTableStore();
const playerCreditStore = usePlayerCreditStore();

const showScienceToolTip = ref(false)
const showDayToolTip = ref(false)
const showVipToolTip = ref(false)

const toggleChat = () => {
  const chatVisible = document.documentElement.style.getPropertyValue('--chat-display');
  if(chatVisible === 'block') {
    document.documentElement.style.setProperty('--chat-display', 'none');
    document.documentElement.style.setProperty('--chat-height', '0rem');
  } else {
    document.documentElement.style.setProperty('--chat-display', 'block');
    document.documentElement.style.setProperty('--chat-height', '16rem');
  }
};

const techPercentage = computed(() => {
  const tech = assetStore.findTechById(Number(gameStore.player?.currentTechId))
  if(!tech) {
    return 0;
  }
  const percentage = Math.floor((Number(gameStore.player?.sciencePoints)/ tech?.cost)*100);
  if (percentage > 100) {
    return 100;
  }
  return percentage;
})

const scienceOutput = computed(() => {
  if(gameStore.websocketInitialSyncCompleted) {
    return planetStore.getTotalScienceOutput()
  }
  return 0;
});

const creditsOutput = computed(() => {
  if(gameStore.websocketInitialSyncCompleted) {
    return playerCreditStore.calculateCreditsPerTurn()
  }
  return 0;
});

const timeRemainingPercentage = ref(0);

const updateTimeRemainingPercentage = () => {
  const timeSinceTurn = (( Date.now()/1000) - gameStore.turnedAt);
  const percentage = (timeSinceTurn / ((gameStore.turnInterval*60)/100));
  timeRemainingPercentage.value = Math.min(100, Math.max(0, percentage));
};

onMounted(() => {
  // Update immediately and then every second
  updateTimeRemainingPercentage();
  const interval = setInterval(updateTimeRemainingPercentage, 1000);

  // Clear the interval when the component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });
});

</script>

<template>
  <div class="footer bg-slate-600 flex items-center justify-between text-white text-sm w-full">
    <span class="pl-2 pr-2 hidden sm:block">{{ gameStore.player?.nickName }}</span>
    <div class="flex flex-grow items-center justify-center">
      <div>
        <div v-if="showDayToolTip" class="current-tech bg-slate-800 border-1 border-slate-700 p-2">
          <div class="mb-1 pb-1 border-b border-b-slate-600">Progress in the game is measured in Days and Weeks.</div>
          <div class="mb-1 pb-1 border-b border-b-slate-600">In-game days last {{ (gameStore.shipTurnInterval * gameStore.turnInterval) }} real minutes, weeks are {{ (gameStore.planetTurnInterval * gameStore.turnInterval) }} real minutes.</div>
          <div>Ships tick every day, everything else ticks every Week.</div>
          <div></div>
        </div>
        <div class="relative me-1 overflow-hidden rounded-md cursor-pointer"
             @click="showDayToolTip = true"
             @mouseleave="showDayToolTip = false">
          <div class="bg-slate-700 absolute inset-0"></div>
          <div class="bg-slate-800 absolute inset-0" :style="{ width: timeRemainingPercentage + '%' }"></div>
          <div class="p-1 ps-2 pe-2 relative z-10">
            Day {{ gameStore.turn }}
          </div>
        </div>
      </div>
      <RouterLink to="/research">
        <div class="sm:block flex-col hidden">
          <div v-if="showScienceToolTip" class="current-tech">
            <CurrentTech></CurrentTech>
          </div>
          <div class="bg-slate-700 p-1 rounded-md w-52 flex ps-2 h-7 justify-between"
            @mouseenter="showScienceToolTip = gameStore.player?.currentTechId != 0"
            @mouseleave="showScienceToolTip = false"
          >
            <div><Icon type="science" :id="0" title="Science" :size="4"></Icon><IconCount :amount="scienceOutput"></IconCount></div>
            <div class="rounded bg-slate-600 flex-grow h-4 mt-0.5 mx-1">
              <div v-if="gameStore.player?.currentTechId != 0" class="bg-blue-600 text-xs text-blue-100 p-0.5 text-center leading-none rounded whitespace-nowrap" :style="'width:' + techPercentage + '%'"> {{techPercentage}}%</div>
              <div v-else class="bg-red-600 text-xs p-0.5 text-center leading-none rounded whitespace-nowrap" :style="'width:100%'">Not researching</div>
            </div>
          </div>
        </div>
      </RouterLink>
      <RouterLink :to="'/government/finances/'">
        <div class="bg-slate-700 p-0.5 ps-2 pe-2 rounded-md ms-1 hidden sm:flex">
          <Icon type="credit" :id="0" title="Credits" :size="4"></Icon><IconCount :amount="creditsOutput" class="pt-0.5 ps-1"></IconCount>
          <div class="ps-2 pt-0.5">{{ formatNumber(Number(gameStore.player?.credits)) }}</div>
        </div>
      </RouterLink>
      <RouterLink :to="'/planets/' + tableStore.planets.singleSelectedId">
      <div class="bg-slate-700 p-0.5 ps-2 pe-2 rounded-md ms-1 flex">
        <img :src="'' + gameStore.cdn + 'images/planet/new/64/planet_3.png'" alt="Planets" class="w-4 h-4 mt-1">
        <div class="ps-2 pt-0.5">{{planetStore.planets.size}}</div>
      </div>
      </RouterLink>
      <RouterLink :to="'/command/ships/' + tableStore.ships.singleSelectedId">
        <div class="bg-slate-700 p-0.5 ps-2 pe-2 rounded-md ms-1 flex">
          <img :src="'' + gameStore.cdn + 'images/ship/ship.png'" alt="Ships" class="w-4 h-4 mt-1">
          <div :class="{
            'ps-2 pt-0.5': true,
            'text-red-600': shipStore.ships.size > 999
          }">{{shipStore.ships.size}}</div>
        </div>
      </RouterLink>
      <RouterLink to="/government/cabinet" v-if="Number(gameStore.player?.vipTokens)>0">
        <div v-if="showVipToolTip" class="current-tech bg-slate-800 border-1 border-slate-700 p-2">
          Use VIP Tokens to hire Ministers for your Cabinet.<br>Ministers provide bonuses to your empire.
        </div>
        <div class="bg-slate-700 p-0.5 ps-2 pe-2 rounded-md ms-1 flex"
             @mouseenter="showVipToolTip = true"
             @mouseleave="showVipToolTip = false"
        >
          <Icon type="vip" :size="4"></Icon>
          <div class="pl-2">{{gameStore.player?.vipTokens}}</div>
        </div>
      </RouterLink>
    </div>
    <span class="pe-2">
      <StandardButton @click="toggleChat">Chat</StandardButton>
    </span>
  </div>
</template>

<style scoped>
.footer {
  height: 2rem;
}
.current-tech {
  position: absolute;
  bottom: 2rem;
}
</style>