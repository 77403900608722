<script setup lang="ts">
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Backend from '@/models/backend.ts'
import { ref } from 'vue'
import { usePQAStore } from '@/stores/PQAStore.ts'

// Get the planet data
const gameStore = useGameStore()
const pqaStore = usePQAStore()
const backend = new Backend()

const targetPQAId = ref(0)

// Handle form submission
const setPQA = () => {
  backend.planetSetPQA(gameStore.modalData,targetPQAId.value).then((result) => {
    gameStore.modalType=''
  })
}

</script>

<template>
  <SmallModel title="Set production queue">
    <div class="p-2">
      <div class="text-subtext pb-2">Select the production queue you wish to assign to the selected planets</div>
      <div>
        <select v-model="targetPQAId" @change="setPQA" class="min-w-56">
          <template v-for="pqa in pqaStore.queues.values()" :key="pqa.id">
            <option :value="pqa.id">{{ pqa.name}}</option>
          </template>
        </select>
      </div>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>