import { defineStore } from 'pinia'
import { usePlanetStore } from '@/stores/planetStore.ts'
import type PlayerCreditBalanceSheet from '@/types/PlayerCreditBalanceSheet.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useAssetStore } from '@/stores/assetStore.ts'


export const usePlayerCreditStore = defineStore('playerCreditStore', {
    state: () => ({
        playerCreditBalanceSheet: {} as PlayerCreditBalanceSheet
    }),
    getters: {

    },
    actions: {
        calculateCreditsPerTurn() {
            const planetStore = usePlanetStore();
            const gameStore = useGameStore();
            const assetStore = useAssetStore();

            let credits = 0;

            // Calculate credits from planets
            credits += planetStore.getTotalCreditOutput()

            // Calculate debits from minister salaries
            for(const minister of gameStore.playerMinisters.values()) {
                credits -= assetStore.findMinisterSalaryByCode(minister)
            }

            return credits;
        }
    }
})
