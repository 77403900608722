<script setup lang="ts">

import {computed} from 'vue'
import { useFleetStore } from '@/stores/fleetStore.ts'

import Backend from '@/models/backend.ts'
import router from "@/router";
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ShipCurrentActions
  from '@/components/Sections/Command/Ships/ShipDetails/ShipDetailTabs/ShipActions/Current/ShipCurrentActions.vue'
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import Eta from '@/models/eta.ts'

const fleetStore = useFleetStore()
const shipActionStore = useShipActionStore()
const gameStore = useGameStore()
const mapStore = useMapStore()
const eta = new Eta();

const props = defineProps({
  fleetId: Number
});

const fleetData = computed(() => fleetStore.findFleetById(props.fleetId??0))

const initFleetAction = () => {
  router.push('/map/galaxy/')
  // clear map area selection
  mapStore.areaBoxTL = {x:0,y:0}
  mapStore.areaBoxRB = {x:0,y:0}
  shipActionStore.initFleet(fleetData.value)
}

const clearFleetAction = () => {
  const backend = new Backend();
  backend.setFleetActions(props.fleetId,[])
    .then(() => {
      gameStore.setTinySuccessToastMessage('Orders cleared')
    })
    .catch((error) => {
      gameStore.setTinyErrorToastMessage(error.response.data.message)
      throw error
    })
}

// A computed property to calculate eta for each action
const calculateEtas = computed(() => {
  if (!fleetData.value || !fleetData.value.actions.length) return [];

  eta.start(fleetData.value?.xPos, fleetData.value?.yPos);

  return fleetData.value.actions.map((action: any) => {
    if (action.targetX !== undefined && action.targetY !== undefined) {
      // Use the Eta model to calculate the ETA for each action
      if(fleetData.value) {
        return eta.etaNext(action.targetX, action.targetY, fleetData.value.maxSpeed);
      }
    }
    return 0;
  });
});

</script>

<template>
  <div class="content-header mt-1">
    <div class="flex justify-between">
      <div>Action Queue</div>
      <div>
        <StandardButton @click="clearFleetAction">Clear</StandardButton>
        <StandardButton @click="initFleetAction">Set</StandardButton>
      </div>
    </div>
  </div>
  <div v-if="fleetData?.actions.length === 0" class="text-center pt-4">
    <div class="pt-1 pb-3 text-subtext">Your fleet is doing nothing</div>
    <StandardButton @click="initFleetAction">Set an action</StandardButton>
  </div>
  <div v-else>
    <div v-for="(action, index) in fleetData.actions" :key="index">
      <ShipCurrentActions :shipActionData="action" :eta="calculateEtas[index]"/>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>