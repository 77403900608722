<script setup lang="ts">
// Import defineProps to define the component props
import { defineProps } from 'vue'
import { useGameStore } from '@/stores/gameStore'

const gameStore = useGameStore();

// Define the props your component expects
const props = defineProps({
  type: {
    type: String,
    required: true
  }
});

</script>

<template>
  <img
      :src="'' + gameStore.cdn + 'images/stars/star_' + props.type + '.png'"
      alt=""
  >
</template>

<style scoped lang="scss">

</style>
