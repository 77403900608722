<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Position from '@/components/Interface/Position.vue'
import PlayerName from '@/components/Objects/PlayerName.vue'

const gameStore = useGameStore()

const props = defineProps<{
  shipActionData: {
    action: string;
    targetX: number;
    targetY: number;
    targetPlayer: number;
    targetShip: number;
    data: {
      name: string;
      type: number;
      typeVariantId: number;
      targetPlayer: number;
    }
  },
  eta: number
}>();

</script>

<template>
  <div class="p-2 bg-slate-800 mt-1 mb-1 flex">
    <div class="flex-shrink">
      <img :src="'' + gameStore.cdn + '/images/ship/actions/path.png'" alt="Idle" class="h-19 w-12 mr-3 p-1" />
    </div>
    <div class="flex-grow pt-3">
      <div class="text-subtext flex justify-items-start align-middle">
        <div class="flex items-center pe-1">Follow & Attack </div>
        <div class="flex items-center pe-1">
          <PlayerName :player-id="shipActionData.targetPlayer"/>
        </div>
        <div class="flex items-center">
          to <Position :x="props.shipActionData.targetX" :y="props.shipActionData.targetY"/>
          <span v-if="props.shipActionData.data.status === 'tracking'">(Tracking)</span>
          <span v-if="props.shipActionData.data.status === 'on_position'">(Caught up with target)</span>
          <span v-if="props.shipActionData.data.status === 'lost_tracking'">(Lost tracking, moving to last known position)</span>
        </div>
      </div>
    </div>
    <div v-if="eta>0" class="flex-shrink flex items-center text-subtext">
      {{ eta }} days
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>