<script setup lang="ts">

import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'

const props = defineProps<{
  toast: boolean,
  toastImage: string,
  toastText: string,
}>();

</script>

<template>
  <div v-if="props.toast">
    <ToastItem>
      <template #image>
        <ImageRoundedUrl
          :url="toastImage"
          :size="3"
        />
      </template>
      <template #text>
        {{ toastText }}
      </template>
    </ToastItem>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">

</style>