<script setup lang="ts">

import { computed } from 'vue'

const props = defineProps<{
  value: number
}>()

const adjustedValue = computed(() => {
  if(props.value < 0) {
    return 0
  }
  if(props.value > 100) {
    return 100
  }
  return Math.floor(props.value)
})

</script>

<template>
  <span :class="{
    'text-green-500': props.value > 90,
    'text-green-600': props.value > 75 && props.value <= 90,
    'text-yellow-500': props.value > 25 && props.value <= 75,
    'text-orange-500': props.value > 25 && props.value <= 50,
    'text-red-500': props.value <= 25
  }">
    <span>{{ adjustedValue }}%</span>
  </span>
</template>

<style scoped lang="scss">

</style>