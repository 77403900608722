<script setup lang="ts">

import { computed } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import ShipStatIcons from "@/components/Objects/IconsWithCounters/ShipStatIcons.vue";
import ShipDetailsTabs from "@/components/Sections/Command/Ships/ShipDetails/ShipDetailsTabs.vue";
import Icon from "@/components/Objects/Icons/Icon.vue";
import Position from "@/components/Interface/Position.vue";
import ShipMenu from '@/components/Interface/Menu/ShipMenu.vue';
import router from '@/router'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import Percentage from '@/components/Interface/Text/Percentage.vue'

const shipStore = useShipStore()
const gameStore = useGameStore()

const props = defineProps({
  shipId: Number
});

const shipData = computed(() => {
  return shipStore.findShipById(props.shipId ?? 0)
})

</script>

<template>
  <div v-if="props.shipId !==0 && shipData">
    <span class="shipHeader flex flex-col justify-between bg-cover" :style="'background-image: url(' + gameStore.cdn + 'images/ship/' + shipData.type + '.webp)'">
      <span class="p-2 text-2xl flex justify-between w-full items-center">
        <span class="flex items-center">
          <ShipMenu :shipId="props.shipId"></ShipMenu>
          {{ shipData.shipName }}
          <Position :x="shipData.xPos" :y="shipData.yPos" class="ps-1"></Position>
        </span>
        <span class="self-end text-md block sm:hidden"><StandardButton
          color="red"
          @click="() => router.push('/command/ships')"
          :end-margin="false"
        >X</StandardButton></span>
      </span>
    </span>
    <div class="flex justify-between w-full bg-slate-700 p-2">
        <span class="flex-1 whitespace-nowrap">

        </span>
        <span class="flex-1 whitespace-nowrap text-center">
          <Icon type="ship_hit_points" class="pr-1"/> <Percentage :value="shipData.healthPercentage"></Percentage>
        </span>
        <span class="flex-1 whitespace-nowrap text-right">

        </span>
    </div>
    <div class="pt-1">
      <div class="grid grid-cols-4 sm:grid-cols-6 gap-1">
        <div class="bg-slate-800 text-subtext text-center p-2">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="ship_attack_ships" :amount="shipData.attackShips" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext p-2">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="ship_attack_planets" :amount="shipData.attackPlanets" />
          </div>
        </div>
        <div class="bg-slate-800 text-center text-subtext p-2">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="ship_shields" :amount="shipData.shields" />
          </div>
        </div>
        <div class="bg-slate-800 text-subtext text-center p-2">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="ship_max_speed" :amount="shipData.maxSpeed" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext p-2">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="ship_scanning_range" :amount="shipData.scanningRange" />
          </div>
        </div>
        <div class=" bg-slate-800 text-center text-subtext p-2">
          <div class="flex justify-center p-1">
            <ShipStatIcons type="ship_repair" :amount="shipData.repair" />
          </div>
        </div>
      </div>
    </div>
    <ShipDetailsTabs :shipId="props.shipId"></ShipDetailsTabs>
  </div>
</template>

<style scoped lang="scss">
.shipHeader{
  height: 12rem;
  background-size: cover;
  background-position: center;
}
</style>