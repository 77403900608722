<script setup lang="ts">
// Import defineProps to define the component props
import { computed, defineProps } from 'vue'
import { useGameStore } from '@/stores/gameStore'

const gameStore = useGameStore();

// Define the props your component expects
const props = defineProps({
  type: {
    type: Number,
    required: true
  },
  size: {
    type: Number,
    default: 2
  }
});
// Calculate the width and height of the image based on the planet size from 1 = 0.5 to 10 = 2.5
const imageSize = computed(() => {
  return (props.size*0.8);
})


</script>

<template>
  <img
      :src="'' + gameStore.cdn + 'images/planet/new/64/planet_' + props.type + '.png'"
      :alt="'Size : ' + props.size"
      :title="'Size : ' + props.size"
      :style="'width: ' + imageSize + 'rem; height: ' + imageSize + 'rem;'"
  >
</template>

<style scoped lang="scss">

</style>
