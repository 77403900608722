<script setup lang="ts">

import { usePQAStore } from '@/stores/PQAStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import Backend from '@/models/backend.ts'
import { ref, onMounted } from 'vue'

const pqaStore = usePQAStore()
const gameStore = useGameStore()

const playerConquerPQA = ref(0)

const setPQA = () => {
  const backend = new Backend()
  backend.setPlayerConquerPQA(playerConquerPQA.value).then((result) => {
    gameStore.modalType=''
  })
}

onMounted(() => {
  playerConquerPQA.value = gameStore.player?.conquerPQA ?? 0
})


</script>

<template>
  <div class="p-2">
    <div class="pb-2 text-green-500">Select the production queue you wish to assign to newly conquered planets</div>
    <div>
      <select v-model="playerConquerPQA" @change="setPQA" class="min-w-56">
        <template v-for="pqa in pqaStore.queues.values()" :key="pqa.id">
          <option :value="pqa.id">{{ pqa.name}}</option>
        </template>
      </select>
    </div>
  </div>
</template>

<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>