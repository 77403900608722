import { defineStore } from 'pinia'
import type OreConversions from '@/types/OreConversions.ts'

export const useOreConversionStore = defineStore('oreConversionStore', {
    state: () => ({
        data: {
            planetLavaToBezantium: {
                capacity: 230,
                enabled: true,
            },
            planetLavaToRadiuSant: {
                capacity: 34,
                enabled: true,
            },
            planetDesertToNucloid: {
                capacity: 1,
                enabled: true,
            },
            planetDesertToVerrotan: {
                capacity: 5,
                enabled: true,
            },
            planetTemperateToBoturKoec: {
                capacity: 353433,
                enabled: true,
            },
            planetTemperateToNagazal: {
                capacity: 343,
                enabled: true,
            },
            planetOceanicToFormets: {
                capacity: 0,
                enabled: true
            },
            planetOceanicToGrindazine: {
                capacity: 43535,
                enabled: false
            },
            planetGasToPerunga: {
                capacity: 10,
                enabled: true,
            },
            planetGasToVeronium: {
                capacity: 50,
                enabled: true,
            },
            planetIceToBlazanter: {
                capacity: 100,
                enabled: true
            },
            planetIceToTerrilliod: {
                capacity: 500,
                enabled: true
            }
        } as OreConversions
    }),
    getters: {
    },
    actions: {

    }
})
