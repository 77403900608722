<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { useMapStore } from '@/stores/mapStore.ts'
import type MapCoordinate from '@/types/MapCoordinate.ts'
import MapToolTipStar from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapToolTip/MapToolTipStar.vue'
import MapToolTipPlanet from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapToolTip/MapToolTipPlanet.vue'
import MapToolTipShips from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapToolTip/MapToolTipShips.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'
import { useTableStore } from '@/stores/tableStore.ts'

// Define props
const props = defineProps<{
  toolTipVisible: boolean
}>();

const mouseX = ref(0);
const mouseY = ref(0);;

// Data to be displayed in the tooltip
const coordinate = ref<MapCoordinate | null>(null);

const mapStore = useMapStore();
const gameStore = useGameStore();
const mapCoordinateStore = useMapCoordinateStore()

// Store the last mouse event
let lastMouseEvent: MouseEvent | null = null;

const updateMousePosition = (event: MouseEvent) => {
  lastMouseEvent = event;
};

onMounted(() => {
  window.addEventListener('mousemove', updateMousePosition);
});

onUnmounted(() => {
  window.removeEventListener('mousemove', updateMousePosition);
});

const loadMapCoordinate = async () => {
    const data = mapCoordinateStore.getByCoordinates(mapStore.tooltipX, mapStore.tooltipY);
    //If we are on space
    if(data.mapCoordinate.type == 0) {
      //Are we dealing with only one player?
      if (Object.keys(data.mapCoordinate.ships).length == 1) {
        //If so, check if the player is the current player
        if (Object.keys(data.mapCoordinate.ships).includes(String(gameStore.player?.id))) {
          //If so, we don't want to show the tooltip
          gameStore.modalType = 'mapSelectPlayerShip'
          gameStore.modalData = {
            xPos: mapStore.tooltipX,
            yPos: mapStore.tooltipY,
          }
          coordinate.value = null;
          return;
        }
      }
    }

    coordinate.value = data.mapCoordinate;
};

//
const jumpToPosition = () => {
  if (coordinate.value) {
    mapStore.gotoX = coordinate.value.xPos;
    mapStore.gotoY = coordinate.value.yPos;
  }
};

// Watcher for toolTipVisible prop to update position when it turns true
watch(() => props.toolTipVisible, (newValue) => {
  if (newValue && lastMouseEvent) {
    // Set the position where the tooltip should be displayed, keep in mind that the tooltip should not be displayed outside the viewport
    const tooltipWidth = 320; // Assume a fixed tooltip width
    const tooltipHeight = 300; // Assume a fixed tooltip height
    const padding = 10;

    let x = lastMouseEvent.clientX;
    let y = lastMouseEvent.clientY;

    // Adjust x position to avoid overflow
    if (x + tooltipWidth + padding > window.innerWidth) {
      x = window.innerWidth - tooltipWidth - padding;
    }

    // Adjust y position to avoid overflow
    if (y + tooltipHeight + padding > window.innerHeight) {
      y = window.innerHeight - tooltipHeight - padding;
    }

    mouseX.value = x;
    mouseY.value = y;
  }
});
// Load the map coordinate when the tooltip is visible
watch(() => props.toolTipVisible, () => {
  if (props.toolTipVisible) {
    loadMapCoordinate();
  }
});
</script>

<template>
  <div v-show="props.toolTipVisible && coordinate!=null" :style="{ left: mouseX + 'px', top: mouseY + 'px' }" class="tooltip z-20">
    <div>
      <div class="bg-slate-800 flex">
        <div v-if="Number(coordinate?.type) > 0" class="w-40">
          <div class="content-header flex justify-between">
            <div class="me-2 overflow-hidden text-nowrap" :title="coordinate?.typeName">
              {{ coordinate?.typeName }}
            </div>
            <div class="flex items-center justify-end text-nowrap w-6">
              <img alt="Center on coordinate" class="w-4 h-4 cursor-pointer" :src="'' + gameStore.cdn + '/images/icons/zoom-in.png'" @click="jumpToPosition()">
            </div>
          </div>
          <!-- Planets -->
          <div v-if="Number(coordinate?.type) == 2 && coordinate!=null">
            <MapToolTipPlanet :coordinate="coordinate"></MapToolTipPlanet>
          </div>
          <!-- Stars -->
          <div v-else-if="Number(coordinate?.type) == 1 && coordinate!=null">
            <MapToolTipStar :coordinate="coordinate"></MapToolTipStar>
          </div>
        </div>
        <div v-if="coordinate?.ships && Object.keys(coordinate?.ships).length>0">
          <MapToolTipShips :coordinate="coordinate"></MapToolTipShips>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tooltip {
  position: absolute;
}
</style>
