<script setup lang="ts">

import { computed, defineProps, type PropType } from 'vue'
import type OreConversion from '@/types/OreConversion.ts'

const props = defineProps({
  conversion: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  objectImage: {
    type: String,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  left: {
    type: Number,
    required: true
  },
  midTime: {
    type: Number,
    required: false,
    default: 0.5
  },
  minSpeed: {
    type: Number,
    required: false,
    default: 1
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})

const conveyorColor = computed(() => {
  if (props.conversion.capacity == 0) {
    return '#1E293B'; // Red if either conversion is zero or negative
  }
  return 'black'; // Green if both conversions are positive
});

const oreSpeed = computed(() => {
  if (props.conversion.capacity <= 0) {
    return 0; // No speed if ore processed is zero or negative
  }

  // Calculate logarithmic speed inversely
  const logValue = Math.log10(10000 / props.conversion.capacity);

  // Define a base speed that increases with ore processed
  return Math.max(props.minSpeed, logValue); // Ensure a minimum speed of 0.3
});

</script>

<template>
  <svg :class="{
          'absolute':true,
          'border':debug
        }"
       :style="'top:' + top + 'rem;left:' + left + 'rem;width:' + width + 'rem;height:' + height + 'rem;z-index:10;'"
       xmlns="http://www.w3.org/2000/svg"
  >

    <!-- Original rectangles -->
    <rect x="0" y="0" :width="width + 'rem'" height="1rem" :fill="conveyorColor" />
    <rect :x="(width-1) + 'rem'" y="0" width="1rem" :height="height + 'rem'" fill="conveyorColor"/>

    <image
      v-if="conversion.capacity > 0"
      :href="objectImage"
      x="0"
      y="0"
      width="1rem"
      height="1rem">
      <!-- Animate the downward movement -->
      <animate
        attributeName="y"
        :values="'0;0;' + height + 'rem'"
        :keyTimes="'0;' + midTime + ';1'"
        :dur="oreSpeed + 's'"
        repeatCount="indefinite" />
      <!-- Animate the leftward movement -->
      <animate
        attributeName="x"
        :values="'0;' + (width-1) + 'rem;' + (width-1) + 'rem'"
        :keyTimes="'0;' + midTime + ';1'"
        :dur="oreSpeed + 's'"
        repeatCount="indefinite" />
    </image>
  </svg>
</template>

<style scoped lang="scss">

</style>