<script setup lang="ts">

import {computed, defineComponent, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetProductionTabAvailable
  from "@/components/Sections/Planets/PlanetTabs/ProductionTab/PlanetProductionTabAvailable.vue";
import PlanetProductionProgressObject
  from "@/components/Sections/Planets/PlanetTabs/ProductionTab/Objects/PlanetProductionProgressObject.vue";
import PlanetProductionTabQueue
  from "@/components/Sections/Planets/PlanetTabs/ProductionTab/PlanetProductionTabQueue.vue";
import ProductionQueueAutomation
  from '@/components/Sections/Planets/PlanetTabs/PlanetProductionQueueAutomation/PQA.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import Backend from '@/models/backend.ts'
import { useGameStore } from '@/stores/gameStore.ts'


/**
 * Planet production tab component
 */
defineComponent({
  name: 'PlanetProduction',
})

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()
const gameStore = useGameStore()
const planetData = computed(() => planetStore.findPlanetById(props.planetId))

const clearQueue = () => {
  const backend = new Backend()
  backend.clearProductionFromPlanet([planetData.value.id])
  gameStore.setTinySuccessToastMessage('Production queue cleared')
}

</script>

<template>
  <div class="grid lg:grid-cols-1 xl:grid-cols-2">
    <div class="flex-1">
      <div class="content-header mt-1 flex justify-between">
        <div>Current Production</div>
        <div>
          <div class="relative !font-normal !text-xs">
            <StandardButton
              @click="clearQueue"
              :end-margin="false"
            >Clear all</StandardButton>
          </div>
        </div>
      </div>
      <div class="py-1">
        <PlanetProductionProgressObject
          :planet-id="planetData.id"
          :details="true"
        ></PlanetProductionProgressObject>
      </div>
      <div class="text-left text-xs font-bold text-slate-400 bg-slate-700 p-2 flex justify-between">
        <div>Queued</div>
        <ProductionQueueAutomation
          :planet-id="props.planetId"
          :planet-queue-automation-id="planetData.planetQueueAutomationId"
        ></ProductionQueueAutomation>
      </div>
      <PlanetProductionTabQueue :planetId="planetData.id"></PlanetProductionTabQueue>
    </div>
    <div class="flex-1 xl:ps-1">
      <div class="content-header mt-1">Available</div>
      <PlanetProductionTabAvailable :planetId="planetData.id"></PlanetProductionTabAvailable>
    </div>
  </div>
</template>

<style scoped lang="scss">
</style>
