<script setup lang="ts">

import ConveyorTLBR from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTLBR.vue'

import { defineProps, computed } from 'vue'
import type { PropType } from 'vue';
import { useGameStore } from '@/stores/gameStore.ts'
import type OreConversion from '@/types/OreConversion.ts'
import ConveyorTRBL from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTRBL.vue'
import ConveyorTB from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTB.vue'
import Ore from '@/components/Sections/Resources/Elements/PlanetOre/Ore.vue'
import OreTrade from '@/components/Sections/Resources/Elements/OreTrade.vue'

const gameStore = useGameStore()

const props = defineProps({
  left: {
    type: Number,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  oreLeft: {
    type: Number,
    required: true
  },
  oreRight: {
    type: Number,
    required: true
  },
  oreProcessed: {
    type: Number,
    required: true
  },
  conversionLeft: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  conversionRight: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  conveyorType: {
    type: String,
    required: false,
    default: 'level1'
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})


</script>

<template>
<div :class="{
          'absolute':true,
          'border':debug
        }"
     :style="'top:' + top + 'rem;left:' + left + 'rem;width:' + width + 'rem;height:' + height + 'rem;'">

  <ConveyorTLBR
    v-if="conveyorType == 'level1'"
    :left="1"
    :top="1"
    :height="18"
    :width="2.5"
    :midTime="0.15"
    :min-speed="5"
    :object-image="gameStore.cdn + 'images/icons/ore/' + oreLeft + '.png'"
    :conversion="conversionLeft"
    :debug="false"
  />

  <ConveyorTLBR
    v-if="conveyorType == 'level2'"
    :left="-4.5"
    :top="1"
    :height="18"
    :width="8"
    :midTime="0.3"
    :min-speed="5"
    :object-image="gameStore.cdn + 'images/icons/ore/' + oreLeft + '.png'"
    :conversion="conversionLeft"
    :debug="false"
  />

  <ConveyorTLBR
    v-if="conveyorType == 'level3'"
    :left="-14.5"
    :top="1"
    :height="18"
    :width="18"
    :midTime="0.5"
    :min-speed="8"
    :object-image="gameStore.cdn + 'images/icons/ore/' + oreLeft + '.png'"
    :conversion="conversionLeft"
    :debug="false"
  />

  <ConveyorTRBL
    v-if="conveyorType == 'level1'"
    :left="4.5"
    :top="1"
    :height="18"
    :width="2.5"
    :midTime="0.13"
    :min-speed="5"
    :object-image="gameStore.cdn + 'images/icons/ore/' + oreRight + '.png'"
    :conversion="conversionRight"
    :debug="false"
  />

  <ConveyorTRBL
    v-if="conveyorType == 'level2'"
    :left="4.5"
    :top="1"
    :height="18"
    :width="8"
    :midTime="0.3"
    :min-speed="5"
    :object-image="gameStore.cdn + 'images/icons/ore/' + oreRight + '.png'"
    :conversion="conversionRight"
    :debug="false"
  />
  <ConveyorTRBL
    v-if="conveyorType == 'level3'"
    :left="4.5"
    :top="1"
    :height="18"
    :width="18"
    :midTime="0.5"
    :min-speed="8"
    :object-image="gameStore.cdn + 'images/icons/ore/' + oreRight + '.png'"
    :conversion="conversionRight"
    :debug="false"
  />

  <div :class="{
            'absolute rounded bg-slate-800 border border-slate-700':true,
            'opacity-50':debug
          }"
       style="top:18rem;left:0rem;width:8rem;z-index:15"
  >
    <div class="flex-col">
      <img class="mx-auto p-2" :src="gameStore.cdn + '/images/icons/factory.png'">
      <div class="text-center">Factory x 2</div>
    </div>
  </div>

  <ConveyorTB
    :left="3.5"
    :top="23"
    :height="6"
    :width="2.5"
    :midTime="0.15"
    :min-speed="5"
    :object-image="gameStore.cdn + 'images/icons/ore/' + props.oreProcessed + '.png'"
    :conversion="conversionRight"
  />

  <Ore
    :ore-id="props.oreProcessed"
    :top="28"
    :left="1.5"
    :debug="debug"
  />

</div>
</template>

<style scoped lang="scss">

</style>