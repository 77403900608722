<script setup lang="ts">

import { useOreConversionStore } from '@/stores/oreConversionStore.ts'
import OreFactory from '@/components/Sections/Resources/Elements/OreFactory.vue'

const oreConversionStore = useOreConversionStore()

const debug = false
</script>

<template>

  <!-- Level 1 -->
  <OreFactory
    :oreLeft="1"
    :oreRight="2"
    :oreProcessed="13"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="15"
    :left="6"
    :height="4"
    :width="8"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="3"
    :oreRight="4"
    :oreProcessed="14"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="15"
    :left="26"
    :height="4"
    :width="8"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="5"
    :oreRight="6"
    :oreProcessed="15"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="15"
    :left="46"
    :height="4"
    :width="8"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="7"
    :oreRight="8"
    :oreProcessed="16"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="15"
    :left="66"
    :height="4"
    :width="8"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="9"
    :oreRight="10"
    :oreProcessed="17"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="15"
    :left="86"
    :height="4"
    :width="8"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="11"
    :oreRight="12"
    :oreProcessed="18"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="15"
    :left="106"
    :height="4"
    :width="8"
    :debug="debug"
  />

  <!-- Level 2 -->

  <OreFactory
    :oreLeft="13"
    :oreRight="14"
    :oreProcessed="19"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="47"
    :left="16"
    :height="4"
    :width="8"
    conveyorType="level2"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="15"
    :oreRight="16"
    :oreProcessed="20"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="47"
    :left="56"
    :height="4"
    :width="8"
    conveyorType="level2"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="17"
    :oreRight="18"
    :oreProcessed="21"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="47"
    :left="96"
    :height="4"
    :width="8"
    conveyorType="level2"
    :debug="debug"
  />

  <!-- Level 3 -->

  <OreFactory
    :oreLeft="19"
    :oreRight="20"
    :oreProcessed="22"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="79"
    :left="36"
    :height="4"
    :width="8"
    conveyorType="level3"
    :debug="debug"
  />

  <OreFactory
    :oreLeft="20"
    :oreRight="21"
    :oreProcessed="23"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="79"
    :left="76"
    :height="4"
    :width="8"
    conveyorType="level3"
    :debug="debug"
  />

  <!-- Level 4 -->

  <OreFactory
    :oreLeft="22"
    :oreRight="23"
    :oreProcessed="24"
    :conversionLeft="oreConversionStore.data.planetLavaToBezantium"
    :conversionRight="oreConversionStore.data.planetLavaToRadiuSant"
    :top="111"
    :left="56"
    :height="4"
    :width="8"
    conveyorType="level3"
    :debug="debug"
  />


</template>

<style scoped lang="scss">

</style>