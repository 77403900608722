<script setup lang="ts">

</script>

<template>
Alliances
</template>

<style scoped lang="scss">

</style>