<script setup lang="ts">
// Import defineProps to define the component props
import {computed, defineProps, onMounted, ref, watch} from 'vue';
import ToolTip from "@/components/Interface/ToolTip/ToolTip.vue";
import {usePlanetStore} from "@/stores/planetStore";
import Icon from "@/components/Objects/Icons/Icon.vue";


// Define the props your component expects
const props = defineProps({
  planetId: Number,
  noEndPadding: Boolean
});

const planetStore = usePlanetStore()

const planetData = computed(() => planetStore.findPlanetById(props.planetId))

const url = ref('');
const amount = ref(0);

onMounted(() => {
  updateAmount()
});

watch(() => planetData.value.timestamp, (newValue) => {
  if(newValue) {
      updateAmount()
  }
});

//update function
const updateAmount = () => {
  if(planetData.value) {
      amount.value = planetData.value.scanRange || 0;
    url.value = 'planet/' + planetData.value.id + '/scan-range';
  }
};

</script>

<template>
  <tool-tip :url="url">
    <Icon type="scanning_range" class="pr-1"></Icon>
    <span :class="{
      'p-0 text-green-500':true,
      'pr-3':!props.noEndPadding
    }">
     {{ amount }}
    </span>
  </tool-tip>
</template>

<style scoped lang="scss">
img {
  width: 20px;
  height: 20px;
}
</style>
