import { defineStore } from 'pinia';
import type PlayerShip from '@/types/PlayerShip';
import Eta from '@/models/eta.ts'
import { useFleetStore } from '@/stores/fleetStore.ts'

export const useShipStore = defineStore('shipStore', {
    state: () => ({
        ships: new Map<number, PlayerShip>()
    }),
    getters: {
        findShipById: (state) => (id: number): PlayerShip | undefined => {
            return state.ships.get(id);
        },
        allShipsArray: (state) => {
            return Array.from(state.ships.values());
        },
    },
    actions: {
        getNumberOfShipsUsingDesignId(designId: number): number {
            let count = 0;
            this.ships.forEach((ship) => {
                if (ship.shipDesignId === designId) {
                    count++;
                }
            });
            return count;
        },

        updateShipById(id: number, data: PlayerShip) {
            if(this.ships.has(id)) {
                const item = this.ships.get(id);
                // Manually merge changes
                for (const key in data) {
                    item[key] = data[key];
                }
                data = item;
            }

            // Calculate the eta to the next hop
            if(data.actions) {
                let etaNextHop = 0;
                if (data.actions.length > 0) {
                    const eta = new Eta();
                    eta.start(data.xPos, data.yPos);
                    const action = data.actions[0]
                    etaNextHop = eta.etaNext(action.targetX, action.targetY, data.maxSpeed);
                }
                data.etaNextHop = etaNextHop;
            } else {
                data.etaNextHop = 0;
            }

            data.healthPercentage = data.currentHitPoints / data.maxHitPoints * 100;

            this.ships.set(id, data);

            //Update fleet data
            if(data.fleetId > 0) {
                const fleetStore = useFleetStore();
                fleetStore.refreshFleetData(data.fleetId);
            }
        },

        deleteShipById(id: number) {
            if (this.ships.has(id)) {
                this.ships.delete(id);
            }
        },
        getAllCoordsTargetedForSettlement() {
            const coords:any = [];
            this.ships.forEach((ship) => {
                for(const key in ship.actions){
                    const action = ship.actions[key]
                    if(action.action === 'settle') {
                        // Check if coords already are in the list
                        let exists = false;
                        for(let i = 0; i < coords.length; i++) {
                            if(coords[i].x === action.targetX && coords[i].y === action.targetY) {
                                exists = true;
                                break;
                            }
                        }
                        if(!exists) {
                            coords.push({ 'x': action.targetX, 'y': action.targetY });
                        }
                    }
                }
            });
            return coords;
        },

        resetShips() {
            this.ships.clear();
        },

        getUnitById(id: number): PlayerShip | undefined {
            const ship = this.ships.get(id);
            if (ship !== undefined) {
                return ship;
            } else {
                console.log('Ship not found: ' + id);
            }
        },
        countShipsInFleet(fleetId: number) {
            let count = 0;
            this.ships.forEach((ship) => {
                if (ship.fleetId === fleetId && !ship.catchingUpToFleet) {
                    count++;
                }
            });
            return count;
        },
        countShipsInFleetCatchingUp(fleetId: number) {
            let count = 0;
            this.ships.forEach((ship) => {
                if (ship.fleetId === fleetId && ship.catchingUpToFleet) {
                    count++;
                }
            });
            return count;
        },
        getShipsFromFleet(fleetId: number) {
            return Array.from(this.ships.values()).filter(ship => ship.fleetId === fleetId);
        },
        getFirstShipFromFleet(fleetId: number) {
            return Array.from(this.ships.values()).find(ship => ship.fleetId === fleetId);
        },
        getSlowestSpeedFromFleet(fleetId: number) {
            let maxSpeed = 100;
            this.ships.forEach((ship) => {
                if (ship.fleetId === fleetId && ship.maxSpeed < maxSpeed) {
                    maxSpeed = ship.maxSpeed;
                }
            });
            return maxSpeed;
        },
        countShipsWithoutFleet() {
            let count = 0;
            this.ships.forEach((ship) => {
                if (ship.fleetId === 0) {
                    count++;
                }
            });
            return count;
        },
        countFleetsWithShips() {
            const fleets = new Set<number>();
            this.ships.forEach((ship) => {
                if (ship.fleetId > 0) {
                    fleets.add(ship.fleetId);
                }
            });
            return fleets.size;
        },
        isPlanetTargetedForSettlement(x: number, y: number): boolean {
            return Array.from(this.ships.values()).some(ship =>
              Object.values(ship.actions).some(action =>
                action.action === 'settle' && x === action.targetX && y === action.targetY
              )
            );
        }
    },
});
