<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import Position from '@/components/Interface/Position.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'

const playerRelationStore = usePlayerRelationStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="props.newsItem.contentData.profileImage"
    :toast-text="'You have just met ' + props.newsItem.contentData.nickname"
  >
    <ModalTrigger type="requestAudience" :data="{'relation': playerRelationStore.findRelationByPlayerId( props.newsItem.contentData.playerId)}" >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="props.newsItem.contentData.profileImage"
        :header="'First contact with ' + props.newsItem.contentData.nickname + '!'"
      >
        You have just met {{ props.newsItem.contentData.nickname }} at <Position @click.stop :x="props.newsItem.contentData.xPos" :y="props.newsItem.contentData.yPos"></Position><br>
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>