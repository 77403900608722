<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { useGameStore } from '@/stores/gameStore.ts'
import { useTableStore } from '@/stores/tableStore.ts'
import Backend from '@/models/backend.ts'

const tableStore = useTableStore()
const shipActionStore = useShipActionStore()
const gameStore = useGameStore()

const isOpen = ref(false);
const menuRef = ref(null);

function toggleMenu() {
  isOpen.value = !isOpen.value;
}

// Perform actions on the map with selected shipsshipActionData.data
const actionMap = () => {
    if (tableStore.mapShips.selectedRowIds.length > 0) {
      shipActionStore.initShipsFromIds(tableStore.mapShips.selectedRowIds);
      tableStore.mapShips.selectedRowIds = [];
      tableStore.mapShips.selectedAll = false
      gameStore.modalType=''
    }
}

/**
 * Assign ships to a fleet
 */
const attachToFleet = () => {
  if (tableStore.mapShips.selectedRowIds.length > 0) {
    gameStore.modalType = 'assignToFleet'
    gameStore.modalData = tableStore.mapShips.selectedRowIds;
    tableStore.mapShips.selectedRowIds = []
    tableStore.mapShips.selectedAll = false
  }
}

function closeMenu() {
  isOpen.value = false;
}

function handleClickOutside(event) {
  if (!menuRef.value.contains(event.target)) {
    closeMenu();
  }
}

const destroyFleet = () => {
  if (tableStore.mapShips.selectedRowIds.length > 0) {

    gameStore.modal2Type = 'confirmationDelete';
    gameStore.modal2Data = {
      title: 'Are you sure?',
      message: 'Are you sure you want to destroy these ships?',
      actionText: 'Yes',
      cancelText: 'No',
      action: () => {
        const backend = new Backend();
        backend.destroyShips(tableStore.mapShips.selectedRowIds)
        tableStore.mapShips.selectedRowIds = []
        tableStore.mapShips.selectedAll = false
      },
    };

  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>


<template>
  <div class="relative pl-2" @click.stop="toggleMenu">
    <!-- Icon Button -->
    <StandardButton >
      Action
    </StandardButton>
    <!-- Dropdown Menu -->
    <div v-show="isOpen" ref="menuRef" class="absolute w-48 border border-slate-500 bg-slate-800 rounded z-50 mt-1">
        <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="actionMap">
          Command
        </span>
        <span class="block p-1 text-sm text-red-500 hover:bg-slate-700 cursor-pointer" @click="destroyFleet">
          Destroy Ships
        </span>
        <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="attachToFleet">
          Attach to Fleet
        </span>
    </div>
  </div>
</template>

<style scoped>
/* Add any specific styles for your dropdown here */
</style>
