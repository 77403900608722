<script setup lang="ts">
// Import defineProps to define the component props
import { computed, defineProps, ref } from 'vue'
import {useAssetStore} from "@/stores/assetStore";
import {usePlanetStore} from "@/stores/planetStore";
import Backend from "@/models/backend";
import Icon from '@/components/Objects/Icons/Icon.vue'
import { useGameStore } from '@/stores/gameStore'
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import {useShipDesignStore} from "@/stores/shipDesignStore.ts";
import BuildingEffects from '@/components/Objects/Building/BuildingEffects.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import BuildingBase from '@/components/Sections/Planets/PlanetTabs/BuildingBase.vue'

import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import { useFleetStore } from '@/stores/fleetStore.ts'
import SingleMulti from '@/components/Objects/SingleMulti.vue'

/**
 * props
 */
// Defining props with types and default values
const props = defineProps({
  planetId: Number
});

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const planetStore = usePlanetStore()
const gameStore = useGameStore()
const fleetStore = useFleetStore()
const shipDesignStore = useShipDesignStore()
const backend = new Backend()

const showButton = ref(false);

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props.planetId))
const shipDesignData = computed(() => shipDesignStore.getShipDesignById(planetData.value.productionShipDesignId))
const productionObjectData = computed(() => assetStore.findProductionObjectById(planetData.value.productionObjectId))

const widthPercentage = computed(() => {
  let progress = 0;
  if (shipDesignData.value) {
    progress = (planetData.value.productionProgress / shipDesignData.value.productionCost);
  } else {
    progress = (planetData.value.productionProgress / productionObjectData.value.productionCost)
  }
  if(progress > 1) {
    progress = 1
  }
  return progress * 100
});

const turnsLeft = computed(() =>
{
  let turns = 0;
  if (shipDesignData.value) {
     turns = (shipDesignData.value.productionCost - planetData.value.productionProgress)/planetData.value.productionPerTurn
  } else {
    turns = (productionObjectData.value.productionCost - planetData.value.productionProgress) / planetData.value.productionPerTurn
  }
  if(turns < 0) {
    turns = 0
  }
  return turns
});

const name = computed(() => {
  if (shipDesignData.value) {
    return shipDesignData.value.name
  } else {
    return productionObjectData.value.productionObjectName
  }
});

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})

const constructionCost = computed(() => {
  if (shipDesignData.value) {
    return shipDesignData.value.productionCost
  } else {
    return productionObjectData.value.productionCost
  }
});

const cancelProduction = () => {
  backend.cancelPlanetProductionObject(planetData.value.id);
  gameStore.setTinySuccessToastMessage('Production cancelled');
}

</script>

<template>
  <div v-if="planetData.productionObjectId != 0">
    <BuildingBase >
      <template #image>
        <ModalTrigger v-if="productionObjectData.type=='building'" type="building" :data="{'buildingCode': productionObjectData.objectId}">
          <ImageRounded
            type="buildings"
            source-size="128"
            :file="productionObjectData.objectId"
            :size="8"/>
        </ModalTrigger>
        <ImageRounded
          v-else
          type="buildings"
          source-size="128"
          file="ship"
          :size="8"/>
      </template>
      <template #button>
        <StandardButton
          color="red"
          @click="cancelProduction"
          :end-margin="false"
        >X</StandardButton>
      </template>
      <template #title>
        {{ name }}
      </template>
      <template #default>
        <span v-if="planetData.productionShipFleetId>0" class="pt-3">Assign to fleet <b class="text-slate-400">{{ fleetStore.getFleetNameById(planetData.productionShipFleetId) }}</b></span>
        <span v-else>{{ productionObjectData.description }}</span>
        <div class="flex-grow flex items-center justify-center pt-3">
          <div class="w-full rounded bg-gray-700">
            <div class="bg-blue-600 text-xs text-blue-100 text-center p-1 leading-none rounded whitespace-nowrap" :style="'width:' + widthPercentage + '%'">{{ Math.ceil(turnsLeft) }} <SingleMulti :value="Math.ceil(turnsLeft)" single="week" multi="weeks"/></div>
          </div>
        </div>
      </template>
      <template #effects>
        <div class="w-full flex justify-between">
          <div>
            <BuildingEffects v-if="buildingData" :building="buildingData"> </BuildingEffects>
          </div>
          <div class="flex-shrink text-subtext">
            {{ constructionCost }} <Icon type="production"></Icon>
          </div>
        </div>
      </template>
    </BuildingBase>
  </div>
  <div v-else>
    <div class="flex justify-center text-subtext p-10">
      <span v-if="gameStore.playerMinisters.has('production_converted_to_energy')">Production is converted to energy</span>
      <span v-else>Nothing is being build currently</span>
    </div>
  </div>
</template>

<style scoped>
</style>
