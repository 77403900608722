<template>
  <div
    class="p-0 m-0 relative inline-block cursor-pointer"

    @click.stop="fetchContent"
    @mouseleave="reset"
    >
    <slot></slot>
    <div
      v-if="isVisible"
      style="z-index: 100"
      class="tooltip-content fixed border border-gray-600 min-w-[250px] text-white whitespace-nowrap bg-slate-800"
      :class="{ loading: isLoading }">
      <div v-if="isLoading">Loading...</div>
      <div v-else>
        <ToolTipContent :tool-tip-data="toolTipData"></ToolTipContent>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Backend from '@/models/backend.js';
import ToolTipContent from "@/components/Interface/ToolTip/ToolTipContent.vue";

// Define props
const props = defineProps<{
  url: string;
}>();

// Reactive state
const isVisible = ref(false);
const isLoading = ref(false);
const toolTipData = ref({
  type: '',
  data: {}
});


// Methods
const fetchContent = async () => {
  if (isVisible.value) {
    return reset();
  }
  if (!props.url) {
    console.error('No URL provided for tooltip content');
    return;
  }
  isVisible.value = true;
  isLoading.value = true;
  try {
    console.log('Fetching content');
    const backend = new Backend();
    const data = await backend.loadToolTip(props.url) as never as {
      type: string,
      data: {}
  };
    toolTipData.value = data.data;
    console.log('Fetched content:', data);
  } catch (error) {
    console.error('Failed to fetch tooltip content:', error);
  } finally {
    isLoading.value = false;
  }
};

const reset = () => {
  isVisible.value = false;
  isLoading.value = false;
};
</script>


<style>
</style>
