<script setup lang="ts">

import { useGameStore } from "@/stores/gameStore.ts";
import SmallModel from '@/components/Interface/Modal/ModelSizes/SmallModel.vue'
import Backend from '@/models/backend.ts'
import { ref } from 'vue'
import { usePlanetGroupStore } from '@/stores/planetGroupStore.ts'

// Get the planet data
const gameStore = useGameStore();
const planetGroupStore = usePlanetGroupStore();
const backend = new Backend()

const selectedGroup = ref()

const submit = () => {
  backend.attachPlanetToGroup(selectedGroup.value,gameStore.modalData).then(()=>{
    gameStore.modalType=''
  })
}

</script>

<template>
  <SmallModel title="Select Group">
    <div class="p-2">
      <p class="pb-3 text-subtext">Select the group you wish to assign these {{ gameStore.modalData.length }} planet(s) to.</p>
    <select @change="submit" v-model="selectedGroup" class="w-full pointer-events-auto p-2 text-sm border rounded bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
      <template v-for="group in planetGroupStore.planetGroups.values()" :key="group.id">
        <option :value="group.id">{{ group.name }}</option>
      </template>
    </select>
    </div>
  </SmallModel>
</template>

<style scoped lang="scss">
.table-container {
  height: 30rem;
  overflow-y: auto;
}
</style>
