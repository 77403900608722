<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore'
import ModelBuilding from '@/components/Interface/Modal/Models/ModelBuilding.vue'
import ModelEditPlanetName from '@/components/Interface/Modal/Models/ModelEditPlanetName.vue'
import ModelAbandonPlanet from "@/components/Interface/Modal/Models/ModelAbandonPlanet.vue";
import ModelCreateShipDesign from "@/components/Interface/Modal/Models/ModelCreateShipDesign.vue";
import ModelBuildShip from "@/components/Interface/Modal/Models/ModelBuildShip.vue";
import ModelMapSelectPlayerShips from "@/components/Interface/Modal/Models/ModelMapSelectPlayerShips.vue";
import ModelEditShipName from '@/components/Interface/Modal/Models/ModelEditShipName.vue'
import ModelMapAttack from "@/components/Interface/Modal/Models/ModelMapAttack.vue";
import ModelBattleReport from "@/components/Interface/Modal/Models/ModelBattleReport.vue";
import ModelRequestAudience from '@/components/Interface/Modal/Models/ModelRequestAudience.vue'
import ModelShipComponent from '@/components/Interface/Modal/Models/ModelShipComponent.vue'
import ModelTech from '@/components/Interface/Modal/Models/ModelTech.vue'
import ModelPQA from '@/components/Interface/Modal/Models/ModelPQA.vue'
import ModelAssignToFleet from '@/components/Interface/Modal/Models/ModelAssignToFleet.vue'
import ModelCreateFleet from '@/components/Interface/Modal/Models/ModelCreateFleet.vue'
import ModelEditFleetName from '@/components/Interface/Modal/Models/ModelEditFleetName.vue'
import ModelPlanetsSetProductionQueue from '@/components/Interface/Modal/Models/ModelPlanetsSetProductionQueue.vue'
import ModelPlanetsSetPopulationAssignments
  from '@/components/Interface/Modal/Models/ModelPlanetsSetPopulationAssignments.vue'
import ModelMapFollow from '@/components/Interface/Modal/Models/ModelMapFollow.vue'
import ModelMapFollowAttack from '@/components/Interface/Modal/Models/ModelMapFollowAttack.vue'
import ModelCreateShipGroup from '@/components/Interface/Modal/Models/ModelCreateShipGroup.vue'
import ModelMinistryCandidate from '@/components/Interface/Modal/Models/ModelMinistryCandidate.vue'
import ModelMapSelectPlayerFleetShips from '@/components/Interface/Modal/Models/ModelMapSelectPlayerFleetShips.vue'
import ModelDelete from '@/components/Interface/Modal/Models/Confirmation/ModelDelete.vue'
import ModelBuildBuilding from '@/components/Interface/Modal/Models/ModelBuildBuilding.vue'
import ModelCreatePlanetGroup from '@/components/Interface/Modal/Models/ModelCreatePlanetGroup.vue'
import ModelAttachToShipGroup from '@/components/Interface/Modal/Models/ModelAttachToShipGroup.vue'
import ModelAttachToPlanetGroup from '@/components/Interface/Modal/Models/ModelAttachToPlanetGroup.vue'

const gameStore = useGameStore()

</script>

<template>
  <!-- Level 1 Modals -->
  <div v-if="gameStore.modalType === 'building'">
    <ModelBuilding/>
  </div>
  <div v-else-if="gameStore.modalType === 'editPlanetName'">
    <ModelEditPlanetName/>
  </div>
  <div v-else-if="gameStore.modalType === 'abandonPlanet'">
    <ModelAbandonPlanet/>
  </div>
  <div v-else-if="gameStore.modalType === 'createShipDesign'">
    <ModelCreateShipDesign/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetBuildShip'">
    <ModelBuildShip/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetBuildBuilding'">
    <ModelBuildBuilding/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapSelectPlayerShip'">
    <ModelMapSelectPlayerShips/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapSelectPlayerFleetShips'">
    <ModelMapSelectPlayerFleetShips/>
  </div>
  <div v-else-if="gameStore.modalType === 'editShipName'">
    <ModelEditShipName/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapAttack'">
    <ModelMapAttack/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapFollow'">
    <ModelMapFollow/>
  </div>
  <div v-else-if="gameStore.modalType === 'mapFollowAttack'">
    <ModelMapFollowAttack/>
  </div>
  <div v-else-if="gameStore.modalType === 'battleReport'">
    <ModelBattleReport/>
  </div>
  <div v-else-if="gameStore.modalType === 'requestAudience'">
    <ModelRequestAudience/>
  </div>
  <div v-else-if="gameStore.modalType === 'shipComponent'">
    <ModelShipComponent/>
  </div>
  <div v-else-if="gameStore.modalType === 'tech'">
    <ModelTech/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetProductionAutomation'">
    <ModelPQA/>
  </div>
  <div v-else-if="gameStore.modalType === 'assignToFleet'">
    <ModelAssignToFleet/>
  </div>
  <div v-else-if="gameStore.modalType === 'createFleet'">
    <ModelCreateFleet/>
  </div>
  <div v-else-if="gameStore.modalType === 'editFleetName'">
    <ModelEditFleetName/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetsSetProductionQueue'">
    <ModelPlanetsSetProductionQueue/>
  </div>
  <div v-else-if="gameStore.modalType === 'planetsSetPopulationAssignments'">
    <ModelPlanetsSetPopulationAssignments/>
  </div>
  <div v-else-if="gameStore.modalType === 'createShipGroup'">
    <ModelCreateShipGroup/>
  </div>
  <div v-else-if="gameStore.modalType === 'createPlanetGroup'">
    <ModelCreatePlanetGroup/>
  </div>
  <div v-else-if="gameStore.modalType === 'attachToShipGroup'">
    <ModelAttachToShipGroup/>
  </div>
  <div v-else-if="gameStore.modalType === 'attachToPlanetGroup'">
    <ModelAttachToPlanetGroup/>
  </div>
  <div v-else-if="gameStore.modalType === 'ministryCandidate'">
    <ModelMinistryCandidate/>
  </div>
  <!-- Level 2 Modals -->
  <div v-if="gameStore.modal2Type === 'confirmationDelete'">
    <ModelDelete/>
  </div>



</template>

<style scoped lang="scss">

</style>