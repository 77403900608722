import * as THREE from 'three'
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import { useShipStore } from '@/stores/shipStore.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import { MapETA } from '@/models/Map/MapETA.ts'

export class ShipActionPath {

    scene: THREE.Scene;
    pendingActionLine: THREE.Line | null;
    actionLines: THREE.Line[] = [];
    shipActionStore: any;
    shipStore: any;
    mapStore: any;

    constructor(scene: THREE.Scene) {
        this.scene = scene;
        this.pendingActionLine = null;
        this.shipActionStore = useShipActionStore();
        this.shipStore = useShipStore();
        this.mapStore = useMapStore()
    }

    setPendingAction(x: number, y: number) {
        console.log('setPendingAction', x, y, this.shipActionStore.pendingAction);
        if (this.pendingActionLine !== null || (this.shipActionStore.pathEndX === 0 && this.shipActionStore.pathEndY === 0)) {
            return;
        }

        // Create the line
        const material = new THREE.LineBasicMaterial({ color: 0x0000ff });
        const points = [];
        const startX = this.shipActionStore.pathEndX;
        const startY = this.shipActionStore.pathEndY;
        points.push(new THREE.Vector3(startX, startY, 0.2));
        points.push(new THREE.Vector3(x, y, 0.2));
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        this.pendingActionLine = new THREE.Line(geometry, material);
        this.pendingActionLine.name = 'actionLine';
        this.scene.add(this.pendingActionLine);

        new MapETA(this.pendingActionLine);
    }


    removePendingAction() {
        console.log('removePendingAction');
        if (this.pendingActionLine) {
            this.scene.remove(this.pendingActionLine);
            this.pendingActionLine = null;
        }
    }
    queuePendingAction(x: number, y: number, targetPlayer: number, targetShip: number) {
        // add the action to the queue
        this.shipActionStore.queuePendingAction(x, y, targetPlayer, targetShip);
        this.removePendingAction();
        this.setActionPath();
    }
    removeLastAction() {
        if (this.shipActionStore.actions.length > 0) {
            this.shipActionStore.actions.pop();
            this.recalculatePathEnd()
            this.setActionPath();
        }
    }
    recalculatePathEnd(){
        this.shipActionStore.pathEndX = this.shipActionStore.pathStartX;
        this.shipActionStore.pathEndY = this.shipActionStore.pathStartY;
        this.shipActionStore.actions.forEach((action: any) => {
            this.shipActionStore.pathEndX = action.targetX;
            this.shipActionStore.pathEndY = action.targetY;
        });
    }
    resetActionPath() {
        this.shipActionStore.actions = [];
        this.shipActionStore.pathEndX = this.shipActionStore.pathStartX;
        this.shipActionStore.pathEndY = this.shipActionStore.pathStartY;
        this.setActionPath();
    }
    setActionPath(): void {
        console.log('setActionPath')
        // remove all action lines
        this.actionLines.forEach((line) => {
            this.scene.remove(line);
        });
        this.actionLines = [];

        let startX = this.shipActionStore.pathStartX;
        let startY = this.shipActionStore.pathStartY;
        // add all action lines
        this.shipActionStore.actions.forEach((action: any) => {
            // when there is no first point to draw a line from, we just skip it, otherwise it will draw it from the left-bottom corner.
            if(startX !== 0 && startY !== 0 && action.targetX !== 0 && action.targetY !== 0) {
                const material = new THREE.LineBasicMaterial({ color: 0x0000ff });
                const points = [];
                points.push(new THREE.Vector3(startX, startY, 0.2));
                points.push(new THREE.Vector3(action.targetX, action.targetY, 0.2));
                const geometry = new THREE.BufferGeometry().setFromPoints(points);
                const line = new THREE.Line(geometry, material);
                line.name = 'actionLine';
                this.actionLines.push(line);
                this.scene.add(line);

                new MapETA(line);
            }
            startX = action.targetX;
            startY = action.targetY

        });

    }
}