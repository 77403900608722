import type { AxiosError } from 'axios'
import { useGameStore } from '@/stores/gameStore.ts'
import * as yup from 'yup'

export default class FormProcess {

  private gameStore = useGameStore()

  constructor() {

  }

  /**
   * Process the backend success response
   */
  public processBackendSuccessResponse(): void {
    this.gameStore.modalType = '';
  }

  /**
   * Process the backend error response
   *
   * @param error
   * @param formErrors
   */
  public processBackendErrorResponse(error: any, formErrors: { [key: string]: string } ): void {
    if (error.response && error.response.data.code === 'invalid_input') {
      const errors = error.response.data.data as Array<{ field: string, message: string }>;
      errors.forEach((error) => {
        console.error('Form ' + error.field + ' : ' + error.message);
        formErrors[error.field] = error.message;
      });
    } else if (error.response && error.response.data.code === 'internal_server_error') {
      formErrors.generic = 'Server Error, try again later.';
    } else {
      // All other errors
      formErrors.generic = error.response.data.message;
    }
  }

  /**
   * Process the frontend error response
   *
   * @param error
   * @param formErrors
   */
  public processFrontendErrorResponse(error: any, formErrors: { [key: string]: string } ): void {
    if (error instanceof yup.ValidationError) {
      // Initialize an errors object
      const errors: { [key: string]: string } = {};

      // Check if there are multiple errors
      if (error.inner && error.inner.length > 0) {
        // If the error was not an immediate abort, collect all errors
        error.inner.forEach(err => {
          const path = err.path || 'unknown';  // Provide a default key if path is undefined
          if (!errors[path]) {
            formErrors[path] = err.message;
          }
        });
      } else {
        // If there was a direct throw, it's on the `path` and `message`
        if (error.path) {
          formErrors[error.path] = error.message;
        }
      }

      console.log('Validation errors:', errors);
    } else {
      // Handle unexpected errors
      console.error('An unexpected error occurred:', error);
    }
  }

  public resetFormErrors(formErrors: { [key: string]: string }): void {
    for (const key in formErrors) {
      formErrors[key] = '';
    }
  }

}