<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'

const playerRelationStore = usePlayerRelationStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="props.newsItem.contentData.profileImage"
    :toast-text="'Friend request'"
  >
    <ModalTrigger type="requestAudience" :data="{'relation': playerRelationStore.findRelationByPlayerId( props.newsItem.contentData.playerId)}" >
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="props.newsItem.contentData.profileImage"
        :header="'Friend request'"
      >
        {{ props.newsItem.contentData.nickname }} wants to be friends with you.
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>