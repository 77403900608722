<script setup lang="ts">
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import {usePlayerRelationStore} from "@/stores/playerRelations.ts";
import PlayerName from "@/components/Objects/PlayerName.vue";
import {useShipActionStore} from "@/stores/shipActionStore.ts";
import { onMounted, ref } from 'vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'

// Get the planet data
const gameStore = useGameStore()
const playerRelation = usePlayerRelationStore()
const shipActionPath = useShipActionStore()
const mapCoordinateStore = useMapCoordinateStore()

//List of players that are there and are enemies
const players = ref(new Map<number, number>());

const follow = (targetPlayer: number) => {
  /**
   * Queue the attack action
   */
  shipActionPath.queuePendingAction(
      gameStore.modalData.xPos,
      gameStore.modalData.yPos,
      targetPlayer,
      0
  );
  // Close the modal
  gameStore.modalType = '';
}

const loadMapCoordinate = async () => {
  try {

    const data = mapCoordinateStore.getByCoordinates(gameStore.modalData.xPos, gameStore.modalData.yPos);

    //check if the ships are from an enemy
    for (const playerId of Object.keys(data.mapCoordinate.ships)) {
      const numericPlayerId = Number(playerId);
      if (!players.value.has(numericPlayerId) && numericPlayerId > 0) {
        players.value.set(
          numericPlayerId,
          numericPlayerId
        );
      }
    }

  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  loadMapCoordinate()
});


</script>

<template>
  <SmallModel title="Which player do you wish to follow?">
    <div class="p-1 grid grid-cols-3 gap-2">
      <div v-for="playerId in players.values()" :key="playerId">
        <div
          @click="follow(playerId)"
          class="p-2 m-2 cursor-pointer rounded border border-slate-500 hover:bg-slate-600">
          <ImageRoundedUrl v-if="playerId != gameStore.player?.id"
            :url="playerRelation.getProfileImageByPlayerId(playerId)"
          />
          <ImageRoundedUrl v-else
                           :url="gameStore.player?.profileImage"
          />
          <div class="text-center pt-2">
            <div>
              <PlayerName :player-id="playerId"></PlayerName>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="players.size === 0">
      <div class="text-subtext p-5 text-center">Nobody to follow here</div>
    </div>
    <div v-else>
      <div class="text-subtext p-5 text-center">Your ship(s) will target one ship of the selected player to follow.</div>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>