<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '0'
  },
  name: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    default: '20',
  }
});

const emit = defineEmits(['update:modelValue']);

const updateValue = (value) => {
  emit('update:modelValue', value);
};

</script>

<template>
  <div class="flex">
    <div v-if="name!=''" class="bg-slate-800 flex items-center px-2">{{ name }}</div>
    <div class="bg-slate-800 px-1 flex items-center">
      <select
        :value="modelValue"
        @input="updateValue($event.target.value)"
        :style="'max-width: ' + props.width + 'rem;'">
        <slot></slot>
      </select>
    </div>
  </div>
</template>

<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>