import { defineStore } from 'pinia';
import type PlayerRelation from "@/types/PlayerRelation.ts";
import emitter from '@/models/eventBus.ts';

export const usePlayerRelationStore = defineStore('playerRelationsStore', {
    state: () => ({
        relations: new Map<number, PlayerRelation>()
    }),
    getters: {
        findRelationByPlayerId: (state) => (playerId: number): PlayerRelation => {
            const relation = state.relations.get(playerId);
            if (relation) {
                return relation;
            } else {
                // Return a default value matching the PlayerRelation type
                return {
                    relation: "unknown",
                    name: "Unknown",
                    playerId: 0,
                    iRequested: 'none',
                    otherRequested: 'none',
                    profileImage: "",
                    napExpireTurn: 0,
                    country: "",
                    stopNap: "continue",
                };
            }
        },
        countByRelation: (state) => (relation: string) => {
            return Array.from(state.relations.values()).filter((value) => value.relation === relation).length;
        },
        allRelationsArray: (state) => {
            return Array.from(state.relations.entries());
        },
        allEnemyRelationsArray: (state) => {
            return Array.from(state.relations.entries()).filter(([key, value]) => value.relation === "enemy");
        },
        getAllRelationsByType: (state) => (type: string) => {
            return Array.from(state.relations.entries()).filter(([key, value]) => value.relation === type);
        },
        getAllRelationsWithPendingActions: (state) => () => {
            const counts = new Map<string, number>();

            Array.from(state.relations.values()).forEach((relation) => {
                if (relation.otherRequested !== 'none') {
                    if (!counts.has(relation.relation)) {
                        counts.set(relation.relation, 0);
                    }
                    counts.set(relation.relation, counts.get(relation.relation)! + 1);
                }
            });

            return counts;
        },
        getCountOfPendingActions: (state) => () => {
            let counts = 0;

            Array.from(state.relations.values()).forEach((relation) => {
                if (relation.otherRequested !== 'none') {
                    counts++;
                }
            });
            return counts;
        }
    },
    actions: {
        update(playerId: number, data: PlayerRelation) {
            this.relations.set(playerId, data);
            emitter.emit('mapRelationUpdate', data);
        },
        isEnemy(playerId: number):boolean {
            if(this.relations.has(playerId)) {
                const relation = this.relations.get(playerId);
                if (relation) {
                    return relation.relation === 'enemy';
                }
                return false;
            } else {
                return false;
            }
        },
        getProfileImageByPlayerId(playerId: number):string {
            if(this.relations.has(playerId)) {
                const relation = this.relations.get(playerId);
                if (relation) {
                    return relation.profileImage;
                }
                return '';
            } else {
                return '';
            }
        }
    }
});
