import { defineStore } from 'pinia';
import type StatKey from '@/types/StatKey.ts';
import type { ChartData } from 'chart.js';
import Backend from '@/models/backend.ts' // Assuming you're using Chart.js types

// Define the structure of ChartJsData (Chart.js data structure)
interface ChartJsData extends ChartData<'line'> {
    // You can extend this if needed
}

export const useStatsStore = defineStore('statsStore', {
    state: () => ({
        statsKeys: new Map<number, StatKey>(), // Map with StatKey types
        selectedKey: 'population', // Initially empty
        stats: new Map<string, ChartJsData>(), // Map to store Chart.js data for each key
    }),
    getters: {
        // Add any getters if needed
    },
    actions: {
        async setSelectedKey(key: string) {
            this.selectedKey = key;
            await this.loadStatsForKey(key);
        },
        async loadStatsForKey(key: string) {

            const backend = new Backend();
            // Fetch the real data from your backend service
            const backendData = await backend.getStatsDataForKey(key);
            // Convert the object to arrays of labels (keys) and data (values)
            const labels = Object.keys(backendData); // Extract keys as labels
            const data = Object.values(backendData); // Extract values as data points

            console.log('stats3',labels, data);
            if (labels && data) {
                // Assuming the backend returns labels and data as separate arrays
                const dataset = {
                    label: `Stats for ${key}`,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    data: data, // Use the actual data from backend
                    fill: true,
                    tension: 0.3,
                    pointRadius: 0,
                };

                // Construct the Chart.js data structure
                const chartData: ChartJsData = {
                    labels: labels, // Use the actual labels from backend
                    datasets: [dataset],
                };

                // Save the data into the stats map
                this.stats.set(key, chartData);
            } else {
                console.error(`Failed to load data for key: ${key}`);
            }
        },
    },
});
