<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'
import Ministry from '@/components/Sections/Government/Cabinet/Ministry.vue'


const gameStore = useGameStore()

</script>

<template>
  <div id="main-content" class="content-height-limit-tab overflow-y-scroll">
    <div class="flex justify-center pt-10">
      <div :id="'source'" class="sm:w-2/12 w-10/12 bg-slate-800 border border-slate-700">
        <div class="p-1 text-center">Leader</div>
        <img :src="gameStore.player?.profileImage" alt="Leader">
        <div class="p-1 text-center">{{ gameStore.player?.nickName }}</div>
      </div>
    </div>
    <div class="text-center pt-16 flex justify-center">
      <div class="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-16 xl:w-4/6 w-full xl:py-0 px-10">
        <Ministry code="ministry_of_industry"></Ministry>
        <Ministry code="ministry_of_cartography"></Ministry>
        <Ministry code="ministry_of_colonization"></Ministry>
        <Ministry code="ministry_of_exploration"></Ministry>
      </div>
    </div>

    <div class="text-center pt-16 flex justify-center">
      <div class="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-16 xl:w-4/6 w-full xl:py-0 px-10">
        <Ministry code="placeholder"></Ministry>
        <Ministry code="ministry_of_conquest"></Ministry>
        <Ministry code="ministry_of_maintenance"></Ministry>
        <Ministry code="placeholder"></Ministry>
      </div>
    </div>

    <div class="text-center pt-16 flex justify-center">
      <div class="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-16 xl:w-4/6 w-full xl:py-0 px-10">
        <Ministry code="placeholder"></Ministry>
        <Ministry code="placeholder"></Ministry>
        <Ministry code="placeholder"></Ministry>
        <Ministry code="placeholder"></Ministry>
      </div>
    </div>

    <div class="text-center pt-16 flex justify-center">
      <div class="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-16 xl:w-4/6 w-full xl:py-0 px-10">
        <div></div>
        <Ministry code="placeholder"></Ministry>
        <Ministry code="placeholder"></Ministry>
        <div></div>
      </div>
    </div>

    <div class="pb-32"></div>
  </div>
</template>

<style scoped lang="scss">
.parent {
  position: relative;
  width: 100%;
  overflow: auto;
  //scrollbar-width: none; /* Firefox */
  //-ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.parent::-webkit-scrollbar {
  //display: none; /* Safari and Chrome */
}
</style>
