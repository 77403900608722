import { useShipStore } from '@/stores/shipStore.ts'
import * as THREE from "three";
import { MapETA } from '@/models/Map/MapETA.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import type PlayerShip from '@/types/PlayerShip.ts'

export class MapShipActionPaths {

    scene: THREE.Scene;
    actionLine: THREE.Line | null;
    shipStore: any;
    mapStore: any;
    xPos: number;
    yPos: number;

    constructor(scene: THREE.Scene) {
        this.scene = scene;
        this.actionLine = null;
        this.shipStore = useShipStore();
        this.mapStore = useMapStore();
        this.xPos = 0;
        this.yPos = 0;
    }

    create(xPos: number, yPos: number) {
        this.xPos = xPos;
        this.yPos = yPos;
        const path = this.getFirstPathFromShips();
        if(path.length === 0) {
            return;
        }
        this.drawPath(path);
    }

    deleteLines() {
        if (this.actionLine) {
            this.scene.remove(this.actionLine);
            this.actionLine.geometry.dispose();
            (this.actionLine.material as THREE.Material).dispose();
            this.actionLine = null;
        }
    }

    drawPath(path: any) {
        // Remove existing line before drawing a new one
        this.deleteLines();

        if (!path || path.length === 0) {
            return; // No path to draw
        }

        const material = new THREE.LineBasicMaterial({ color: 0x800080 });
        const points = [];
        points.push(new THREE.Vector3(this.xPos, this.yPos, 0.2));
        path.forEach((action: any) => {
            points.push(new THREE.Vector3(action.targetX, action.targetY, 0.2));
        });
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        this.actionLine = new THREE.Line(geometry, material);
        this.scene.add(this.actionLine);

        new MapETA(this.actionLine);
    }

    getFirstPathFromShips(): any {
        // Get the first ship at the current location and return its actions
        const ship = Array.from(this.shipStore.ships.values()).find(
          (ship: any) => ship.xPos == this.xPos && ship.yPos == this.yPos
        ) as PlayerShip;


        // If a ship exists at the location, return its actions
        if (ship) {
            this.mapStore.etaSpeed = ship.maxSpeed;
            return ship.actions;
        }

        return []; // Return an empty array if no ship is found
    }
}
