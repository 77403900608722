<script setup lang="ts">

import { computed } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { usePlanetStore } from '@/stores/planetStore.ts'

const props = defineProps({
  shipDesignId: Number
});

const planetStore = usePlanetStore();

const number = computed(() => {
  return planetStore.getNumberOfShipsInProduction(props.shipDesignId)
})

</script>

<template>
  <span v-if="number>0" class="text-yellow-500">{{ number }}</span>
</template>

<style scoped lang="scss">

</style>