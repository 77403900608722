<script setup lang="ts">

</script>

<template>
  <div class="chat bg-slate-950 p-2">
    <ul class="flex flex-wrap text-sm font-medium text-center border-gray-700 text-gray-400">
      <li class="me-2">
        <a href="#" aria-current="page" class="inline-block p-2 rounded-t-lg active bg-gray-800 text-slate-500">General</a>
      </li>
      <li class="me-2">
        <a href="#" class="inline-block p-2 rounded-t-lg hover:bg-gray-800 hover:text-gray-300">Alliance</a>
      </li>
      <li class="me-2">
        <a href="#" class="inline-block p-2 rounded-t-lg hover:bg-gray-800 hover:text-gray-300">Region</a>
      </li>
    </ul>

    <div class="bg-slate-800 rounded-b text-white p-2">Chat</div>
  </div>
</template>

<style scoped>
.chat {
  height: var(--chat-height);
  color:white;
  display: var(--chat-display);
}
</style>