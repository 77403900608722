
<script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { useGameStore } from '@/stores/gameStore.ts'
  import { useFleetStore } from '@/stores/fleetStore.ts'
  import { useShipActionStore } from '@/stores/shipActionStore.ts'
  import { useMapStore } from '@/stores/mapStore.ts'

  const props = defineProps({
    fleetIds: Array
  });

  const gameStore = useGameStore();
  const fleetStore = useFleetStore();
  const shipActionStore = useShipActionStore();
  const mapStore = useMapStore();

  const menuRef = ref(null);

  function toggleMenu() {
    if(gameStore.pullDownMenuVisible === 'mapFleetMenu') {
      closeMenu()
    } else {
      gameStore.pullDownMenuVisible = 'mapFleetMenu'
    }
  }

  function closeMenu() {
    gameStore.pullDownMenuVisible = ''
  }

  const selectFleet = (fleetId) => {
      const fleet = fleetStore.findFleetById(fleetId);
      shipActionStore.initFleet(fleet);
      mapStore.disableAreaSelection();
      closeMenu();
  }

  function handleClickOutside(event) {
    if (!menuRef.value.contains(event.target)) {
      closeMenu();
    }
  }

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
  });
</script>

<template>
  <div class="relative" @click.stop="toggleMenu">
    <slot></slot>
    <!-- Dropdown Menu -->
    <div v-show="gameStore.pullDownMenuVisible === 'mapFleetMenu'" ref="menuRef" class="absolute w-48 border border-slate-500 bg-slate-800 rounded z-50 mt-1">
      <template v-for="fleetId in fleetIds" :key="fleetId">
        <span
          class="block p-1 text-sm hover:bg-slate-500"
          @click="selectFleet(fleetId)"
        >
         {{ fleetStore.getFleetNameById(fleetId) }}
        </span>
      </template>

    </div>
  </div>
</template>

<style scoped>
/* Add any specific styles for your dropdown here */
</style>
