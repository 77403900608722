<script setup lang="ts">

import { useMapStore } from '@/stores/mapStore.ts'
import MapSideBarPlanet from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/MapSideBarPlanet.vue'
import MapSideBarFleets from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/MapSideBarFleets.vue'

const mapStore = useMapStore();

</script>

<template>
  <template v-if="mapStore.mapSideBarTab=='planets'">
    <MapSideBarPlanet></MapSideBarPlanet>
  </template>
  <template v-else-if="mapStore.mapSideBarTab=='fleets'">
    <MapSideBarFleets></MapSideBarFleets>
  </template>
</template>

<style scoped lang="scss">

</style>