<script setup lang="ts">

import {computed, defineComponent, defineProps, type Ref, ref, watch} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import {useAssetStore} from "@/stores/assetStore";
import type ProductionObject from "@/types/ProductionObject";
import PlanetProductionQueueObject
  from "@/components/Sections/Planets/PlanetTabs/ProductionTab/Objects/PlanetProductionQueueObject.vue";
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'

/**
 * Available planet production objects tab component
 */
defineComponent({
  name: 'PlanetProductionTabQueue',
});

const props = defineProps({
  planetId: Number,
});

const planetStore = usePlanetStore();
const assetStore = useAssetStore();

const planetData = computed(() => planetStore.findPlanetById(props?.planetId));

/**
 * Dynamically compute the queued production objects based on planet data
 **/
const queuedProductionObjects = computed(() => {
  const productionQueue = planetData.value.productionQueue;

  if (!productionQueue) {
    return [];
  }

  // Generate the list of production objects
  return productionQueue.map((queueItem) => ({
    object: assetStore.findProductionObjectById(queueItem['productionObjectId']),
    queueId: queueItem['id'],
    productionShipDesignId: queueItem['productionShipDesignId'],
    productionShipFleetId: queueItem['productionShipFleetId'],
  }));
});



</script>

<template>
  <div v-for="productionObject in queuedProductionObjects" :key="productionObject.object.id" class="pt-1">
    <PlanetProductionQueueObject
        :planet-id="planetData.id"
        :queue-id="productionObject.queueId"
        :production-object-id="productionObject.object.id"
        :production-ship-design-id="productionObject.productionShipDesignId"
        :production-ship-fleet-id="productionObject.productionShipFleetId"
    >
    </PlanetProductionQueueObject>
  </div>
  <div v-if="planetData.planetQueueAutomationId > 0">
    <ModalTrigger type="planetProductionAutomation" :data="{'PQAId': planetData.planetQueueAutomationId}">
      <div
        class="flex justify-center text-subtext p-10 bg-slate-800 mt-1 border border-slate-800 hover:border-slate-700"

      >Production Queue Automation</div>
    </ModalTrigger>
  </div>
  <div v-if="queuedProductionObjects.length === 0 && planetData.planetQueueAutomationId === 0">
    <div class="flex justify-center text-subtext p-10">Queue is empty</div>
  </div>
</template>

<style scoped lang="scss">
</style>
