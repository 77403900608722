<script setup lang="ts">

import {useAssetStore} from "@/stores/assetStore.ts";
import {useShipDesignCreationStore} from "@/stores/shipDesignCreationStore.ts";
import ShipDesignComponent from "@/components/Interface/Modal/Models/CreateShipDesign/ShipDesignComponent.vue";

const assetStore = useAssetStore()
const shipDesignStore = useShipDesignCreationStore()

const props = defineProps({
  componentCode: String,
  index: Number
});

const component = assetStore.findShipComponentByCode(props.componentCode)

</script>

<template>
  <ShipDesignComponent :component="component">
    <button
        @click="shipDesignStore.removeComponent(props.index)"
        class="bg-red-500 hover:bg-red-700 text-white py-1 px-3 rounded mt-1">
      <span v-if="component.group === 'frame'">Reset Design</span>
      <span v-else>Remove</span>
    </button>
  </ShipDesignComponent>
</template>

<style scoped lang="scss">

</style>