import type MapCoordinate from '@/types/MapCoordinate.ts'
import { zoomFactor } from '@/enums/zoomFactor.ts'

export const updateCoordinateShips = (
  mapCoordinate: MapCoordinate,
  totalPositions: number,
  position: number,
  zoom: number
) => {

    let scale = 0
    let positionType = 'space'

    if(mapCoordinate.type === 0) {
        //Space
        positionType = 'space'
    } else if(mapCoordinate.type === 1) {
        //Planet
        positionType = 'planet'
    } else if(mapCoordinate.type === 2) {
        //Star
        positionType = 'planet'
    }

    if(zoom == zoomFactor.Planet) {
        scale = 1;
    } else if(zoom == zoomFactor.Solar) {
        scale = 2.5;
    } else if(zoom == zoomFactor.Local) {
        scale = 8;
    } else if(zoom == zoomFactor.Sector) {
        scale = 10
    } else if(zoom == zoomFactor.Quadrant) {
        scale = 0;
    } else if(zoom == zoomFactor.Max) {
        scale = 0;
    }

    let coords = {x:mapCoordinate.xPos, y:mapCoordinate.yPos}
    if(positionType === 'planet') {
        if(zoom === zoomFactor.Planet) {
            coords = positionPlanetZoomPlanet(mapCoordinate.xPos, mapCoordinate.yPos, totalPositions, position)
        } else if(zoom === zoomFactor.Solar) {
            coords = positionPlanetZoomSolar(mapCoordinate.xPos, mapCoordinate.yPos, totalPositions, position)
        } else if(zoom === zoomFactor.Local) {
            coords = positionPlanetZoomLocal(mapCoordinate.xPos, mapCoordinate.yPos, totalPositions, position)
        }
    } else if(positionType === 'space') {
        if(zoom === zoomFactor.Planet) {
            coords = positionSpacePlanet(mapCoordinate.xPos, mapCoordinate.yPos, totalPositions, position, zoomFactor)
        } else if(zoom === zoomFactor.Solar) {
            coords = positionSpaceSolar(mapCoordinate.xPos, mapCoordinate.yPos, totalPositions, position, zoomFactor)
        } else {
            coords = positionSpaceSolar(mapCoordinate.xPos, mapCoordinate.yPos, totalPositions, position, zoomFactor)
        }
    }

    return {
        x: coords.x,
        y: coords.y,
        scale: scale
    }
}

const positionPlanetZoomPlanet = (x: number, y: number, totalPositions: number, position: number): {x:number, y:number} => {

    const middlePos = {
        x: x,
        y: y - 1.5
    }
    const leftPos = {
        x: x - 0.3,
        y: y - 1.5
    }
    const rightPos = {
        x: x + 0.3,
        y: y - 1.5
    }
    const farLeftPos = {
        x: x - 0.6,
        y: y - 1.5
    }
    const farRightPos = {
        x: x + 0.6,
        y: y - 1.5
    }

    if(totalPositions === 1) {
        return middlePos
    } else if(totalPositions === 2) {
        if(position === 1) {
            return leftPos
        } else if(position === 2) {
            return rightPos
        }
    } else if(totalPositions === 3) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return middlePos
        } else if(position === 3) {
            return farRightPos
        }
    } else if(totalPositions === 4) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return leftPos
        } else if(position === 3) {
            return rightPos
        } else if(position === 4) {
            return farRightPos
        }
    } else if(totalPositions === 5) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return leftPos
        } else if(position === 3) {
            return middlePos
        } else if(position === 4) {
            return rightPos
        } else if(position === 5) {
            return farRightPos
        }
    }
}

const positionPlanetZoomSolar = (x: number, y: number, totalPositions: number, position: number): {x:number, y:number} => {

    const middlePos = {
        x: x,
        y: y - 1.5
    }
    const leftPos = {
        x: x - 0.3,
        y: y - 1.5
    }
    const rightPos = {
        x: x + 0.3,
        y: y - 1.5
    }
    const farLeftPos = {
        x: x - 0.6,
        y: y - 1.5
    }
    const farRightPos = {
        x: x + 0.6,
        y: y - 1.5
    }

    if(totalPositions === 1) {
        return middlePos
    } else if(totalPositions === 2) {
        if(position === 1) {
            return leftPos
        } else if(position === 2) {
            return rightPos
        }
    } else if(totalPositions === 3) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return middlePos
        } else if(position === 3) {
            return farRightPos
        }
    } else if(totalPositions === 4) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return leftPos
        } else if(position === 3) {
            return rightPos
        } else if(position === 4) {
            return farRightPos
        }
    } else if(totalPositions === 5) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return leftPos
        } else if(position === 3) {
            return middlePos
        } else if(position === 4) {
            return rightPos
        } else if(position === 5) {
            return farRightPos
        }
    }
}

const positionPlanetZoomLocal = (x: number, y: number, totalPositions: number, position: number): {x:number, y:number} => {

    const middlePos = {
        x: x - 0.5,
        y: y - 4
    }
    const leftPos = {
        x: x - 1,
        y: y - 4
    }
    const rightPos = {
        x: x + 1,
        y: y - 4
    }
    const farLeftPos = {
        x: x - 2,
        y: y - 4
    }
    const farRightPos = {
        x: x + 2,
        y: y - 4
    }

    if(totalPositions === 1) {
        return middlePos
    } else if(totalPositions === 2) {
        if(position === 1) {
            return leftPos
        } else if(position === 2) {
            return rightPos
        }
    } else if(totalPositions === 3) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return middlePos
        } else if(position === 3) {
            return farRightPos
        }
    } else if(totalPositions === 4) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return leftPos
        } else if(position === 3) {
            return rightPos
        } else if(position === 4) {
            return farRightPos
        }
    } else if(totalPositions === 5) {
        if(position === 1) {
            return farLeftPos
        } else if(position === 2) {
            return leftPos
        } else if(position === 3) {
            return middlePos
        } else if(position === 4) {
            return rightPos
        } else if(position === 5) {
            return farRightPos
        }
    }
}

const positionSpacePlanet = (x: number, y: number, totalPositions: number, position: number): {x:number, y:number} => {
    const firstPos = {
        x: x,
        y: y
    }
    const secondPos = {
        x: x - 0.25,
        y: y - 0.25
    }
    const thirdPos = {
        x: x + 0.25,
        y: y - 0.25
    }
    const fourthPos = {
        x: x - 0.25,
        y: y + 0.25
    }
    const fifthPos = {
        x: x + 0.25,
        y: y + 0.25
    }
    if (totalPositions === 1) {
        return firstPos
    }
    else if (totalPositions === 2) {
        if (position === 1) {
            return secondPos
        } else if (position === 2) {
            return fourthPos
        }
    }
    else if (totalPositions === 3) {
        if (position === 1) {
            return secondPos
        } else if (position === 2) {
            return thirdPos
        } else if (position === 3) {
            return fourthPos
        }
    }
    else if (totalPositions === 4) {
        if (position === 1) {
            return secondPos
        } else if (position === 2) {
            return thirdPos
        } else if (position === 3) {
            return fourthPos
        } else if (position === 4) {
            return fifthPos
        }
    }
    else if (totalPositions === 5) {
        if (position === 1) {
            return firstPos
        } else if (position === 2) {
            return secondPos
        } else if (position === 3) {
            return thirdPos
        } else if (position === 4) {
            return fourthPos
        } else if (position === 5) {
            return fifthPos
        }
    }
}

const positionSpaceSolar = (x: number, y: number, totalPositions: number, position: number): {x:number, y:number} => {
    const firstPos = {
        x: x,
        y: y
    }
    const secondPos = {
        x: x - 0.5,
        y: y - 0.5
    }
    const thirdPos = {
        x: x + 0.5,
        y: y - 0.5
    }
    const fourthPos = {
        x: x - 0.5,
        y: y + 0.5
    }
    const fifthPos = {
        x: x + 0.5,
        y: y + 0.5
    }
    if (totalPositions === 1) {
        return firstPos
    }
    else if (totalPositions === 2) {
        if (position === 1) {
            return secondPos
        } else if (position === 2) {
            return fourthPos
        }
    }
    else if (totalPositions === 3) {
        if (position === 1) {
            return secondPos
        } else if (position === 2) {
            return thirdPos
        } else if (position === 3) {
            return fourthPos
        }
    }
    else if (totalPositions === 4) {
        if (position === 1) {
            return secondPos
        } else if (position === 2) {
            return thirdPos
        } else if (position === 3) {
            return fourthPos
        } else if (position === 4) {
            return fifthPos
        }
    }
    else if (totalPositions === 5) {
        if (position === 1) {
            return firstPos
        } else if (position === 2) {
            return secondPos
        } else if (position === 3) {
            return thirdPos
        } else if (position === 4) {
            return fourthPos
        } else if (position === 5) {
            return fifthPos
        }
    }
}