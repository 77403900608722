import * as THREE from 'three';
import { useGameStore } from '@/stores/gameStore.ts';

export class MapBlackHole {
  private readonly circle: THREE.Mesh;

  public constructor(scene: THREE.Scene) {
    const gameStore = useGameStore();

    // Geometry and Material
    const geometryBH = new THREE.CircleGeometry(64, 64);
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(gameStore.cdn + 'images/map/blackhole_orange.webp', () => {

    });

    const materialBH = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      opacity: 0.5,
      color: new THREE.Color(1, 0.5, 0.5),
    });

    materialBH.needsUpdate = true;

    this.circle = new THREE.Mesh(geometryBH, materialBH);
    this.circle.position.set(gameStore.galaxyTileGridSize/2, gameStore.galaxyTileGridSize/2, -0.1);
    scene.add(this.circle);

    //draw a black circle in the same location
    const geometryBH2 = new THREE.CircleGeometry(38, 64);
    const materialBH2 = new THREE.MeshBasicMaterial({
      color: 0x000000,
    });
    const circle2 = new THREE.Mesh(geometryBH2, materialBH2);
    circle2.position.set(gameStore.galaxyTileGridSize/2, gameStore.galaxyTileGridSize/2, 0);
    circle2.name="blackHole";
    scene.add(circle2);

    this.animate();
  }

  private animate = () => {
    requestAnimationFrame(this.animate);
    this.circle.rotation.z -= 0.0005;
  };
}
