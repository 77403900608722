<script setup>
import { ref, onMounted, onUnmounted, defineProps } from 'vue'
import { useTableStore } from '@/stores/tableStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { usePQAStore } from '@/stores/PQAStore.ts'
import Backend from '@/models/backend.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import { usePlanetGroupStore } from '@/stores/planetGroupStore.ts'

const tableStore = useTableStore()
const gameStore = useGameStore()
const pqaStore = usePQAStore()
const mapStore = useMapStore()
const planetGroupStore = usePlanetGroupStore()

const props = defineProps({
  text: {
    type: String,
    default: 'Action'
  }
});


const menuRef = ref(null);

function toggleMenu() {
  if(gameStore.pullDownMenuVisible === 'planetMultiSelectMenu') {
    closeMenu()
  } else {
    gameStore.pullDownMenuVisible = 'planetMultiSelectMenu'
  }
}



// Perform actions on the map with selected ships
const renamePlanets = () => {
    if (tableStore.planets.selectedRowIds.length > 0) {
      // console.log('actionMap', tableStore.ships.selectedRowIds)
      // shipActionStore.initShipsFromIds(tableStore.ships.selectedRowIds);
      // tableStore.ships.selectedRowIds = [];
      // tableStore.ships.selectedAll = false
      // router.push('/map/galaxy/')
    }
}

/**
 * Assign ships to a fleet
 */
const setProductionQueue = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetsSetProductionQueue'
    gameStore.modalData = tableStore.planets.selectedRowIds;

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const setPopulationAssignments = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetsSetPopulationAssignments'
    gameStore.modalData = tableStore.planets.selectedRowIds;

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const clearProductionQueues = () => {
  const backend = new Backend()
  backend.clearProductionFromPlanet(tableStore.planets.selectedRowIds)
  gameStore.setTinySuccessToastMessage('Production queues cleared')

  // Disable area selection on the map
  mapStore.disableAreaSelection();

  closeMenu()
}

const buildShips = () => {

  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetBuildShip'
    gameStore.modalData = { planetIds:tableStore.planets.selectedRowIds }

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const buildBuildings = () => {

  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'planetBuildBuilding'
    gameStore.modalData = { planetIds:tableStore.planets.selectedRowIds }

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

/**
 * Attach selected ships to a group
 */
const attachToGroup = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    gameStore.modalType = 'attachToPlanetGroup'
    gameStore.modalData = tableStore.planets.selectedRowIds;
    tableStore.planets.selectedRowIds = []
    tableStore.planets.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}

const detachFromGroup = () => {
  if (tableStore.planets.selectedRowIds.length > 0) {
    const backend = new Backend();
    backend.detachPlanetsFromGroup(tableStore.planets.selectedRowIds)
    tableStore.planets.selectedRowIds = []
    tableStore.planets.selectedAll = false

    // Disable area selection on the map
    mapStore.disableAreaSelection();
  }
}


function closeMenu() {
  gameStore.pullDownMenuVisible = '';
}

function handleClickOutside(event) {
  if (!menuRef.value.contains(event.target)) {
    closeMenu();
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>


<template>
  <div class="relative" @click.stop="toggleMenu">
    <!-- Icon Button -->
    <slot></slot>
    <!-- Dropdown Menu -->
    <div v-show="gameStore.pullDownMenuVisible === 'planetMultiSelectMenu'" ref="menuRef" class="absolute w-56 border border-slate-500 bg-slate-800 rounded z-50 mt-1">
<!--        <span class="block px-4 py-2 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="renamePlanets">-->
<!--          Rename Planets-->
<!--        </span>-->
      <div class="bg-slate-600 text-center text-slate-300">
        {{ tableStore.planets.selectedRowIds.length }} Planets selected
      </div>
      <span v-if="pqaStore.queues.size>0" class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="setProductionQueue">
          Set production queue
      </span>
      <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="setPopulationAssignments">
          Set population assignments
      </span>
      <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="clearProductionQueues">
          Clear production
      </span>
      <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="buildShips">
        Build ships
      </span>
      <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="buildBuildings">
        Build buildings
      </span>
      <span v-if="planetGroupStore.planetGroups.size>0">
        <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="attachToGroup">
          Add to group
        </span>
        <span class="block p-1 text-sm text-white hover:bg-slate-700 cursor-pointer" @click="detachFromGroup">
          Remove from group
        </span>
      </span>
    </div>
  </div>
</template>

<style scoped>
/* Add any specific styles for your dropdown here */
</style>
