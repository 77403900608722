<script setup lang="ts">

import {computed, defineComponent, defineProps, type Ref, ref, watch} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import {useGameStore} from "@/stores/gameStore";
import {useAssetStore} from "@/stores/assetStore";
import type ProductionObject from "@/types/ProductionObject";
import PlanetProductionAvailableObject
  from "@/components/Sections/Planets/PlanetTabs/ProductionTab/Objects/PlanetProductionAvailableObject.vue";

/**
 * Available planet production objects tab component
 */
defineComponent({
  name: 'PlanetProductionTabAvailable',
})

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()
const gameStore = useGameStore()
const assetStore = useAssetStore()

const planetData = computed(() => planetStore.findPlanetById(props?.planetId))
const availableProductionObjects: Ref<ProductionObject[]> = ref([]);

/**
 * Calculate the available production objects for the current planet
 **/
const updateList = () => {
  // Get all available production objects for the player
  const productionObjectsMap = gameStore.playerProductionObjects;

  if (productionObjectsMap && productionObjectsMap.size > 0) {
    let tempProductionObjects = Array.from(productionObjectsMap.entries())
      .map(([id, value]) => {
        const productionObject = assetStore.findProductionObjectById(id);
        if (!productionObject) {
          throw new Error(`Production object with ID ${id} not found.`);
        }
        return productionObject;
      })
      .sort((a, b) => a.productionCost - b.productionCost); // Sort by cost

    // Remove buildings that are already built on the planet
    const buildings = planetData.value.buildings;
    // console.log('buildings')
    // console.log(buildings)
    if(buildings) {
      //iterate over all production objects with a for loop
      for (let i = 0; i < buildings.length; i++) {
        //iterate over all production objects with a for loop
        for (let j = 0; j < tempProductionObjects.length; j++) {
          //check if the production object is a building and if the building is already built on the planet
          if (tempProductionObjects[j].type === "building" && buildings[i] === tempProductionObjects[j].objectId) {
            //remove the building from the list of available production objects
            tempProductionObjects.splice(j, 1);
          }
        }
      }
    }

    // Remove current building if its being build currently
    if(planetData.value.productionObjectId){
      tempProductionObjects = tempProductionObjects.filter(productionObject => {
        return !(planetData.value.productionObjectId === productionObject.id && productionObject.type === "building");
      });
    }

    //console.log('available objects')
    //console.log(tempProductionObjects)

    // Remove buildings that are in the queue
    const productionQueue = planetData.value.productionQueue;
    if(productionQueue) {
      for (let i = 0; i < productionQueue.length; i++) {
        for (let j = 0; j < tempProductionObjects.length; j++) {
          if (tempProductionObjects[j].type === "building" && productionQueue[i]['productionObjectId'] == tempProductionObjects[j].id) {
            tempProductionObjects.splice(j, 1);
          }
        }
      }
    }

    availableProductionObjects.value = tempProductionObjects;
  }
}

// Watch for changes in planetId and update sliders accordingly
watch(() => planetData.value.timestamp, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateList();
  }
}, { immediate: true }); // immediate: true ensures this runs on mount as well


</script>

<template>
  <div v-for="productionObject in availableProductionObjects" :key="productionObject.id">
    <PlanetProductionAvailableObject
        :planet-id="planetData.id"
        :production-object-id="productionObject.id">
    </PlanetProductionAvailableObject>
  </div>
  <div v-if="availableProductionObjects.length === 0">
    <div class="flex justify-center text-subtext p-10">Nothing available</div>
  </div>
</template>

<style scoped lang="scss">
</style>
