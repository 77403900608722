<script setup lang="ts">

import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import { usePQAStore } from '@/stores/PQAStore.ts'
import Backend from '@/models/backend.ts'
import { computed } from 'vue'
import { usePlanetStore } from '@/stores/planetStore.ts'

const props = defineProps({
  visible: Boolean,
  planetId: Number
});

const PQAStore = usePQAStore()
const planetStore = usePlanetStore()
const backend = new Backend()

const planetData = computed(() => planetStore.findPlanetById(props.planetId))

const emit = defineEmits(['update:visible']);

const closePopup = () => {
  emit('update:visible', false);
};

const selectPQA = (id:number) => {
  backend.planetSetPQA([props.planetId],id).then((result) => {
    closePopup()
  })
}

</script>



<template>
  <div class="fixed bottom-0 right-0 left-0 top-0 bg-black opacity-5 z-10 cursor-" @click="closePopup">

  </div>
  <div class="absolute bg-slate-900 border border-slate-600 bottom-6 right-0 shadow-2xl w-72 z-20">
    <div class="overflow-y-auto max-h-72 pt-2 px-2">
      <div class="grid grid-cols-1">
        <template v-for="pqa in PQAStore.queues.values()" :key="pqa.id">
          <div
            :class="{
            'border border-slate-700 hover:bg-slate-600 rounded p-2 mb-2 cursor-pointer': true,
            'bg-slate-700' : planetData.planetQueueAutomationId === pqa.id,
            'bg-slate-800' : planetData.planetQueueAutomationId !== pqa.id,
          }"
            @click.once="selectPQA(pqa.id)">
            {{ pqa.name }}
          </div>
        </template>
        <div v-if="PQAStore.queues.size === 0">
          <div class="text-subtext p-6 text-center">You don't have any queues yet, click on Manage to create one.</div>
        </div>
      </div>
    </div>
    <div class="flex justify-between p-2 bg-slate-700">
      <div>
        <ModalTrigger type="planetProductionAutomation">
          <StandardButton>Manage</StandardButton>
        </ModalTrigger>
      </div>
      <div>
        <StandardButton color="red" :end-margin="false" @click.once="selectPQA(0)">Reset</StandardButton>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">

</style>