<script setup lang="ts">

import { defineProps } from 'vue'
import { useGameStore } from '@/stores/gameStore'

const props = defineProps({
  type: String,
  data: {
    type: Object,
    default: () => ({}) // Default to an empty object
  }
});

const gameStore = useGameStore()

const loadModal = () => {
  gameStore.modalType = props.type as string
  gameStore.modalData = props.data as {}
}

</script>

<template>
<span class="cursor-pointer" @click="loadModal()" @click.stop>
  <slot></slot>
</span>
</template>

<style scoped lang="scss">

</style>