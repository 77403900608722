import { defineStore } from 'pinia';
import type Fleet from '@/types/Fleet.ts'
import { useShipStore } from '@/stores/shipStore.ts'
import Eta from '@/models/eta.ts'

export const useFleetStore = defineStore('fleetStore', {
    state: () => ({
        fleets: new Map<number, Fleet>()
    }),
    getters: {
        findFleetById: (state) => (id: number): Fleet | undefined => {
            return state.fleets.get(id);
        }
    },
    actions: {
        updateFleetById(id: number, data: Fleet) {
            data = this.getFleetStats(data)

            this.fleets.set(id, data);
        },
        refreshFleetData(id: number) {
            const fleet = this.fleets.get(id)
            if(fleet) {
                this.updateFleetById(id, fleet)
            }
        },
        deleteFleetById(id: number) {
            if (this.fleets.has(id)) {
                this.fleets.delete(id);
            }
        },
        getFleetNameById(id: number) {
            if (id>0 && this.fleets.has(id)) {
                const fleet = this.fleets.get(id);
                return fleet?.name ?? 'No name';
            }
            console.log(this.fleets)
            return 'No fleet'
        },
        getFleetStats(data: Fleet) {
            const shipStore = useShipStore();
            const ships = shipStore.getShipsFromFleet(data.id)
            let maxSpeed = 0;
            let nrOfShips = 0;
            let attackShips = 0;
            let attackPlanets = 0;
            let shields = 0;
            let scanningRange = 0;
            let maxHealth = 0;
            let currentHealth = 0;
            let healthPercentage = 100;
            let repair = 0;
            for (const ship of ships) {
                nrOfShips++
                //Speed
                if(ship.maxSpeed < maxSpeed || maxSpeed === 0) {
                    if(!ship.catchingUpToFleet) {
                        maxSpeed = ship.maxSpeed
                    }
                }
                //Attack
                attackShips += ship.attackShips
                attackPlanets += ship.attackPlanets
                shields += ship.shields
                //Scanning Range
                if(ship.scanningRange > scanningRange) {
                    scanningRange = ship.scanningRange
                }
                //Health
                maxHealth+=ship.maxHitPoints
                currentHealth+=ship.currentHitPoints
                repair+=ship.repair
            }

            if(nrOfShips>0) {
                //Health percentage
                healthPercentage = Math.floor(currentHealth / (maxHealth / 100))
            }

            data.nrOfShips = nrOfShips
            data.maxSpeed = maxSpeed
            data.attackShips = attackShips
            data.attackPlanets = attackPlanets
            data.shields = shields
            data.scanningRange = scanningRange
            data.healthPercentage = healthPercentage
            data.repair = repair

            // Calculate the eta to the next hop
            if(data.actions) {
                let etaNextHop = 0;
                if (data.actions.length > 0) {
                    const eta = new Eta();
                    eta.start(data.xPos, data.yPos);
                    const action = data.actions[0]
                    etaNextHop = eta.etaNext(action.targetX, action.targetY, data.maxSpeed);
                }
                data.etaNextHop = etaNextHop;
            } else {
                data.etaNextHop = 0;
            }

            return data
        }
    },
});
