<script setup lang="ts">
import { RouterLink, useRoute } from 'vue-router'
import {useGameStore} from "@/stores/gameStore.ts";
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import { computed, ref } from 'vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { version } from '@/../package.json'; // Adjust path if necessary

const appVersion = version;

const route = useRoute()
const gameStore = useGameStore()
const playerRelations = usePlayerRelationStore()
const menuVisible = ref(false)

const totalDiplomacyRequests = computed(() => {
  return playerRelations.getCountOfPendingActions()
})


const buttons = [
  {
    'name': 'Map',
    'target' : '/map/galaxy',
    'activeKey': 'Map'
  },
  {
    'name': 'Planets',
    'target' : '/planets',
    'activeKey': 'Planets'
  },
  {
    'name': 'Command',
    'target' : '/command/ships',
    'activeKey': 'Command'
  },
  {
    'name': 'Research',
    'target' : '/research',
    'activeKey': 'Research'
  },
  // {
  //   'name': 'Vips',
  //   'target' : '/vips',
  //   'activeKey': 'Vips'
  // },
  {
    'name': 'Diplomacy',
    'target' : '/diplomacy/overview',
    'activeKey': 'Diplomacy'
  },
  {
    'name': 'Government',
    'target' : '/government/cabinet',
    'activeKey': 'Government'
  },
  {
    'name': 'Resources',
    'target' : '/resources',
    'activeKey': 'Resources'
  },
  {
    'name': 'Statistics',
    'target' : '/stats',
    'activeKey': 'Statistics'
  },
  {
    'name': 'News',
    'target' : '/news',
    'activeKey': 'News'
  }
]

</script>

<template>
  <div v-if="menuVisible" @click="menuVisible = !menuVisible" class="fixed w-full h-full">
    <!-- Close menu on background click -->
  </div>
  <div class="header bg-slate-600 flex  sm:justify-start justify-between text-white">
    <!-- Logo -->
    <span class="pl-2 pr-2 font-bold self-center text-slate-400">Ferion <span class="text-xs">{{ appVersion }}</span><span class="pl-2 text-white sm:hidden">{{ gameStore.player?.nickName }}</span></span>
    <!-- Menu -->
      <div :class="{
        'text-nowrap text-center sm:text-left':true,
        'hidden sm:flex sm:self-center self-top': !menuVisible,
        'absolute flex flex-col bg-slate-600 z-50 p-1 top right-0': menuVisible
      }">
        <RouterLink
          v-for="button in buttons"
          :key="button.name"
          :to="button.target"
        >
          <div
            @click="menuVisible = false"
            :class="{
              'p-1.5 sm:py-1.5 px-2': true,
              'bg-slate-800': typeof route.name === 'string' && route.name.startsWith(button.activeKey)
            }">
            {{ button.name }}
            <span
              v-if="button.activeKey ==='News' && Number(gameStore.player?.unreadNewsItems) > 0"
              class="rounded bg-red-500 text-white text-xs px-1 ml-1"
            >{{ gameStore.player?.unreadNewsItems }}</span>

            <span
              v-if="button.activeKey ==='Diplomacy' && totalDiplomacyRequests > 0"
              class="rounded bg-red-500 text-white text-xs px-1 ml-1"
            >{{ totalDiplomacyRequests }}</span>
          </div>
        </RouterLink>
      </div>
      <div class="block sm:hidden self-center">
        <StandardButton @click="menuVisible = !menuVisible">
          Menu
        </StandardButton>
      </div>
    </div>
</template>

<style scoped>
.header {
  height: 2rem;
}
.top{
  top: 2rem
}
</style>