import { defineStore } from 'pinia'
import type Player from "@/types/Player";
import type PlayerShipDesign from "@/types/PlayerShipDesign.ts";
export const useGameStore = defineStore('gameStore', {
    state: () => ({
        galaxyTileGridSize: 0 as number,
        sectorTileGridSize: 0 as number,
        turnInterval: 0 as number,
        shipTurnInterval: 0 as number,
        planetTurnInterval: 0 as number,
        playerTechTurnInterval: 0 as number,
        playerCreditsTurnInterval: 0 as number,
        lastPlanetTurn: 0 as number,
        lastShipTurn: 0 as number,
        lastPlayerTechTurn: 0 as number,
        lastPlayerCreditsTurn: 0 as number,
        websocketInitialSyncCompleted: false as boolean,
        gameOutOfSync: false as boolean,
        backendError: '' as string,
        playerToken: '' as string,
        authPlayerId: 0 as number,
        turn: 0 as number,
        turnedAt: 0 as number,
        mapInitialized: false as boolean,
        player: null as Player | null,
        playerShipDesigns: [] as Record<number, PlayerShipDesign>,
        playerProductionObjects: new Map<number, string>(),
        playerShipComponents: new Map<number, string>(),
        playerMinisters: new Map<string, string>(),
        playerTechs: new Map<number, number>(),
        cdn: import.meta.env.VITE_CDN_URL as string,
        modalType: '' as string, //Pops up a model with the given type
        modalData: {} as any, //Data to be passed to the modal
        modal2Type: '' as string, //Pops up a model with the given type (second modal to layer on top of the first)
        modal2Data: {} as any, //Data to be passed to the modal (second modal to layer on top of the first)
        pendingRequestId: '' as string,
        pendingRequestPopupVisible: false as boolean,
        tinyToastMessage: '' as string,
        tinyToastType: '' as string,
        pullDownMenuVisible: '' as string, //Used to show/hide multiple pull down menus, so they don't overlap
        loadingText: '' as string,
    }),
    getters: {
        getShipDesignById: (state) => (id: number): PlayerShipDesign | undefined => {
            return Object.values(state.playerShipDesigns).find(c => c.id === id);
        }
    },
    actions: {
        setTinySuccessToastMessage(message: string) {
            this.tinyToastMessage = message;
            this.tinyToastType = 'success';
        },
        setTinyErrorToastMessage(message: string) {
            this.tinyToastMessage = message;
            this.tinyToastType = 'error';
        },
        setPendingRequest(): string {
            this.pendingRequestPopupVisible = false;
            this.pendingRequestId = this.generateRequestId();
            // Make the popup visible after 500ms
            setTimeout(() => {
                this.pendingRequestPopupVisible = true;
            }, 700);

            return this.pendingRequestId;
        },
        clearPendingRequest(requestId:string): void {
            console.log('Received request ID', requestId)
            if(requestId == this.pendingRequestId) {
                this.pendingRequestId = ''
            }
        },
        generateRequestId(): string {
            return '1e7-1e3-4e3-8e3-1e11'.replace(/[018]/g, c =>
              (parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c) / 4).toString(16)
            );
        }

    }
})
