import numeral from 'numeral';

export const formatNumber = (value:number) => numeral(value).format('0,0');

export const formatShort = (value: number): string => {
  if (value < 100) {
    //if the value has no decimal places, return it as is
    if (value % 1 === 0) {
      return numeral(value).format('0');
    } else {
      return numeral(value).format('0.00');
    }
  }

  if (value < 1000) {
    return numeral(value).format('0'); // Show plain number for values below 1,000
  }
  if (value % 1000 === 0) {
    return numeral(value).format('0a'); // Show abbreviation without decimals for even thousands
  }
  return numeral(value).format('0.[0]a'); // Show one decimal for non-round thousands (e.g., 12.5k)
};
