<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import PlanetBuildingTabObject from '@/components/Sections/Planets/PlanetTabs/BuildingsTab/PlanetBuildingTabObject.vue'

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore();
const gameStore = useGameStore();

const passiveDefenceBuildings = computed(() => {
  return planetStore.getPassiveDefenceBuildings(props.planetId)
})


</script>

<template>
  <div class="content-header mt-1">
    Buildings providing passive defense
  </div>
  <div>
    <div v-for="building in passiveDefenceBuildings" :key="building.buildingCode">
      <PlanetBuildingTabObject
        :planet-id="planetId"
        :building-code="building.buildingCode"
        class="mt-1"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>