<script setup lang="ts">
import {computed, onMounted, onUnmounted} from "vue";
import {useNewsStore} from "@/stores/newsStore.ts";
import router from '@/router';
import Backend from "@/models/backend.ts";
import {useGameStore} from "@/stores/gameStore.ts";
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import NewsItem from '@/components/Sections/News/NewsItem.vue'

const newsStore = useNewsStore();
const gameStore = useGameStore();
const backend = new Backend();

const gotoNewsDetails = (path: string) => {
  router.push(path);
};

onMounted(() => {
  // Load the first page
  if(!newsStore.firstLoad) {
    backend.getLatestNews(0);
  }

});

onUnmounted(() => {
  if (gameStore.player) {
    newsStore.setAllRead();
    backend.setNewsRead();
  }
});

const loadMore = () => {
  backend.getLatestNews(newsStore.lowestId);
};

const sortedNews = computed(() => {
  return [...newsStore.news.values()].sort((a, b) => b.id - a.id);
});

</script>

<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-1 p-1 ">
      <div
          v-for="newsItem in sortedNews" :key="newsItem.id"
          class="cursor-pointer"
          @click="gotoNewsDetails(newsItem.targetUrl)"
      >
        <div :class="{
          'border-slate-600': !newsItem.isRead,
          'border-slate-800': newsItem.isRead,
          'border hover:border-slate-700': true
        }">
          <NewsItem :newsItem="newsItem"/>
        </div>
      </div>
  </div>
  <div v-if="newsStore.loadMoreDone" class="flex justify-center p-2">
    <StandardButton @click="loadMore">Load More</StandardButton>
  </div>
  <div v-if="newsStore.news.size === 0">
    <div class="text-center text-subtext p-6">No news available</div>
  </div>
</template>

<style scoped lang="scss">
</style>
