<script setup lang="ts">

import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { computed, defineProps, ref } from 'vue'
import { usePQAStore } from '@/stores/PQAStore.ts'
import PQASelector from '@/components/Sections/Planets/PlanetTabs/PlanetProductionQueueAutomation/PQASelector.vue'

const props = defineProps({
  planetId: Number,
  planetQueueAutomationId: Number,
});

const PQAStore = usePQAStore()

const visible = ref(false);

const buttonName = computed(() => {
  if(props.planetQueueAutomationId === 0) {
    return 'Preset'
  }
  const pqa = PQAStore.findQueueById(props.planetQueueAutomationId)
  return pqa?.name
})

const updateVisible = (value: boolean) => {
  visible.value = value;
};

</script>

<template>
  <div class="relative !font-normal !text-xs">
    <PQASelector
      v-if="visible"
      :visible="visible"
      :planet-id="props.planetId"
      @update:visible="updateVisible"></PQASelector>
    <StandardButton
      @click="visible = !popupVisible"
      :end-margin="false"
    >{{ buttonName }}</StandardButton>
  </div>
</template>

<style scoped lang="scss">

</style>