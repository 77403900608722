<script setup lang="ts">

import { ref } from 'vue'
import { useGameStore } from '@/stores/gameStore.ts'
import MapFleetSideBarMine
  from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/Tabs/MapFleetSideBarMine.vue'
import MapFleetSideBarEnemy
  from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/Tabs/MapFleetSideBarEnemy.vue'
import MapFleetSideBarOther
  from '@/components/Sections/Map/Galaxy/WebGLMap/MapObjects/MapSideBar/Tabs/MapFleetSideBarOther.vue'

const gameStore = useGameStore();

const currentTab = ref('mine');

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'mine',
               'text-blue-500 border-blue-500': currentTab === 'mine',
               'text-gray-500 border-transparent': currentTab !== 'mine',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'mine'">
          {{ gameStore.player?.nickName }}
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'enemy',
               'text-blue-500 border-blue-500': currentTab === 'enemy',
               'text-gray-500 border-transparent': currentTab !== 'enemy',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'enemy'">
          Enemy
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'other',
               'text-blue-500 border-blue-500': currentTab === 'other',
               'text-gray-500 border-transparent': currentTab !== 'other',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'other'">
          Others
        </a>
      </li>
    </ul>
  </div>
  <div v-if="currentTab === 'mine'" class="pt-1">
    <MapFleetSideBarMine/>
  </div>
  <div v-else-if="currentTab === 'enemy'" class="pt-1">
    <MapFleetSideBarEnemy/>
  </div>
  <div v-else-if="currentTab === 'other'" class="pt-1">
    <MapFleetSideBarOther/>
  </div>
</template>

<style scoped lang="scss">

</style>