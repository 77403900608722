<script setup lang="ts">

import { defineProps } from 'vue'
import type { PropType } from 'vue';
import { useGameStore } from '@/stores/gameStore.ts'
import type OreConversion from '@/types/OreConversion.ts'
import ConveyorTB from '@/components/Sections/Resources/Elements/Conveyor/ConveyorTB.vue'
import ConveyorBT from '@/components/Sections/Resources/Elements/Conveyor/ConveyorBT.vue'

const gameStore = useGameStore()

const props = defineProps({
  oreId: {
    type: Number,
    required: true
  },
  conversionOut: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  conversionIn: {
    type: Object as PropType<OreConversion>,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  left: {
    type: Number,
    required: true
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})
</script>

<template>
  <div :class="{
    'absolute': true,
    'border': debug
  }"
    :style="'left:' + left + 'rem;top:' + top + 'rem;width:5rem;height:10rem;'">
    <ConveyorTB
      :object-image="gameStore.cdn + 'images/icons/ore/' + props.oreId + '.png'"
      :conversion="props.conversionOut"
      :width="1"
      :height="7"
      :top="0"
      :left="1"
      :debug="debug"
    />

    <ConveyorBT
      :object-image="gameStore.cdn + 'images/icons/ore/' + props.oreId + '.png'"
      :conversion="props.conversionIn"
      :width="1"
      :height="7"
      :top="0"
      :left="3"
      :debug="debug"
    />
    <div :class="{
            'absolute rounded bg-slate-800 border border-slate-700':true,
            'opacity-50':debug
          }"
         style="top:6rem;left:0rem;width:5rem;z-index:15"
    >
      <div class="flex-col">
      <img class="p-2" :src="gameStore.cdn + '/images/icons/trading.png'">
      <div class="text-center">Trade</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>