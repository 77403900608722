<script setup lang="ts">

import { computed, defineComponent, defineProps, ref } from 'vue'
import {useAssetStore} from "@/stores/assetStore";
import Backend from "@/models/backend";
import Icon from '@/components/Objects/Icons/Icon.vue'
import { useGameStore } from '@/stores/gameStore'
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import {useShipDesignStore} from "@/stores/shipDesignStore.ts";
import BuildingEffects from '@/components/Objects/Building/BuildingEffects.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import BuildingBase from '@/components/Sections/Planets/PlanetTabs/BuildingBase.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import { useFleetStore } from '@/stores/fleetStore.ts'

/**
 * Props
 */
const props = defineProps({
  planetId: Number,
  queueId: Number,
  productionObjectId: Number,
  productionShipDesignId: Number,
  productionShipFleetId: Number
});

const showButton = ref(false);

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const gameStore = useGameStore()
const fleetStore = useFleetStore()
const shipDesignStore = useShipDesignStore()
const backend = new Backend()

/**
 * Computed
 */
const productionObjectData = computed(() => assetStore.findProductionObjectById(props.productionObjectId))

const shipDesignData = computed(() => shipDesignStore.getShipDesignById(props.productionShipDesignId))

const name = computed(() => {
  if (shipDesignData.value) {
    return shipDesignData.value.name
  } else {
    return productionObjectData.value.productionObjectName
  }
});

const constructionCost = computed(() => {
  if (shipDesignData.value) {
    return shipDesignData.value.productionCost
  } else {
    return productionObjectData.value.productionCost
  }
});

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})


/**
 * Set production object
 */
const deleteFromQueue = () => {
 backend.removeProductionObjectFromQueue(props.planetId, props.queueId);
  gameStore.setTinySuccessToastMessage('Production object removed from queue');
}

</script>

<template>
  <BuildingBase>
    <template #image>
      <ModalTrigger v-if="productionObjectData.type=='building'" type="building" :data="{'buildingCode': productionObjectData.objectId}">
        <ImageRounded
          type="buildings"
          source-size="128"
          :file="productionObjectData.objectId"
          :size="8"/>
      </ModalTrigger>
      <ImageRounded
        v-else
        type="buildings"
        source-size="128"
        file="ship"
        :size="8"/>
    </template>
    <template #button>
      <StandardButton
        color="red"
        @click="deleteFromQueue"
        :end-margin="false"
      >X</StandardButton>
    </template>
    <template #title>
      {{ name }}
    </template>
    <template #default>
      <span v-if="productionShipFleetId>0" class="pt-3">Assign to fleet <b class="text-slate-400">{{ fleetStore.getFleetNameById(productionShipFleetId) }}</b></span>
      <span v-else>{{ productionObjectData.description }}</span>
    </template>
    <template #effects>
      <div class="flex justify-between w-full">
        <div>
          <BuildingEffects v-if="buildingData" :building="buildingData"> </BuildingEffects>
        </div>
        <div class="flex-shrink text-subtext">{{ constructionCost }} <Icon type="production"></Icon></div>
      </div>
    </template>
  </BuildingBase>
</template>

<style scoped lang="scss">
</style>
