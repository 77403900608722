<script setup lang="ts">

import FleetList from '@/components/Sections/Command/Fleets/FleetList.vue'
import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import router from '@/router'
import { useFleetStore } from '@/stores/fleetStore.ts'
import { useTableStore } from '@/stores/tableStore.ts'

const route = useRoute();
const fleetStore = useFleetStore()
const tableStore = useTableStore()

const selectedFleetId = ref(0);

const selectFleetDetails = (fleetId: number) => {
  //set Router
  router.push(`/command/fleets/${fleetId}`);
  selectedFleetId.value = fleetId;
}

onMounted(() => {
  if(Number(route.params.fleetId) > 0) {
    // Load planet details from URL
    tableStore.fleets.singleSelectedId = Number(route.params.fleetId);
  } else if(tableStore.fleets.singleSelectedId > 0) {
    // Load from last selected planet
    //only when we are on portrait mode mobile
    if (window.innerWidth > 500) {
      router.push('/command/fleets/' + tableStore.fleets.singleSelectedId);
    }
  } else {
    // Load first planet from list
    if (fleetStore.fleets.size > 0 && window.innerWidth > 500) {
      router.push('/command/fleets/' + fleetStore.fleets.values().next().value.id);
    }
  }
});

</script>

<template>
  <div class="bg-slate-900 content-height-limit-tab overflow-y-auto">
      <div class="flex flex-col md:flex-row">
        <div
          :class="{
          'w-full md:w-2/5' : true,
          'hidden sm:block' : Number(route.params.fleetId) > 0
        }">
          <div class="mt-1 mb-1">
            <FleetList></FleetList>
          </div>
        </div>
        <div class="w-full md:w-3/5 pl-1 pt-0 sm:pt-1 pr-1 sm:content-height-limit-tab">
          <div class="bg-slate-850 rounded text-white">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped lang="scss">

</style>