<script setup lang="ts">

</script>

<template>
  <div class="flex justify-between content-header">
    <div>
      <slot name="left"></slot>
    </div>
    <div>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>