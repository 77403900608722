<script setup lang="ts">

import PlayerName from '@/components/Objects/PlayerName.vue'
import type MapCoordinate from '@/types/MapCoordinate.ts'
import {useGameStore} from "@/stores/gameStore.ts";

const gameStore = useGameStore()

const props = defineProps<{
  coordinate: MapCoordinate
}>();

const openShip = (playerId: Number) => {
  if(playerId == gameStore.player?.id) {
    gameStore.modalType = 'mapSelectPlayerShip'
    gameStore.modalData = {
      xPos: props.coordinate.xPos,
      yPos: props.coordinate.yPos,
    }
  }
}

</script>

<template>
  <div class="w-40">
    <div v-for="(shipCount, playerId) in props.coordinate?.ships" :key="playerId">
      <div class="border-slate-800 border p-1 flex justify-between hover:bg-slate-700 cursor-pointer" @click="openShip(playerId)" title="Ships">
        <div class="flex-auto">
          <PlayerName :playerId="Number(playerId)"></PlayerName>
        </div>
        <div class="ps-4 flex-auto flex justify-end">
          <div class="flex-shrink">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.86 121.64" class="h-3 mt-1">
              <path d="M121.62,59,2.78.2A1.92,1.92,0,0,0,.2,1.08a1.89,1.89,0,0,0,0,1.76h0l30.87,58L.23,118.8h0a1.89,1.89,0,0,0,0,1.76,1.92,1.92,0,0,0,2.58.88l118.84-58.8a2,2,0,0,0,0-3.64Z" fill="white"/>
            </svg>
          </div>
          <div class="ps-2">{{ shipCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>