<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore'
import { onMounted, onUnmounted, ref } from 'vue'
import Backend from '@/models/backend.ts'
import * as THREE from 'three'
import type BRShipMesh from '@/types/Three/BattleReport/BRShipMesh.ts'
import type BRPlanetMesh from '@/types/Three/BattleReport/BRPlanetMesh.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import LargeWideModel from '@/components/Interface/Modal/ModelSizes/LargeWideModel.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import PlayerName from '@/components/Objects/PlayerName.vue'
import { formatNumber } from '@/models/formatNumber.ts'

const gameStore = useGameStore()

const brContainer = ref<HTMLDivElement | null>(null);

let renderer: THREE.WebGLRenderer;
let scene: THREE.Scene;
let camera: THREE.PerspectiveCamera;
let attackingPlayerId: number;
let defendingPlayerId: number;
let startBattleAnimation: boolean = false;
const battleWinner = ref<boolean>(false);
const battleComplete = ref<boolean>(false);
const animationSpeed = ref<number>(100);

const attackingTotalShips = ref<number>(0);
const defendingTotalShips = ref<number>(0);
const attackingShipsDestroyed = ref<number>(0);
const defendingShipsDestroyed = ref<number>(0);

const attackingDamageTakenFleetVsFleet = ref<number>(0);
const attackingDamageTakenPlanetVsFleet = ref<number>(0);

const defendingDamageTakenFleetVsFleet = ref<number>(0);
const defendingDamageTakenFleetVsPlanet = ref<number>(0);

const defendingPlanetShields = ref<number>(0);
const defendingPlanetTroops = ref<number>(0);

const planetBuildingsDestroyed = ref<number>(0);
const planetPopulationKilled = ref<number>(0);
const planetName = ref<string>('');

onMounted(() => {
  // Load the battle report
  const backend = new Backend()
  backend.getBattleReport(gameStore.modalData.battleReportId).then((response:any) => {
    initBattleReport(response.data)
  });
});

onUnmounted(() => {
  // Clean up
  // Dispose geometries, materials, and textures
  scene.traverse((object: any) => {
    if (object.isMesh) {
      object.geometry.dispose();
      object.material.dispose();
    }
  });

  // Remove all objects from the scene
  while (scene.children.length > 0) {
    scene.remove(scene.children[0]);
  }

  // Dispose of the renderer
  renderer.dispose();

  // Remove the canvas element
  if(brContainer.value) {
    brContainer.value.removeChild(renderer.domElement);
  }
});

/**
 * Initialize the battle report
 *
 * @param response
 */
const initBattleReport = (response:any) => {
  /**
   * Create the WebGL renderer and camera
   */
  console.log('initBattleReport Init')

  attackingPlayerId = response.attackerPlayerId
  defendingPlayerId = response.defenderPlayerId

  renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: 'high-performance', alpha: true});
  if (brContainer.value) {
    renderer.setSize(brContainer.value.clientWidth, brContainer.value.clientHeight);
    brContainer.value.appendChild(renderer.domElement);
  }

  camera = new THREE.PerspectiveCamera(45, brContainer.value ? brContainer.value.clientWidth / brContainer.value.clientHeight : 1, 1, 500);
  camera.position.set(0, 0, 100);
  camera.lookAt(0, 0, 0);

  camera.position.x = 1;
  camera.position.y = 1;

  scene = new THREE.Scene();

  // Build scene by iterating response.objects
  for (let object of response.objects) {
    addObject(response,object)
  }

  createLine()

  countShips();

  animate()

  //Wait for startBattleAnimation to be true
  const interval = setInterval(() => {
    if(startBattleAnimation) {
      clearInterval(interval)
      animateBattle(response)
    }
  }, 1000);

}

/**
 * Animate the battle
 *
 * @param response
 */
const animateBattle = (response: any) => {
  console.log('animateBattle');
  let currentStep = 0;

  const animateStep = () => {
    if (currentStep < response.events.length) {
      battleStep(response.events[currentStep]);
      currentStep++;
      setTimeout(() => {
        requestAnimationFrame(animateStep);
      }, animationSpeed.value);
    }
  };

  //hide line
  const line = scene.getObjectByName('line') as unknown as THREE.Line;
  if(line) {
    line.visible = false;
  }

  requestAnimationFrame(animateStep);
};

const createLine = () => {
  const material = new THREE.LineBasicMaterial({ color: 0x000000 });
  const points = [new THREE.Vector3(0,0, 0), new THREE.Vector3(0,0, 0)];
  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  const line = new THREE.Line(geometry, material);
  line.visible = false;
  line.name='line';
  scene.add(line);
};


/**
 * Handle a battle step
 *
 * @param object
 */
const battleStep = (object: any) => {
  if (object.type === 'attack') {
    // Get the attacking and defending ships/planet
    const attacker = scene.getObjectByName(object.attacker.id) as unknown as BRShipMesh;
    const defender = scene.getObjectByName(object.defender.id) as unknown as BRShipMesh;

    // Draw a line from the attacking ship to the defending ship (attacking)
    if (attacker && defender) {

      const line = scene.getObjectByName('line') as unknown as THREE.Line;
      //change color
      (line.material as THREE.LineBasicMaterial).color.setHex(attacker.color);
      //update points
      line.geometry.dispose();
      line.geometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(attacker.position.x, attacker.position.y, 0), new THREE.Vector3(defender.position.x, defender.position.y, 0)]);
      line.visible = true;

      renderer.render(scene, camera);

      if(object.defender.damageShipToShip){
        if(object.defender.playerId === attackingPlayerId) {
          attackingDamageTakenFleetVsFleet.value += object.defender.damageShipToShip;
        } else {
          defendingDamageTakenFleetVsFleet.value += object.defender.damageShipToShip;
        }
      } else if(object.defender.damageShipToPlanet) {
          defendingDamageTakenFleetVsPlanet.value += object.defender.damageShipToPlanet;
          defendingPlanetShields.value = object.defender.shields
          defendingPlanetTroops.value = object.defender.health
      } else if(object.defender.damagePlanetToShip) {
          attackingDamageTakenPlanetVsFleet.value += object.defender.damagePlanetToShip;
      }

      // Update the health and shields of the defender
      defender.health = object.defender.health;
      defender.shields = object.defender.shields;
    }
  } else if(object.type === 'destroyed') {
    // Destroy the ship
    const ship = scene.getObjectByName(object.id) as unknown as BRShipMesh;
    if(ship && ship.objectType === 'ship') {
      scene.remove(ship);
      renderer.render(scene, camera);
      if(object.playerId === attackingPlayerId) {
        attackingShipsDestroyed.value++;
      } else {
        defendingShipsDestroyed.value++;
      }
    }
  } else if(object.type === 'winner') {
    battleWinner.value = object.playerId === gameStore.player?.id;
    battleComplete.value = true;
    //hide line
    const line = scene.getObjectByName('line') as unknown as THREE.Line;
    if(line) {
      line.visible = false;
    }
  } else if(object.type === 'buildingDestroyed') {
    planetBuildingsDestroyed.value ++;
  } else if(object.type === 'populationKilled') {
    planetPopulationKilled.value ++;
  } else if(object.type === 'planetStats') {
    planetName.value = object.name;
    defendingPlanetShields.value = object.shields;
    defendingPlanetTroops.value = object.health;
  }
}

/**
 * Count the number of ships in the scene
 */
const countShips = () => {
  let defending = 0;
  let attacking = 0;
  for (let object of scene.children) {
    const sideObject = object as unknown as BRShipMesh;
    if(sideObject.side === 'attacker') {
      attacking++;
    } else if(sideObject.side === 'defender') {
      defending++;
    }
  }
  defendingTotalShips.value = defending;
  attackingTotalShips.value = attacking;
}

/**
 * Animate the scene
 */
const animate = () => {
  requestAnimationFrame(animate);
  let countCompletedNot = 0;
  for (let object of scene.children) {
    const animateObject = object as unknown as BRShipMesh;
    if(animateObject.animate) {
      if(!animateObject.movedToPosition) {
        countCompletedNot++;
      }
      animateObject.animate();
    }
  }
  if(countCompletedNot === 0) {
    startBattleAnimation = true;
  }
  renderer.render(scene, camera);
}

/**
 * Add an object to the scene
 *
 * @param response
 * @param object
 */
const addObject = (response: any, object: any) => {
  if(object.type === 'ship') {
    if(object.playerId === response.attackerPlayerId) {
      // This is a attacking ship
      console.log('Attacking ship')
      attackShip(object)
    } else {
      // This is a defending ship
      console.log('Defending ship')
      defendShip(object)
    }
  } else if(object.type === 'planet') {
      // This is a defending planet
      console.log('Defending planet')
      defendingPlanet(object)
  }
}

/**
 * Add a defending ship to the scene
 *
 * @param object
 */
const defendShip = (object: any) => {

  // Define the shape of the ship
  const shape = new THREE.Shape();
  const x = 0;
  const y = 0;
  shape.moveTo(x, y + 1);    // Top point
  shape.lineTo(x, y - 1);    // Bottom point
  shape.lineTo(x - 2, y);    // Right point, further out for a more pointy triangle

  // Define Colors
  let color = 0xff0000;
  let healthBarColor = 0xaa0000;
  if(defendingPlayerId === gameStore.player?.id) {
    color = 0x00FF00;
    healthBarColor = 0x00aa00;
  }

  // Create the ship
  const TriangleGeometry = new THREE.ShapeGeometry(shape);
  const TriangleMaterial = new THREE.MeshBasicMaterial({ color: color });
  const ship = new THREE.Mesh(TriangleGeometry, TriangleMaterial) as unknown as BRShipMesh;

  // Set Ship Parameters
  ship.name = object.id
  ship.side = 'defender'
  ship.objectType = 'ship'
  ship.color = color
  // Random between 5 and 50
  ship.targetXPos = Math.floor(Math.random() * 40) + 10;
  ship.position.x = Math.floor(Math.random() * 100) + 60;
  // Random between -35 and 35
  ship.position.y = Math.floor(Math.random() * 70) + -35;
  ship.position.z = 0.1;
  ship.movedToPosition = false
  ship._health = object.health

  scene.add(ship);

  // Create health bar
  const healthBarGeometry = new THREE.PlaneGeometry(Math.floor(ship._health/30), 0.25);
  const healthBarMaterial = new THREE.MeshBasicMaterial({ color: healthBarColor });
  const healthBar = new THREE.Mesh(healthBarGeometry, healthBarMaterial);
  healthBar.position.x = -1;
  healthBar.position.y = -1.5;
  healthBar.position.z = 0.2;
  ship.add(healthBar);
  ship.healthBar = healthBar;  // Store health bar in the ship object

  // Define health property with getter and setter
  Object.defineProperty(ship, 'health', {
    get: function () {
      return this._health;
    },
    set: function (value) {
      this._health = value;
      const newWidth = value / 30;
      this.healthBar.geometry.dispose(); // Dispose of the old geometry
      this.healthBar.geometry = new THREE.PlaneGeometry(newWidth, 0.25);
    }
  });
  //animate ship so it moves from the right to the left
  ship.animate = () => {
    if(ship.position.x > ship.targetXPos) {
      // Random between 0.1 and 0.5
      ship.position.x -= Math.random() * 0.5 + 0.1;
    } else {
      ship.movedToPosition = true
    }
  }

  renderer.render(scene, camera);
}

/**
 * Add an attacking ship to the scene
 *
 * @param object
 */
const attackShip = (object: any) => {
  const shape = new THREE.Shape();
  const x = 0;
  const y = 0;
  shape.moveTo(x, y + 1);    // Top point
  shape.lineTo(x, y - 1);    // Bottom point
  shape.lineTo(x + 2, y);    // Right point, further out for a more pointy triangle

  let color = 0xff0000;
  let healthBarColor = 0xaa0000;
  if (attackingPlayerId === gameStore.player?.id) {
    color = 0x00FF00;
    healthBarColor = 0x00aa00;
  }
  const TriangleGeometry = new THREE.ShapeGeometry(shape);
  const TriangleMaterial = new THREE.MeshBasicMaterial({ color: color });
  const ship = new THREE.Mesh(TriangleGeometry, TriangleMaterial) as unknown as BRShipMesh;

  console.log('ship added:' + object.id)
  ship.name = object.id
  ship.side = 'attacker'
  ship.objectType = 'ship'

  ship.color = color
  ship.targetXPos = Math.floor(Math.random() * -40) + -10;
  ship.position.x = -(Math.floor(Math.random() * 100) + 60);
  ship.position.y = Math.floor(Math.random() * 70) + -35;
  ship.position.z = 0.1;
  ship.movedToPosition = false
  ship._health = object.health

  scene.add(ship);

  // Create health bar
  const healthBarGeometry = new THREE.PlaneGeometry(ship._health/30, 0.25);
  const healthBarMaterial = new THREE.MeshBasicMaterial({ color: healthBarColor });
  const healthBar = new THREE.Mesh(healthBarGeometry, healthBarMaterial);
  healthBar.position.x = 1;
  healthBar.position.y = -1.5;
  healthBar.position.z = 0.2;
  ship.add(healthBar);
  ship.healthBar = healthBar;  // Store health bar in the ship object

  // Define health property with getter and setter
  Object.defineProperty(ship, 'health', {
    get: function () {
      return this._health;
    },
    set: function (value) {
      this._health = value;
      const newWidth = value / 30;
      this.healthBar.geometry.dispose(); // Dispose of the old geometry
      this.healthBar.geometry = new THREE.PlaneGeometry(newWidth, 0.25);
    }
  });

  //animate ship so it moves from the left to the right
  ship.animate = () => {
    if (ship.position.x < ship.targetXPos) {
      ship.position.x += Math.random() * 0.5 + 0.1;
    } else {
      ship.movedToPosition = true
    }
  }

  renderer.render(scene, camera);
}

/**
 * Add a defending planet to the scene
 *
 * @param object
 */
const defendingPlanet = (object: any) => {
  const texture = new THREE.TextureLoader().load(gameStore.cdn + 'images/planet/new/512/planet_' + object.planetType + '.png');
  const geometry = new THREE.PlaneGeometry( 50, 50 );
  const material = new THREE.MeshBasicMaterial( { map: texture,transparent:true } );
  const planet = new THREE.Mesh( geometry, material ) as unknown as BRPlanetMesh;

  // Define Colors
  planet.color = 0xff0000;
  let healthBarColor = 0xaa0000;
  if(defendingPlayerId === gameStore.player?.id) {
    planet.color = 0x00FF00;
    healthBarColor = 0x00aa00;
  }

  // Set Planet Parameters
  planet.position.x = 45;
  planet.position.y = 0;
  planet.position.z = -0.1;
  planet.name = object.id
  planet.objectType = 'planet'
  planet._health = object.health

  scene.add(planet);

  // Create health bar
  const healthBarGeometry = new THREE.PlaneGeometry(Math.floor( planet._health/10), 0.25);
  const healthBarMaterial = new THREE.MeshBasicMaterial({ color: healthBarColor });
  const healthBar = new THREE.Mesh(healthBarGeometry, healthBarMaterial);
  healthBar.position.x = -0.5;
  healthBar.position.y = -28;
  healthBar.position.z = 0.2;
  planet.add(healthBar);
  planet.healthBar = healthBar;  // Store health bar in the ship object

  // Define health property with getter and setter
  Object.defineProperty(planet, 'health', {
    get: function () {
      return this._health;
    },
    set: function (value) {
      this._health = value;
      const newWidth = value / 10;
      this.healthBar.geometry.dispose(); // Dispose of the old geometry
      this.healthBar.geometry = new THREE.PlaneGeometry(newWidth, 0.25);
    }
  });

  // Shield Image
  const shieldTexture = new THREE.TextureLoader().load(gameStore.cdn + '/images/planet/shield.png');
  const shieldGeometry = new THREE.PlaneGeometry( 54, 54 );
  const shieldMaterial = new THREE.MeshBasicMaterial( { map: shieldTexture,transparent:true } );
  const shield = new THREE.Mesh( shieldGeometry, shieldMaterial );
  shield.position.x = 0;
  shield.position.y = 0;
  shield.position.z = 0.10;
  //set opacity
  shieldMaterial.opacity = object.shields/100;

  planet.add(shield);

  // Store shield material in the sphere object for later reference
  planet.shieldMaterial = shieldMaterial;

  // Define shields property with getter and setter
  Object.defineProperty(planet, 'shields', {
    get: function () {
      return this._shields;
    },
    set: function (value) {
      this._shields = value;
      this.shieldMaterial.opacity = value / 100;
    }
  });

  renderer.render(scene, camera);
}


</script>

<template>

  <LargeWideModel title="BattleReport">
    <div class="flex text-xs sm:text-sm">
      <div class="w-1/2 sm:w-3/12">
        <div class="content-header">
          <PlayerName :playerId="attackingPlayerId" />
        </div>
        <table class="w-full">
          <tbody>
            <tr>
              <td class="pl-2 pt-2 text-subtext">Total Ships</td>
              <td class="pr-2 pt-2 text-white text-right">{{ attackingTotalShips }}</td>
            </tr>
            <tr>
              <td class="pl-2 pt-2 text-subtext">Ships Destroyed</td>
              <td class="pr-2 pt-2 text-red-500 text-right">{{ attackingShipsDestroyed }}</td>
            </tr>
            <tr>
              <td class="pl-2 pt-2 pb-2 text-subtext">Ships Left</td>
              <td class="pr-2 pt-2 pb-2 text-green-500 text-right">{{ (attackingTotalShips - attackingShipsDestroyed) }}</td>
            </tr>
            <tr>
              <td colspan="2" class="bg-slate-700"></td>
            </tr>
            <tr>
              <td class="pl-2 pt-2 text-subtext">Damage dealt Fleet vs Fleet</td>
              <td class="pr-2 pt-2 text-green-500 text-right">{{ formatNumber(defendingDamageTakenFleetVsFleet) }}</td>
            </tr>
            <tr v-if="planetName">
              <td class="pl-2 pt-2 text-subtext">Damage dealt Fleet vs Planet</td>
              <td class="pr-2 pt-2 text-green-500 text-right">{{ formatNumber(defendingDamageTakenFleetVsPlanet) }}</td>
            </tr>
            <tr>
              <td class="pl-2 pt-2 pb-2 text-subtext">Total Damage</td>
              <td class="pr-2 pt-2 pb-2 text-green-500 text-right">{{ formatNumber(defendingDamageTakenFleetVsFleet + defendingDamageTakenFleetVsPlanet) }}</td>
            </tr>
            <tr>
              <td colspan="2" class="bg-slate-700"></td>
            </tr>
            <tr>
              <td class="pl-2 pt-2 text-subtext">Damage taken Fleet vs Fleet</td>
              <td class="pr-2 pt-2 text-red-500 text-right">{{ formatNumber(attackingDamageTakenFleetVsFleet) }}</td>
            </tr>
            <tr v-if="planetName">
              <td class="pl-2 pt-2 text-subtext">Damage taken Planet vs Fleet</td>
              <td class="pr-2 pt-2 text-red-500 text-right">{{ formatNumber(attackingDamageTakenPlanetVsFleet) }}</td>
            </tr>
            <tr>
              <td class="pl-2 pt-2 pb-2 text-subtext">Total damage taken</td>
              <td class="pr-2 pt-2 pb-2 text-red-500 text-right">{{ formatNumber(attackingDamageTakenFleetVsFleet + attackingDamageTakenPlanetVsFleet) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="hidden sm:block sm:w-6/12">
        <div class="flex absolute w-6/12 justify-center">
            <div v-if="!battleComplete" class="text-center text-sm pt-2" >
              <StandardButton @click="animationSpeed = 100" :class="{'bg-slate-800': animationSpeed === 100}">1x</StandardButton>
              <StandardButton @click="animationSpeed = 50" :class="{'bg-slate-800': animationSpeed === 50}">5x</StandardButton>
              <StandardButton @click="animationSpeed = 10" :class="{'bg-slate-800': animationSpeed === 10}">10x</StandardButton>
              <StandardButton @click="animationSpeed = 0" :class="{'bg-slate-800': animationSpeed === 0}">Max</StandardButton>
            </div>
            <div v-else>
              <div v-if="battleWinner" class="text-green-500 text-2xl">Victory!</div>
              <div v-else class="text-red-500 text-2xl">Defeat</div>
            </div>
        </div>
        <div class="battleScene z-40" ref="brContainer"></div>
      </div>

      <div class="w-1/2 sm:w-3/12">
        <div class="content-header">
          <PlayerName :playerId="defendingPlayerId" />
        </div>
        <table class="w-full">
          <tbody>
          <tr>
            <td class="pl-2 pt-2 text-subtext">Total Ships</td>
            <td class="pr-2 pt-2 text-white text-right">{{ defendingTotalShips }}</td>
          </tr>
          <tr>
            <td class="pl-2 pt-2 text-subtext">Ships Destroyed</td>
            <td class="pr-2 pt-2 text-red-500 text-right">{{ defendingShipsDestroyed }}</td>
          </tr>
          <tr>
            <td class="pl-2 pt-2 pb-2 text-subtext">Ships Left</td>
            <td class="pr-2 pt-2 pb-2 text-green-500 text-right">{{ (defendingTotalShips - defendingShipsDestroyed) }}</td>
          </tr>
          <tr>
            <td colspan="2" class="bg-slate-700"></td>
          </tr>
          <tr v-if="planetName">
            <td class="pl-2 pt-2 text-subtext">Planet</td>
            <td class="pr-2 pt-2 text-white text-right">{{ planetName }}</td>
          </tr>
          <tr v-if="planetName">
            <td class="pl-2 pt-2 text-subtext">Shields</td>
            <td class="pr-2 pt-2 text-white text-right">{{ defendingPlanetShields < 0?0:defendingPlanetShields }}%</td>
          </tr>
          <tr v-if="planetName">
            <td class="pl-2 pt-2 text-subtext">Troops</td>
            <td class="pr-2 pt-2 text-white text-right">{{ defendingPlanetTroops < 0?0:defendingPlanetTroops }}%</td>
          </tr>
          <tr v-if="planetName">
            <td class="pl-2 pt-2 text-subtext">Buildings Destroyed</td>
            <td class="pr-2 pt-2 text-red-500 text-right">{{ planetBuildingsDestroyed < 0?0:planetBuildingsDestroyed }}</td>
          </tr>
          <tr v-if="planetName">
            <td class="pl-2 pt-2 pb-2 text-subtext">Population Killed</td>
            <td class="pr-2 pt-2 pb-2 text-red-500 text-right">{{ formatNumber(planetPopulationKilled * 1000000000) }}</td>
          </tr>
          <tr>
            <td colspan="2" class="bg-slate-700"></td>
          </tr>
          <tr>
            <td class="pl-2 pt-2 text-subtext">Damage dealt Fleet vs Fleet</td>
            <td class="pr-2 pt-2 text-green-500 text-right">{{ formatNumber(attackingDamageTakenFleetVsFleet) }}</td>
          </tr>
          <tr v-if="planetName">
            <td class="pl-2 pt-2 text-subtext">Damage dealt Planet vs Fleet</td>
            <td class="pr-2 pt-2 text-green-500 text-right">{{ formatNumber(attackingDamageTakenPlanetVsFleet) }}</td>
          </tr>
          <tr>
            <td class="pl-2 pt-2 pb-2 text-subtext">Total Damage dealt</td>
            <td class="pr-2 pt-2 pb-2 text-green-500 text-right">{{ formatNumber(attackingDamageTakenFleetVsFleet + attackingDamageTakenPlanetVsFleet) }}</td>
          </tr>
          <tr>
            <td colspan="2" class="bg-slate-700"></td>
          </tr>
          <tr>
            <td class="pl-2 pt-2 text-subtext">Damage taken Fleet vs Fleet</td>
            <td class="pr-2 pt-2 text-red-500 text-right">{{ formatNumber(defendingDamageTakenFleetVsFleet) }}</td>
          </tr>
          <tr v-if="planetName">
            <td class="pl-2 pt-2 text-subtext">Damage taken Fleet vs Planet</td>
            <td class="pr-2 pt-2 text-red-500 text-right">{{ formatNumber(defendingDamageTakenFleetVsPlanet) }}</td>
          </tr>
          <tr>
            <td class="pl-2 pt-2 pb-2 text-subtext">Total damage taken</td>
            <td class="pr-2 pt-2 pb-2 text-red-500 text-right">{{ formatNumber(defendingDamageTakenFleetVsFleet + defendingDamageTakenFleetVsPlanet) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </LargeWideModel>

</template>

<style scoped lang="scss">
.battleScene {
  width:100%;
  height:30rem;
  background-image: url("https://cdn.galexion.com/images/map/bg75d.jpg");
}
</style>