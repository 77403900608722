import { usePlanetStore } from '@/stores/planetStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'

export default class Eta {

  private startX: number;
  private startY: number;

  constructor() {
    this.startX = 0;
    this.startY = 0;
  }

  start(x: number, y: number) {
    //console.log('Eta start', x, y);
    this.startX = x;
    this.startY = y;
  }

  etaNext(targetX: number, targetY: number, speed: number): number {
    //console.log('Eta etaNext',this.startX,this.startY, targetX, targetY, speed);

    // Calculate the distance between the current position and the target
    const distance = Math.sqrt(Math.pow(targetX - this.startX, 2) + Math.pow(targetY - this.startY, 2));

    // Calculate the ETA by dividing the distance by the speed
    const eta = distance / speed;

    // Store for next iteration
    this.startX = targetX;
    this.startY = targetY;

    // Optionally, round the ETA to a certain number of decimal places for display
    return Math.round((eta * 100) / 100); // Rounded to two decimal places
  }

  techETA(techPoints:number): number {
    const planetStore = usePlanetStore();
    const gameStore = useGameStore();
    //console.log('Eta techETA',techPoints);
    // Get current science output
    const scienceOutput = planetStore.getTotalScienceOutput()
    // Calculate the time to research the tech
    return Math.ceil((techPoints - (gameStore.player?.sciencePoints ?? 0)) / scienceOutput);
  }
}
