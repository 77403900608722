<script setup lang="ts">

import {computed, defineProps, onMounted, ref, watch} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import Backend from "@/models/backend";
import PlanetTaxSetting from "@/components/Sections/Planets/PlanetTaxSetting.vue";
import { useGameStore } from '@/stores/gameStore'


const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()
const gameStore = useGameStore()

const planetData = computed(() => planetStore.findPlanetById(props.planetId))

// Initialize slider values
const foodSlider = ref();
const scienceSlider = ref();
const productionSlider = ref();

const backend = new Backend()

// Function to update sliders based on the current planet data
const updateSlidersForPlanet = () => {
  if (planetData.value) {
    foodSlider.value = planetData.value.populationFoodPercentage || 0;
    scienceSlider.value = planetData.value.populationSciencePercentage || 0;
    productionSlider.value = planetData.value.populationProductionPercentage || 0;
  } else {
    // Reset sliders or handle the case when no planet data is found
    foodSlider.value = 0;
    scienceSlider.value = 0;
    productionSlider.value = 0;
  }
};

// Watch for changes in planetId and update sliders accordingly
watch(() => props.planetId, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateSlidersForPlanet();
  }
}, { immediate: true }); // immediate: true ensures this runs on mount as well

onMounted(() => {
  updateSlidersForPlanet(); // Update sliders when the component mounts
});

// Watch for changes in any slider and adjust the others accordingly
const adjustSliders = () => {
  // Send the values to the backend
  backend.updatePlanetPopulation([props.planetId], Math.floor(foodSlider.value), Math.floor(scienceSlider.value), Math.floor(productionSlider.value));
}


</script>

<template>
  <div class="xl:flex justify-between pl-2 pr-2">
    <div class="flex-1 p-2 flex">
      <img class="w-10 h-10 mr-3 rounded-3xl" :src="'' + gameStore.cdn + 'images/population/64/food.webp'" alt="Farmers" title="Farmers">
      <div class="relative mb-6 flex-grow">
        <label for="labels-range-input" class="sr-only">Labels range</label>
        <input
            id="population_food"
            @input="adjustSliders()"
            min="0"
            max="100"
            step="1"
            v-model="foodSlider"
            type="range"
            class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
        <span class="text-subtext absolute start-0 -bottom-6">Farmers: <span class="text-white">{{ planetData.populationWorkingOnFood }}</span></span>
      </div>
    </div>
    <div class="flex-1 p-2 flex">
      <img class="w-10 h-10 mr-3 rounded-3xl" :src="'' + gameStore.cdn + 'images/population/64/production.webp'" alt="Workers" title="Workers">
      <div class="relative mb-6 flex-grow">
        <label for="labels-range-input" class="sr-only">Labels range</label>
        <input
            id="population_production"
            @input="adjustSliders()"
            min="0"
            max="100"
            step="1"
            v-model="productionSlider"
            type="range"
            class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
        <span class="text-subtext absolute start-0 -bottom-6">Workers: <span class="text-white">{{ planetData.populationWorkingOnProduction }}</span></span>
      </div>
    </div>
    <div class="flex-1 p-2 flex">
      <img class="w-10 h-10 mr-3 rounded-3xl" :src="'' + gameStore.cdn + 'images/population/64/research.webp'" alt="Scientists" title="Scientists">
      <div class="relative mb-6 flex-grow">
        <label for="labels-range-input" class="sr-only">Labels range</label>
        <input
            id="population_science"
            @input="adjustSliders()"
            min="0"
            max="100"
            step="1"
            v-model="scienceSlider"
            type="range"
            class="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700">
        <span class="text-subtext absolute start-0 -bottom-6 whitespace-nowrap">Scientists: <span class="text-white">{{ planetData.populationWorkingOnScience }}</span></span>
      </div>
    </div>
    <div class="flex-1 p-2 flex">
      <PlanetTaxSetting :planetId="planetData.id"></PlanetTaxSetting>
    </div>
  </div>

</template>

<style scoped lang="scss">
.planetHeader{
  height: 200px;
  background-size: cover;
  background-position: top;
}
.table-td{
  height: 50px !important;
  width: 50px;
}
</style>
