<script setup lang="ts">

import { usePlayerCreditStore } from '@/stores/playerCreditStore.ts'

const playerCreditStore = usePlayerCreditStore()

</script>

<template>
{{ playerCreditStore.playerCreditBalanceSheet }}
</template>

<style scoped lang="scss">

</style>