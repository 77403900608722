<script setup lang="ts">
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {reactive} from "vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Backend from '@/models/backend.ts'
import FormProcess from '@/models/formProcess.ts'
import FormSubmit from "@/components/Interface/Modal/Forms/FormSubmit.vue";
import FormGenericError from '@/components/Interface/Modal/Forms/FormGenericError.vue'

// Get the planet data
const gameStore = useGameStore()

const backend = new Backend()
const formProcess = new FormProcess()

// Form errors
const formErrors = reactive<{ [key: string]: string }>({
  generic: ''
});


// Handle form submission
const submit = () => {

  formProcess.resetFormErrors(formErrors)

  backend.abandonPlanet(gameStore.modalData.planetId)
        .then(() => formProcess.processBackendSuccessResponse())
        .catch(error => formProcess.processBackendErrorResponse(error, formErrors))

}

</script>

<template>
  <SmallModel title="Abandon">
    <form method="get" @submit.prevent="submit" class="pl-4 pr-4 pt-4">
      <p class="pb-4 text-subtext">
        Are you sure you want to abandon this planet?<br>
        <br>
        The current population will be scattered, your buildings and resources will be lost.
        Other players will be able to colonize this planet.
      </p>
      <FormGenericError :error="formErrors.generic"/>
      <div class="flex justify-center">
        <FormSubmit text="Abandon" color="red"/>
      </div>
    </form>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>