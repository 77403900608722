<script setup lang="ts">

import { onMounted } from 'vue'
import {useShipStore} from "@/stores/shipStore.ts";
import router from '@/router'
import { useRoute } from 'vue-router'
import ShipsList from '@/components/Sections/Command/Ships/ShipsList.vue'
import { useTableStore } from '@/stores/tableStore.ts'

const tableStore = useTableStore();
const shipStore = useShipStore();
const route = useRoute();

onMounted(() => {
  if(Number(route.params.shipId) > 0) {
    // Load planet details from URL
    tableStore.ships.singleSelectedId = Number(route.params.shipId);
  } else if(tableStore.ships.singleSelectedId > 0) {
    // Load from last selected planet
    //only when we are on portrait mode mobile
    if (window.innerWidth > 500) {
      router.push('/command/ships/' + tableStore.ships.singleSelectedId);
    }
  } else {
    // Load first planet from list
    if (shipStore.ships.size > 0 && window.innerWidth > 500) {
      router.push('/command/ships/' + shipStore.ships.values().next().value.id);
    }
  }
});


</script>

<template>
  <div class="bg-slate-900 content-height-limit-tab overflow-y-auto">
    <div class="flex flex-col md:flex-row">
      <div :class="{
          'w-full md:w-3/5 px-1' : true,
          'hidden sm:block' : Number(route.params.shipId) > 0
        }">
        <div class="pt-1">
          <ShipsList></ShipsList>
        </div>
      </div>
      <div class="w-full md:w-2/5 p-1 mt-0 sm:content-height-limit-tab">
        <div class="bg-slate-900 rounded text-white">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
<!--  <div class="bg-slate-900">-->
<!--    <div class="flex flex-col md:flex-row">-->
<!--      <div class="w-full md:w-3/5">-->
<!--        <div class="content-height-limit-tab pt-1 pb-1">-->
<!--          <ShipsList></ShipsList>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="w-full md:w-2/5 content-height-limit-tab pl-1 pt-1 pr-1">-->
<!--        <div class="bg-slate-850 rounded text-white">-->
<!--          <router-view></router-view>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<style scoped>

</style>