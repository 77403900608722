<script setup>
import { defineEmits, defineProps } from 'vue';

const props = defineProps({
  column: {
    type: String,
    required: true,
  },
  sortColumn: {
    type: String,
    required: true,
  },
  sortDirection: {
    type: String,
    required: true,
  },
  minWidth: {
    type: String,
    required: true,
  }
});

const emit = defineEmits(['sort']);

const emitSort = () => {
  emit('sort', props.column);
};
</script>

<template>
  <div
    :style="{ minWidth: minWidth + 'rem' }"
    :class="[
      'cursor-pointer bg-slate-700 text-slate-400 font-bold text-xs border-b border-t pt-2.5 flex-1',
      sortColumn !== column ? 'border-b-slate-700 border-t-slate-700' : '',
      sortColumn === column && sortDirection === 'asc' ? 'border-b-slate-400 border-t-slate-700' : '',
      sortColumn === column && sortDirection === 'desc' ? 'border-t-slate-400 border-b-slate-700' : ''
    ]"
    @click="emitSort"
  >
    <slot></slot>
  </div>
</template>
