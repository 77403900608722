<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="gameStore.cdn + 'images/news/blackhole.png'"
    :toast-text="props.newsItem.contentData.ships + ' ship(s) were lost.'"
  >
    <NewsItemBaseStructure
      :newsItem="props.newsItem"
      :item-image="gameStore.cdn + 'images/news/blackhole.png'"
      :header="'Ships lost!'"
    >
      {{ props.newsItem.contentData.ships }} of your ships ventured too close to the supermassive black hole at the galaxy's center. They have been lost forever.<br>
    </NewsItemBaseStructure>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>