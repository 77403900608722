import * as THREE from 'three';
import { zoomFactor } from '@/enums/zoomFactor.ts'
import { useMapStore } from '@/stores/mapStore.ts'

/**
 * Draw a crossHair in the center of the screen
 */
export class MapCrossHairInit {
  constructor(scene: THREE.Scene, camera: THREE.PerspectiveCamera) {

    const crossHairGroup = new THREE.Group();
    crossHairGroup.name = 'crossHair';
    crossHairGroup.position.set(0, 0, -100);
    camera.add(crossHairGroup);
    scene.add(camera);

    // Initial zoom level setup
    new MapCrossHairUpdate(scene, 1);
  }
}

/**
 * Update the crossHair based on the zoom level
 */
export class MapCrossHairUpdate {
  constructor(scene: THREE.Scene,zoomLevel: number) {
    // Find the crossHair group by name
    const crossHairGroup = scene.getObjectByName('crossHair') as THREE.Group;

    if (!crossHairGroup) {
      console.error('CrossHair group not found');
      return;
    }

    const mapStore = useMapStore();

    if(!mapStore.crossHairVisible) {
      crossHairGroup.visible = false;
    } else {

      // CrossHair properties based on zoom level
      const crossHairProperties = [
        { zoomF: zoomFactor.Planet, size: 1, gap: 0.5, thickness: 0.02, color: 0x111111 },  // Zoom Level 1
        { zoomF: zoomFactor.Solar, size: 1, gap: 0.5, thickness: 0.03, color: 0x222222 }, // Zoom Level 2
        { zoomF: zoomFactor.Local, size: 2, gap: 1, thickness: 0.1, color: 0x333333 },  // Zoom Level 3
        { zoomF: zoomFactor.Sector, size: 4, gap: 2.5, thickness: 0.25, color: 0x444444 }, // Zoom Level 4
        { zoomF: zoomFactor.Quadrant, size: 10, gap: 5, thickness: 0.5, color: 0x444444 },  // Zoom Level 5
        { zoomF: zoomFactor.Max, size: 15, gap: 7, thickness: 1, color: 0x444444 }  // Zoom Level 6
      ];


      const getCrossHairProperties = (zoomLevel: number): {
        zoomF: number,
        size: number,
        gap: number,
        thickness: number,
        color: number
      } => {
        const properties = crossHairProperties.find(prop => prop.zoomF === zoomLevel);

        if (!properties) {
          console.error('Invalid zoom level');
          return getCrossHairProperties(zoomFactor.Planet);
        }

        return properties;
      };
      const { size, gap, thickness, color } = getCrossHairProperties(zoomLevel);

      // Clear existing crossHair components
      crossHairGroup.clear();

      const crossHairMaterial = new THREE.MeshBasicMaterial({
        color: color,
        depthTest: false, // Ensure crossHair stays visible in front of everything
      });


      // Create updated crossHair geometries and add them to the group
      const hLineLeftGeometry = new THREE.PlaneGeometry(size - gap, thickness);
      const hLineLeft = new THREE.Mesh(hLineLeftGeometry, crossHairMaterial);
      hLineLeft.position.set(-(size + gap) / 2, 0, 0);
      crossHairGroup.add(hLineLeft);

      const hLineRightGeometry = new THREE.PlaneGeometry(size - gap, thickness);
      const hLineRight = new THREE.Mesh(hLineRightGeometry, crossHairMaterial);
      hLineRight.position.set((size + gap) / 2, 0, 0);
      crossHairGroup.add(hLineRight);

      const vLineTopGeometry = new THREE.PlaneGeometry(thickness, size - gap);
      const vLineTop = new THREE.Mesh(vLineTopGeometry, crossHairMaterial);
      vLineTop.position.set(0, (size + gap) / 2, 0);
      crossHairGroup.add(vLineTop);

      const vLineBottomGeometry = new THREE.PlaneGeometry(thickness, size - gap);
      const vLineBottom = new THREE.Mesh(vLineBottomGeometry, crossHairMaterial);
      vLineBottom.position.set(0, -(size + gap) / 2, 0);
      crossHairGroup.add(vLineBottom);

      crossHairGroup.visible = true;
    }
  }
}
