<script setup lang="ts">


const tabs = [
  {
    'id': 'cabinet',
    'name': 'Cabinet',
    'target': '/government/cabinet',
    'activeKey': 'GovernmentCabinet'
  },
  {
    'id': 'finances',
    'name': 'Finances',
    'target': '/government/finances',
    'activeKey': 'GovernmentFinances'
  }
]

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <router-link
          v-for="tab in tabs"
          :key="tab.name"
          :to="tab.target"
          :class="{
            'active text-blue-500 border-blue-500': $route.name?.toString().startsWith(tab.activeKey),
            'text-gray-500 border-transparent': !$route.name?.toString().startsWith(tab.activeKey),
            'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
          }"
        >
          {{ tab.name }}
        </router-link>
      </li>
    </ul>
  </div>
  <router-view></router-view>
</template>

<style scoped lang="scss">

</style>