<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import { useMapStore } from '@/stores/mapStore.ts';
import PlayerName from '@/components/Objects/PlayerName.vue';

const mapStore = useMapStore();

// Function to update mouse position in the store
function updateMousePosition(event: MouseEvent) {
  mapStore.playerNameTooltipXPos = event.clientX;
  mapStore.playerNameTooltipYPos = event.clientY;
}

onMounted(() => {
  window.addEventListener('mousemove', updateMousePosition);
});

onUnmounted(() => {
  window.removeEventListener('mousemove', updateMousePosition);
});
</script>

<template>
  <div
    v-show="mapStore.playerNameTooltipVisible"
    :style="{
      left: mapStore.playerNameTooltipXPos + 'px',
      top: mapStore.playerNameTooltipYPos - tooltipOffset + 'px',
    }"
    class="tooltip bg-gray-900 z-20 px-1 rounded"
  >
    <PlayerName :player-id="Number(mapStore.playerNameTooltipOwnerId)" /> <span v-if="mapStore.playerNameTooltipShipCount>0">: {{ mapStore.playerNameTooltipShipCount }}</span>
  </div>
</template>

<script lang="ts">
const tooltipOffset = 25; // Adjust this value based on your tooltip's height
</script>

<style scoped lang="scss">
.tooltip {
  position: absolute;
  /* Add any additional styling you need */
}
</style>
