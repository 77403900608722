<script setup lang="ts">
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Backend from '@/models/backend.ts'
import IconCount from "@/components/Objects/IconCount.vue";
import Icon from "@/components/Objects/Icons/Icon.vue";
import {useShipDesignStore} from "@/stores/shipDesignStore.ts";
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { ref } from 'vue'
import { useFleetStore } from '@/stores/fleetStore.ts'

// Get the planet data
const gameStore = useGameStore()
const shipDesignStore = useShipDesignStore()
const fleetStore = useFleetStore()

const backend = new Backend()

const targetFleetId = ref(0)
const clearProductionQueue = ref(false)


// Handle form submission
const setProductionObject = (designId:number, count:number) => {
  backend.setPlanetProductionObjectShip(gameStore.modalData.planetIds, designId, count,targetFleetId.value,clearProductionQueue.value);
  if(gameStore.modalData.planetIds.length > 1) {
    gameStore.setTinySuccessToastMessage('Ship(s) added to production queue on multiple planets');
  } else {
    gameStore.setTinySuccessToastMessage('Ship(s) added to production queue');
  }
}

</script>

<template>
  <SmallModel title="Build ship">
    <div class="">
      <div style="max-height: 25rem;" class="overflow-y-scroll">
        <table class="w-full" >
          <tbody>
          <template v-for="design in shipDesignStore.playerShipDesigns.values()" :key="design.id">
            <tr v-if="!design.deleted"
                class="">
              <td class="px-2 py-3 text-white text-left">
                {{ design.name }}
              </td>
              <td class="px-2 py-3 text-right text-subtext">
                {{ design.typePretty }}
              </td>
              <td class="px-2 py-3 text-center">
                <Icon type="production" class="pr-1"></Icon><IconCount :amount="design.productionCost" :no-plus="true"/>
              </td>
              <td class="px-2 py-3 text-right">
                <StandardButton @click="setProductionObject(design.id,1)">Build</StandardButton>
                <StandardButton color="darker" @click="setProductionObject(design.id,10)">x10</StandardButton>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
      <div class="content-header">Options</div>
      <div class="pt-2 text-subtext">
        <div class="pb-2 flex justify-center">
          <div class="flex items-center">Automatically assign ship to</div>
          <div class="ps-2">
            <select v-model="targetFleetId">
              <option value="0">No Fleet</option>
              <template v-for="fleet in fleetStore.fleets.values()" :key="fleet.id">
                <option :value="fleet.id">{{ fleet.name}}</option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <div class="flex justify-center pb-2 text-subtext">
        <div class="flex items-center pr-2">Clear current production & queue</div>
        <label class="inline-flex items-center cursor-pointer">
          <input type="checkbox" v-model="clearProductionQueue" class="sr-only peer">
          <div class="relative w-9 h-5 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-800 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
      </div>
    </div>
  </SmallModel>
</template>


<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 text-slate-400 focus:outline-none
}
</style>