<script setup lang="ts">
// Import defineProps to define the component props
import {computed, defineProps, onMounted, ref, watch} from 'vue';
import ToolTip from "../../../../../../../galexion/frontend-web/galexion/src/components/Interface/ToolTip/ToolTip.vue";
import {usePlanetStore} from "@/stores/planetStore";
import Icon from "../../../../../../../galexion/frontend-web/galexion/src/components/Objects/Icons/Icon.vue";
import { formatShort } from '@/models/formatNumber.ts'


// Define the props your component expects
const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()

const planetData = computed(() => planetStore.findPlanetById(props.planetId))

const url = ref('');
const amount = ref(0);

onMounted(() => {
  updateAmount()
});

watch(() => planetData.value.timestamp, (newValue) => {
  if(newValue) {
      updateAmount()
  }
});

//update function
const updateAmount = () => {
  if(planetData.value) {
      amount.value = planetData.value.oreStorageCapacity || 0;
    url.value = 'planet/' + planetData.value.id + '/ore-capacity';
  }
};

</script>

<template>
  <tool-tip :url="url">
    <span class="p-0 pe-3 text-secondary">
     <Icon type="ore_storage" class="pr-1"></Icon>
     {{ formatShort(amount) }}
    </span>
  </tool-tip>
</template>

<style scoped>
img {
  width: 20px;
  height: 20px;
}
</style>
