<script setup lang="ts">

import Icon from '@/components/Objects/Icons/Icon.vue'
import { usePQAStore } from '@/stores/PQAStore.ts'

const PQAStore = usePQAStore()

const props = defineProps({
  type: String
})

</script>

<template>
  <Icon :type="props.type" :size="4" :class="
  {
    'pl-2 cursor-pointer':true,
    'grayscale':PQAStore.filters[props.type] === false,
  }"
  @click="PQAStore.toggleFilter(props.type)"
  />
</template>

<style scoped lang="scss">

</style>