<script setup lang="ts">

import { computed } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'

import ShipCurrentActions
  from '@/components/Sections/Command/Ships/ShipDetails/ShipDetailTabs/ShipActions/Current/ShipCurrentActions.vue'

import {useShipActionStore} from "@/stores/shipActionStore.ts";
import Backend from '@/models/backend.ts'
import router from "@/router";
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import Eta from '@/models/eta.ts'

const shipStore = useShipStore()
const shipActionStore = useShipActionStore()
const eta = new Eta();

const props = defineProps({
  shipId: Number
});

const shipData = computed(() => shipStore.findShipById(props.shipId ?? 0))

// A computed property to calculate eta for each action
const calculateEtas = computed(() => {
  if (!shipData.value || !shipData.value.actions.length) return [];

  eta.start(shipData.value?.xPos, shipData.value?.yPos);

  return shipData.value.actions.map((action: any) => {
    if (action.targetX !== undefined && action.targetY !== undefined) {
      // Use the Eta model to calculate the ETA for each action
      if(shipData.value) {
        return eta.etaNext(action.targetX, action.targetY, shipData.value.maxSpeed);
      }
    }
    return 0;
  });
});

// Function to initialize ship actions
const initShipAction = () => {
  router.push('/map/galaxy/')
  if(shipData.value) {
    shipActionStore.initShip(shipData.value)
  }
}

// Function to clear ship actions
const clearShipAction = () => {
  const backend = new Backend();
  backend.clearShipActions([shipData.value]);
}

</script>

<template>
  <div class="content-header mt-1">
    <div class="flex justify-between">
      <div>Action Queue</div>
      <div>
        <StandardButton @click="clearShipAction">Clear</StandardButton>
        <StandardButton @click="initShipAction">Set</StandardButton>
      </div>
    </div>
  </div>

  <div v-if="shipData?.actions.length === 0" class="text-center pt-4">
    <div class="pt-1 pb-3 text-subtext">Your ship is doing nothing</div>
    <StandardButton @click="initShipAction">Set an action</StandardButton>
  </div>

  <div v-if="shipData">
    <div v-for="(action, index) in shipData.actions" :key="index">
      <!-- Pass the specific ETA for each action from calculateEtas -->
      <ShipCurrentActions :shipActionData="action" :eta="calculateEtas[index]" />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>