import { defineStore } from 'pinia'
import { markRaw } from 'vue'
import * as THREE from 'three'
import { zoomFactor } from '@/enums/zoomFactor.ts'

export const useMapPendingSettleStore = defineStore('mapPendingSettleStore', {
    state: () => ({
        objectInstancedMesh: null as THREE.InstancedMesh<THREE.CircleGeometry, THREE.ShaderMaterial> | null,
        objectAvailableInstanceIds: markRaw<number[]>([]),
        objectMapCoordinateLookup: markRaw(new Map())
    }),
    actions: {
        initObjectInstancedMesh(maxCount: number) {
            const geometry2 = new THREE.CircleGeometry(1.5, 32);
            const material2 = new THREE.ShaderMaterial({
                vertexShader: `
                    attribute vec3 instanceColor;
                    varying vec3 vColor;
                
                    void main() {
                      vColor = instanceColor;  // Pass the color to the fragment shader
                      vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
                      gl_Position = projectionMatrix * mvPosition;
                    }
                  `,
                fragmentShader: `
                    varying vec3 vColor;
                
                    void main() {
                      gl_FragColor = vec4(vColor, 1.0);  // Use the passed color for the fragment
                    }
                  `,
                vertexColors: true,
            });

            const colors = new Float32Array(maxCount * 3);  // 3 values per instance (r, g, b)
            geometry2.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(colors, 3));

            this.objectInstancedMesh = markRaw(new THREE.InstancedMesh(geometry2, material2, maxCount));

            const dummy = new THREE.Object3D();

            for(let i = 0; i < maxCount; ++i) {
                dummy.position.set(-10000, -10000, -10000);  // Out of visible range
                dummy.updateMatrix();
                this.objectInstancedMesh.setMatrixAt(i, dummy.matrix);
                this.objectAvailableInstanceIds.push(i);
            }

            this.objectInstancedMesh.instanceMatrix.needsUpdate = true;

            return this.objectInstancedMesh
        },
        addObject(x:number,y:number, color:number): number {
            const instanceId = this.getAvailableObjectInstanceId();
            if (instanceId !== null && instanceId !== undefined) {
                // Reuse the same dummy Object3D instance
                const dummy = new THREE.Object3D();  // Or reuse a global dummy object

                // Set the new position for the instance
                dummy.position.set(x, y, 0.09);
                dummy.scale.set(1, 1, 1);  // Apply uniform scaling
                dummy.updateMatrix();

                // Apply the matrix to the instanced mesh at the specific instance ID
                if(this.objectInstancedMesh) {
                    this.objectInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

                    const colorAttribute = this.objectInstancedMesh.geometry.attributes.instanceColor;
                    if (colorAttribute) {
                        // Update the color buffer with the new color
                        const color2 = new THREE.Color(color);
                        colorAttribute.setXYZ(instanceId, color2.r, color2.g, color2.b);  // Assuming color is a THREE.Color object
                        colorAttribute.needsUpdate = true;
                    }

                    // Mark the instance matrix for update
                    this.objectInstancedMesh.instanceMatrix.needsUpdate = true;

                    // Store the instanceId for the mapCoordinate (so we can reverse lookup it later)
                    this.objectMapCoordinateLookup.set(instanceId, { x: x, y: y });
                    return instanceId
                }
            }
            return 0
        },
        clearObjects() {
            if(this.objectInstancedMesh) {
                for (let i = 0; i < this.objectInstancedMesh.count; ++i) {
                    this.removeObject(i);
                }
            }
        },
        removeObject(instanceId: number) {
            if(this.objectInstancedMesh) {
                const dummy = new THREE.Object3D();
                dummy.position.set(-10000, -10000, -10000);  // Out of visible range
                dummy.updateMatrix();
                this.objectInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                this.releaseObjectInstanceId(instanceId);
                this.objectInstancedMesh.instanceMatrix.needsUpdate = true;
            }
        },
        getAvailableObjectInstanceId(): number|undefined {
            if(this.objectAvailableInstanceIds.length === 0) {
                alert('No more available instance ids for pending Settle objects');
            }
            return this.objectAvailableInstanceIds.pop()
        },
        releaseObjectInstanceId(instanceId: number) {
            this.objectAvailableInstanceIds.push(instanceId)
            this.objectMapCoordinateLookup.delete(instanceId);
        },
        scaleAndPositionObjects(zoom: number) {
            if(this.objectInstancedMesh) {
                for (let i = 0; i < this.objectInstancedMesh.count; ++i) {
                    const mapCoordinate = this.findCoordinatesByInstanceId(i);
                    if (mapCoordinate.x > 0 || mapCoordinate.y > 0) {
                        this.scaleAndPositionObject(i, mapCoordinate.x, mapCoordinate.y, zoom);
                    }
                }
            }
        },
        scaleAndPositionObject(instanceId: number, xPos:number,yPos:number, zoom: number) {
            if(this.objectInstancedMesh) {
                const dummy = new THREE.Object3D();
                let x = 0;
                let y = 0;
                let scale = 0;
                if (zoom === zoomFactor.Planet) {
                    x = xPos + 1;
                    y = yPos + 1;
                    scale = 0.25;
                } else if (zoom === zoomFactor.Solar) {
                    x = xPos + 1;
                    y = yPos + 1;
                    scale = 0.35;
                } else if (zoom === zoomFactor.Local) {
                    x = xPos;
                    y = yPos;
                    scale = 1.3;
                } else if (zoom === zoomFactor.Sector) {
                    x = xPos;
                    y = yPos;
                    scale = 2.2;
                } else {
                    x = xPos;
                    y = yPos;
                    scale = 3.5;
                }
                console.log('x: ', x, 'y: ', y, 'scale: ', scale)

                dummy.position.set(x, y, 0.09)  // You can modify the z position if needed
                dummy.scale.set(scale, scale, scale);  // Apply uniform scaling
                dummy.updateMatrix();
                this.objectInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

                this.objectInstancedMesh.instanceMatrix.needsUpdate = true;
            }
        },
        findCoordinatesByInstanceId(instanceId: number): { x: number, y: number } {
            if(this.objectMapCoordinateLookup.has(instanceId)) {
                return this.objectMapCoordinateLookup.get(instanceId);
            }
            return {x: 0, y: 0}
        }
    }
});