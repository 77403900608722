<script setup lang="ts">

import {computed, defineComponent, defineProps, ref, type Ref, watch} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import {useAssetStore} from "@/stores/assetStore";
import type Building from "@/types/Building";
import PlanetBuildingTabObject from "@/components/Sections/Planets/PlanetTabs/BuildingsTab/PlanetBuildingTabObject.vue";

/**
 * Planet buildings tab component
 */
defineComponent({
  name: 'PlanetBuildings',
})

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()
const assetStore = useAssetStore()

const planetData = computed(() => planetStore.findPlanetById(props.planetId))

const availableBuildings: Ref<Building[]> = ref([]);

/**
 * Calculate the available production objects for the current planet
 **/
const updateList = () => {

  availableBuildings.value = [];

  for(let i = 0; i < planetData.value.buildings.length; i++){
    let index = Number(i); // Explicit conversion, though redundant in this case
    const building = assetStore.findBuildingByCode(planetData.value.buildings[index]);
    if (!building) {
      throw new Error(`Building with ID ${planetData.value.buildings[index]} not found.`);
    }
    availableBuildings.value.push(building);
  }

}


// Watch for changes in planetId and update sliders accordingly
watch(() => planetData.value.timestamp, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    updateList();
  }
}, { immediate: true }); // immediate: true ensures this runs on mount as well


</script>

<template>
  <div class="grid lg:grid-cols-1 xl:grid-cols-2 gap-1 pt-1">
    <div v-for="building in availableBuildings" :key="building.buildingCode">
      <PlanetBuildingTabObject
          :planet-id="planetData.id"
          :building-code="building.buildingCode"
      />
    </div>
  </div>
  <div v-if="availableBuildings.length === 0">
    <div class="flex justify-center text-subtext p-10">Nothing was build yet</div>
  </div>
</template>

<style scoped lang="scss">

</style>
