import {useGameStore} from "@/stores/gameStore";
import {usePlanetStore} from "@/stores/planetStore";
import type PlayerPlanet from "@/types/PlayerPlanet";
import {useAssetStore} from "@/stores/assetStore";
import {useShipDesignStore} from "@/stores/shipDesignStore";
import type Ore from "@/types/Ore";
import type ProductionObject from "@/types/ProductionObject";
import type Player from "@/types/Player";
import type Building from "@/types/Building";
import type ShipComponent from "@/types/ShipComponent.ts";
import type PlayerShipDesign from "@/types/PlayerShipDesign.ts";
import type PlayerShip from "@/types/PlayerShip.ts";
import {useShipStore} from "@/stores/shipStore.ts";
import type Turn from "@/types/PublicRouter/Turn.ts";
import { useMapStore } from '@/stores/mapStore.ts'
import emitter from '@/models/eventBus.ts';
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import type NewsItem from "@/types/NewsItem.ts";
import {useNewsStore} from "@/stores/newsStore.ts";
import type Tech from '@/types/Tech.ts'
import type PlanetQueueAutomation from '@/types/PlanetQueueAutomation.ts'
import { usePQAStore } from '@/stores/PQAStore.ts'
import { useFleetStore } from '@/stores/fleetStore.ts'
import type Fleet from '@/types/Fleet.ts'
import type PlayerRelation from '@/types/PlayerRelation.ts'
import type MapCoordinate from '@/types/MapCoordinate.ts'
import type StatKey from '@/types/StatKey.ts'
import { useStatsStore } from '@/stores/statsStore.ts'
import type ShipGroup from '@/types/ShipGroup.ts'
import { useShipGroupStore } from '@/stores/shipGroupStore.ts'
import type Ministry from '@/types/Ministry.ts'
import type PlanetGroup from '@/types/PlanetGroup.ts'
import { usePlanetGroupStore } from '@/stores/planetGroupStore.ts'
import { usePlayerCreditStore } from '@/stores/playerCreditStore.ts'
import type PlayerCreditBalanceSheet from '@/types/PlayerCreditBalanceSheet.ts'

export default class PrivateRouter {

    private gameStore = useGameStore()
    private planetStore = usePlanetStore()
    private assetStore = useAssetStore()
    private shipDesignStore = useShipDesignStore()
    private shipStore = useShipStore()
    private shipGroupStore = useShipGroupStore()
    private planetGroupStore = usePlanetGroupStore()
    private fleetStore = useFleetStore()
    private mapStore = useMapStore()
    private playerRelationsStore = usePlayerRelationStore()
    private newsStore = useNewsStore()
    private pqaStore = usePQAStore()
    private statsStore = useStatsStore()
    private playerCreditsStore = usePlayerCreditStore()

    public route(event: string, data: Array<never>): void {
        console.log('Routing private event: ' + event)
        if(!this.gameStore.websocketInitialSyncCompleted) {
            this.gameStore.loadingText = event
        }
        switch (event) {
            case 'player_update':
                // Update Player Data
                this.updatePlayer(data)
                break;
            case 'player_ship_designs_update':
                this.updatePlayerShipDesigns(data)
                break;
            case 'player_ship_components_update':
                // Update Player Production Objects
                this.updatePlayerShipComponents(data)
                break;
            case 'player_production_objects_update':
                // Update Player Production Objects
                this.updatePlayerProductionObjects(data)
                break;
            case 'player_tech_update':
                // Update Player Owned Technologies
                this.updatePlayerTechs(data)
                break;
            case 'planet_update':
                // Sync planet data
                this.planetUpdate(data)
                break
            case 'pqa_update':
                // Planet Queue Automation
                this.pqaUpdate(data)
                break;
            case 'pqa_delete':
                // Planet Queue Automation
                this.pqaDelete(data)
                break;
            case 'planet_delete':
                // Delete planet
                this.planetDelete(data)
                break
            case 'ship_update':
                // Sync Ship Data
                this.shipUpdate(data)
                break
            case 'ship_delete':
                // Delete Ship
                this.shipDelete(data)
                break
            case 'ship_group_update':
                // Sync Ship Group Data
                this.shipGroupUpdate(data)
                break
            case 'ship_group_delete':
                // Delete Ship Group
                this.shipGroupDelete(data)
                break
            case 'planet_group_update':
                // Sync Planet Group Data
                this.planetGroupUpdate(data)
                break
            case 'planet_group_delete':
                // Delete Planet Group
                this.planetGroupDelete(data)
                break
            case 'fleet_update':
                // Sync Fleet Data
                this.fleetUpdate(data)
                break
            case 'fleet_delete':
                // Delete Fleet
                this.fleetDelete(data)
                break
            case 'game_state_sync_completed':
                // Sync Initial Client Data
                this.gameStateSync()
                break
            case 'ore_assets':
                // Sync Ore Assets
                this.oreAssets(data)
                break;
            case 'production_object_assets':
                // Sync Production Object Assets
                this.productionObjectAssets(data)
                break;
            case 'building_assets':
                // Sync Building Assets
                this.buildingAssets(data)
                break;
            case 'tech_assets':
                // Sync Tech Assets
                this.techAssets(data)
                break;
            case 'ship_component_assets':
                // Sync Ship Component Assets
                this.shipComponentAssets(data)
                break;
            case 'turn':
                // Sync Initial Map Data
                this.turn(data)
                break
            case 'map_coordinate_update':
                this.mapCoordinateUpdate(data)
                break
            case 'player_relations_update':
                // Update Player Relations
                this.updatePlayerRelations(data)
                break
            case 'planet_type_assets':
                // Sync Planet Type Assets
                this.updatePlanetTypeAssets(data)
                break
            case 'star_type_assets':
                // Sync Star Type Assets
                this.updateStarTypeAssets(data)
                break
            case 'game_config':
                this.gameConfig(data)
                break
            case 'news_item':
                this.addPlayerNewsItems(data)
                break
            case 'stats_assets':
                this.addStatsAssets(data)
                break
            case 'ministry_assets':
                this.addMinistryAssets(data)
                break
            case 'player_ministers_update':
                this.addPlayerMinisters(data)
                break
            case 'player_credits_update':
                this.updatePlayerCredits(data)
                break
            case 'request':
                this.processRequest(data)
                break
            default:
                console.log('Unknown event: ' + event)
                break
        }
    }

    private async updatePlayerCredits(data: PlayerCreditBalanceSheet): void {
        this.playerCreditsStore.playerCreditBalanceSheet = data
    }

    private async addPlayerMinisters(data: Array<string>): void {
        for (const minister of data) {
            this.gameStore.playerMinisters.set(minister, minister)
        }
    }

    private async addMinistryAssets(data: Ministry): void {
        this.assetStore.updateMinistryByCode(data.code, data)
    }

    private async processRequest(data: Array<any>):void
    {
        this.gameStore.clearPendingRequest(data.requestId)
    }

    private async addStatsAssets(data: Array<StatKey>): void {
        for (const item of data) {
            this.statsStore.statsKeys.set(item.key, item)
        }
    }

    private async addPlayerNewsItems(data: NewsItem): void {
        this.newsStore.add(data)
        this.newsStore.addToast(data)
    }

    private async updatePlanetTypeAssets(data: Array<never>): void {
        this.assetStore.updatePlanetTypeById(data.id, data)
    }

    private async updateStarTypeAssets(data: Array<never>): void {
        this.assetStore.updateStarTypeById(data.id, data)

    }

    private async updatePlayerRelations(data: PlayerRelation): void {
        //data is an array of objects
        console.log('TEST',data)
           this.playerRelationsStore.update(Number(data.playerId), data)
    }

    private async gameConfig(data: Array<never>): void {
        const eventData = data as unknown as {
            galaxyTileGridSize: number,
            sectorTileGridSize: number,
            turnInterval: number,
            shipTurnInterval: number,
            planetTurnInterval: number,
            playerTechTurnInterval: number,
            playerCreditsTurnInterval: number,
        }

        console.log('data',data)
        this.gameStore.galaxyTileGridSize = eventData.galaxyTileGridSize;
        this.gameStore.sectorTileGridSize = eventData.sectorTileGridSize;
        this.gameStore.turnInterval = eventData.turnInterval;
        this.gameStore.shipTurnInterval = eventData.shipTurnInterval;
        this.gameStore.planetTurnInterval = eventData.planetTurnInterval;
        this.gameStore.playerTechTurnInterval = eventData.playerTechTurnInterval;
        this.gameStore.playerCreditsTurnInterval = eventData.playerCreditsTurnInterval;

        // Calculate the sectors
        this.mapStore.populateSectors()
    }

    private async mapCoordinateUpdate(data: MapCoordinate): void {
       emitter.emit('mapCoordinateUpdate', data)
    }

    private async turn(data: Array<never>): void {
        const eventData = data as unknown as Turn
        this.gameStore.turn = eventData.turn
    }
    
    /**
     * Sync Production Object Assets
     *
     * @param productionObject
     * @private
     */
    private async productionObjectAssets(productionObject: ProductionObject): void {
        this.assetStore.updateProductionObjectById(productionObject.id, productionObject)
    }

    /**
     * Sync Ore Assets
     *
     * @param ore
     * @private
     */
    private async oreAssets(ore: Ore): void {
        this.assetStore.updateOreById(ore.id, ore)
    }

    /**
     * Sync Building Assets
     *
     * @param building
     * @private
     */
    private async buildingAssets(building: Building): void {
        this.assetStore.updateBuildingById(building.buildingCode, building)
    }

    /**
     * Sync Tech Assets
     *
     * @param tech
     * @private
     */
    private async techAssets(tech: Tech): void {
        this.assetStore.updateTechByCode(tech.id, tech)
    }

    /**
     * Sync Ship Component Assets
     */
    private async shipComponentAssets(shipComponent: ShipComponent): void {
        this.assetStore.updateShipComponentById(shipComponent.code, shipComponent)
    }

    /**
     * Sync Game State for the client
     *
     * @private
     */
    private async gameStateSync(): void {

        // We are now in sync
        this.gameStore.websocketInitialSyncCompleted = true
        console.log('Game State Synced')
    }

    /**
     * Update Player Data
     * @param data
     * @private
     */
    private async updatePlayer(data: unknown): void {
        this.gameStore.player = data as unknown as Player;
    }

    /**
     * Update Player Ship Designs
     *
     * @param data
     * @private
     */
    private async updatePlayerShipDesigns(data: PlayerShipDesign): void {
        this.shipDesignStore.playerShipDesigns.set(data.id, data);
    }

    /**
     * Update Player Production Objects
     *
     * @param data
     * @private
     */
    private async updatePlayerShipComponents(data: Any): void {
         this.gameStore.playerShipComponents.set(data.id,data.id)
    }

    /**
     * Update Player Production Objects
     *
     * @param data
     * @private
     */
    private async updatePlayerProductionObjects(data: any): void {
        this.gameStore.playerProductionObjects.set(data.id, data.id)
    }

    /**
     * Update Player Owned Technologies
     *
     * @param data
     * @private
     */
    private async updatePlayerTechs(data: Array<never>): void {
        data.forEach((eventData: string) => {
            this.gameStore.playerTechs.set(eventData)
        });
    }

    /**
     * Sync Planet Data
     *
     * @param data
     * @private
     */
    private async planetUpdate(data: PlayerPlanet): void {
        this.planetStore.updatePlanetById(data.id, data);
    }

    /**
     * Planet Queue Automation
     *
     * @param data
     * @private
     */
    private async pqaUpdate(data: PlanetQueueAutomation): void {
        this.pqaStore.updateQueue(data)
    }

    private async pqaDelete(data: { pqaId: number }): void {
        this.pqaStore.deleteQueue(data.pqaId)
    }

    /**
     * Delete Planet
     *
     * @param data
     * @private
     */
    private async planetDelete(data: Array<never>): void {
        const eventData = data as unknown as {
            planetId: number
        }
        this.planetStore.deletePlanetById(eventData.planetId)
    }

    /**
     * Sync Ship Data
     *
     * @param data
     * @private
     */
    private async shipUpdate(data: PlayerShip): void {
        this.shipStore.updateShipById(data.id, data)
    }

    /**
     * Add/Update Ship Group
     *
     * @param data
     * @private
     */
    private async shipGroupUpdate(data: ShipGroup): void {
        this.shipGroupStore.updateShipGroupById(data.id, data)
    }

    /**
     * Delete Ship Group
     */
    private async shipGroupDelete(data: Array<never>): void {
        const eventData = data as unknown as {
            shipGroupId: number
        }
        this.shipGroupStore.deleteShipGroupById(eventData.shipGroupId)
    }

    /**
     * Add/Update Ship Group
     *
     * @param data
     * @private
     */
    private async planetGroupUpdate(data: PlanetGroup): void {
        this.planetGroupStore.updatePlanetGroupById(data.id, data)
    }

    /**
     * Delete Ship Group
     */
    private async planetGroupDelete(data: Array<never>): void {
        const eventData = data as unknown as {
            planetGroupId: number
        }
        this.planetGroupStore.deletePlanetGroupById(eventData.planetGroupId)
    }

    /**
     * Sync Fleet Data
     *
     * @param data
     * @private
     */
    private async fleetUpdate(data: Fleet): void {
        this.fleetStore.updateFleetById(data.id, data)
    }

    /**
     * Delete Fleet
     *
     * @param data
     * @private
     */
    private async fleetDelete(data: Array<never>): void {
        const eventData = data as unknown as {
            fleetId: number
        }
        this.fleetStore.deleteFleetById(eventData.fleetId)
    }

    /**
     * Delete Ship
     *
     * @param data
     * @private
     */
    private async shipDelete(data: Array<never>): void {
        const eventData = data as unknown as {
            shipId: number
        }
        this.shipStore.deleteShipById(eventData.shipId)
    }
}
