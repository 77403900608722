<script setup lang="ts">

import IconCount from "@/components/Objects/IconCount.vue";
import Icon from "@/components/Objects/Icons/Icon.vue";
import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetActiveDefenceBuildingList
  from "@/components/Sections/Planets/PlanetTabs/DefencesTab/PlanetActiveDefenceBuildingList.vue";

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()

const planetData = computed(() => planetStore.findPlanetById(props.planetId))
</script>

<template>
  <div class="p-0">
    <div class="content-header">Active Defenses</div>
    <div v-if="planetData?.attackAgainstShips>0">
      <div class="bg-slate-800 text-subtext text-center p-2 my-1">
        Attack against invading ships
        <div class="flex justify-center p-3">
          <Icon type="defence_attack_ships"/><IconCount :amount="planetData?.attackAgainstShips"/>
        </div>
      </div>
      <PlanetActiveDefenceBuildingList :planetId="props.planetId"></PlanetActiveDefenceBuildingList>
    </div>
    <div v-else>
      <div class="text-subtext text-center p-4">No active defenses have been build on this planet yet.</div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>