import * as THREE from 'three';
import { useMapStore } from '@/stores/mapStore.ts'
import { zoomFactor } from '@/enums/zoomFactor.ts'
import Eta from '@/models/eta.ts'

export class MapETA {

  private parent: THREE.Line
  private mapStore: any = useMapStore();

  public constructor(parent: THREE.Line) {
    this.parent = parent;

    const positions = this.parent.geometry.attributes.position.array;
    const numPoints = positions.length / 3;
    const eta = new Eta();

    // Create a group to hold all sprites
    const spriteGroup = new THREE.Group();

    for (let i = 0; i < numPoints - 1; i++) {
      const startX = positions[i * 3];
      const startY = positions[i * 3 + 1];

      const endX = positions[(i + 1) * 3];
      const endY = positions[(i + 1) * 3 + 1];

      eta.start(startX, startY);
      const days = eta.etaNext(endX, endY, this.mapStore.etaSpeed);

      // Calculate midpoint and angle of the line
      const midX = (startX + endX) / 2;
      const midY = (startY + endY) / 2;
      const midPoint = new THREE.Vector3(midX, midY, 0.2);
      const deltaX = endX - startX;
      const deltaY = endY - startY;
      const angle = Math.atan2(deltaY, deltaX);

      // Create a canvas to draw the text
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if(context) {
        const fontSize = 64;
        context.font = `${fontSize}px Arial`;
        const text = days + '';
        const metrics = context.measureText(text);
        // Set canvas dimensions based on text size
        canvas.width = metrics.width;
        canvas.height = fontSize;
        context.font = `${fontSize}px Arial`;
        context.fillStyle = 'rgba(255, 255, 255, 1.0)';
        context.textBaseline = 'middle';
        context.fillText(text, 0, canvas.height / 2);

        // Create a texture from the canvas
        const texture = new THREE.CanvasTexture(canvas);

        // Create sprite material
        const spriteMaterial = new THREE.SpriteMaterial({ map: texture, transparent: true });

        // Create sprite and set its position and rotation
        const sprite = new THREE.Sprite(spriteMaterial);
        sprite.position.copy(midPoint);

        // Determine scale based on zoom factor
        let scale = 0;
        if (this.mapStore.zoomFactor == zoomFactor.Planet) {
          scale = 0.01;
        } else if (this.mapStore.zoomFactor == zoomFactor.Solar) {
          scale = 0.025;
        } else if (this.mapStore.zoomFactor == zoomFactor.Local) {
          scale = 0.09;
        } else if (this.mapStore.zoomFactor == zoomFactor.Sector) {
          scale = 0.20;
        } else if (this.mapStore.zoomFactor == zoomFactor.Quadrant) {
          scale = 0.40;
        } else if (this.mapStore.zoomFactor == zoomFactor.Max) {
          scale = 0.75;
        }
        sprite.scale.set(scale * canvas.width, scale * canvas.height, 1);

        let adjustedAngle = angle;
        if (adjustedAngle > Math.PI / 2) {
          adjustedAngle -= Math.PI;
        }
        if (adjustedAngle < -Math.PI / 2) {
          adjustedAngle += Math.PI;
        }
        sprite.material.rotation = adjustedAngle;

        // Add sprite to the group
        spriteGroup.add(sprite);
      }
    }
    this.parent.add(spriteGroup);
  }

}
