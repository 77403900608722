<script setup lang="ts">

import {computed, defineProps} from "vue";
import {usePlanetStore} from "@/stores/planetStore";
import PlanetEnergyCapacityIcon from "@/components/Objects/IconsWithCounters/PlanetEnergyCapacityIcon.vue";
import PlanetResourceIcon from "@/components/Objects/IconsWithCounters/PlanetResourceIcon.vue";
import PlanetProductionIcon from "@/components/Objects/IconsWithCounters/PlanetProductionIcon.vue";
import PlanetActiveDefenceBuildingList
  from '@/components/Sections/Planets/PlanetTabs/DefencesTab/PlanetActiveDefenceBuildingList.vue'
import PlanetPassiveDefenceBuildingList
  from '@/components/Sections/Planets/PlanetTabs/DefencesTab/PlanetPassiveDefenceBuildingList.vue'

const props = defineProps({
  planetId: Number
});

const planetStore = usePlanetStore()

const data = computed(() => {
  const planetData = planetStore.findPlanetById(props.planetId);

  // Calculate the percentage of the planet's hitpoints
  const percentage = Math.round((planetData?.energy / planetData?.energyStorageCapacity) * 100);

  let color = 'bg-green-800';
  if(percentage < 50 && percentage > 25) {
    color = 'bg-yellow-800';
  } else if(percentage <= 25) {
    color = 'bg-red-800';
  }

  return {
    planetData: planetData,
    percentage: percentage,
    color: color
  }
})
</script>

<template>
  <div class="p-0">
    <div class="content-header">Passive Defenses</div>
    <div v-if="planetStore.hasBuilding(Number(props.planetId),'planetary_shields')">
      <div class="p-3 bg-slate-800 my-1">
        <div class="flex justify-between mb-1">
          <span class="">Shield capacity</span>
          <PlanetEnergyCapacityIcon
              :planet-id="props.planetId"
              :noEndPadding="true"
          ></PlanetEnergyCapacityIcon>
        </div>
        <div class="w-full rounded h-4 bg-slate-700" :title="data.planetData?.energy + ' Energy'">
          <div :class="'h-4 rounded ' + data.color + ''" :style="'width: ' + data.percentage +'%'"></div>
        </div>
      </div>
      <div class="mt-1">
        <div class="grid grid-cols-2 ">
          <div class="text-center p-3 text-subtext bg-slate-800">
            <div class="pb-2">Recuperation rate</div> <PlanetResourceIcon
              :planet-id="props.planetId"
              type="energy"
              class="pr-1"
          ></PlanetResourceIcon>
          </div>
          <div class="text-center p-3 ms-1 text-subtext bg-slate-800">
            <div class="pb-2">Shield Strength</div> <PlanetProductionIcon
              :planet-id="props.planetId"
              :amount="data.planetData?.shields"
              type="defence_shields"
              class="pr-1"
          ></PlanetProductionIcon>
          </div>
        </div>

      </div>
      <PlanetPassiveDefenceBuildingList :planetId="props.planetId"></PlanetPassiveDefenceBuildingList>
    </div>
    <div v-else>
      <div class="text-subtext text-center p-4">Build Planetary Shields first.</div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>