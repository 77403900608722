<script setup lang="ts">

import {useShipActionStore} from "@/stores/shipActionStore.ts";
import Backend from '@/models/backend.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { useFleetStore } from '@/stores/fleetStore.ts'
import { useShipStore } from '@/stores/shipStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { useMapStore } from '@/stores/mapStore.ts'

const shipActionStore = useShipActionStore()
const fleetStore = useFleetStore()
const shipStore = useShipStore()
const gameStore = useGameStore()
const mapStore = useMapStore()

const resetAction = () => {
  shipActionStore.reset()
}

const setActions = () => {
  const backend = new Backend()
  if (shipActionStore.fleetId > 0) {
    //Store fleet orders
    backend.setFleetActions(shipActionStore.fleetId, shipActionStore.actions)
      .then(() => {
        gameStore.setTinySuccessToastMessage('Orders set for fleet "' + fleetStore.getFleetNameById(shipActionStore.fleetId) + '"')
        shipActionStore.reset()
      })
      .catch((error) => {
      gameStore.setTinyErrorToastMessage(error.response.data.message)
      shipActionStore.reset()
    })
  } else {
    //Store ship orders
    backend.setShipActions(shipActionStore.ships, shipActionStore.actions)
      .then(() => {
        gameStore.setTinySuccessToastMessage('Orders set for ' + shipActionStore.ships.length + ' ship(s)')
        shipActionStore.reset()
      })
      .catch((error) => {
      gameStore.setTinyErrorToastMessage(error.response.data.message)
      shipActionStore.reset()
    })
  }
}

const queueExplore = () => {
  shipActionStore.setPendingAction('explore')
  shipActionStore.queuePendingAction(
    0,
    0,
    0,
    0
  );
  setActions()
}

const queueRepair = () => {
  shipActionStore.setPendingAction('repair')
  shipActionStore.queuePendingAction(
    shipActionStore.pathEndX,
    shipActionStore.pathEndY,
    0,
    0
  );
}

const detachShipsFromFleet = () => {
  const ids = []; // Use a different variable name to avoid redeclaration confusion
  for (const ship of shipStore.ships.values()) {
    if (
      Number(ship.xPos) >= Number(mapStore.areaBoxTL.x) &&
      Number(ship.xPos) <= Number(mapStore.areaBoxRB.x) &&
      Number(ship.yPos) <= Number(mapStore.areaBoxTL.y) &&
      Number(ship.yPos) >= Number(mapStore.areaBoxRB.y)
    ) {
      if (ship.fleetId === shipActionStore.fleetId) {
        ids.push(ship.id);
      }
    }
  }

  const backend = new Backend()
  backend.detachShipsFromFleet(ids)

  shipActionStore.reset()
  gameStore.setTinySuccessToastMessage(ids.length + ' Ships detached from fleet')
}

window.addEventListener('keydown', (event) => {
  if(shipActionStore.initialized) {
    if (event.key === 'm' || event.key === 'M') {
      shipActionStore.setPendingAction('move')
    } else if(event.key === 'a' || event.key === 'A') {
      shipActionStore.setPendingAction('attack')
    } else if(event.key === 's' || event.key === 'S') {
      shipActionStore.setPendingAction('settle')
    } else if (event.key === 'f' || event.key === 'F') {
      shipActionStore.setPendingAction('follow')
    } else if (event.key === 't' || event.key === 'T') {
      shipActionStore.setPendingAction('followAttack')
    } else if (event.key === 'r' || event.key === 'R') {
      queueRepair()
    } else if (event.key === 'e' || event.key === 'E') {
      queueExplore()
    } else if (event.key === 'l' || event.key === 'L') {
      detachShipsFromFleet()
    }
    //esc
    if (event.key === 'Escape') {
      shipActionStore.reset();
    }
    //del
    if (event.key === 'Delete' || event.key === 'Backspace') {
      shipActionStore.setPendingAction('')
      mapStore.shipActionPath.removeLastAction()
    }
  }
  if(mapStore.areaActionSelecting) {
    if (event.key === 'Escape') {
      mapStore.disableAreaSelection();
    }
  }
})

</script>

<template>
<div v-if="shipActionStore.initialized" class="absolute w-full top-0 text-center flex justify-center pointer-events-none">
  <div class="flex flex-col bg-slate-900 p-2 pointer-events-auto bg-opacity-70">
    <div v-if="shipActionStore.fleetId === 0">{{ shipActionStore.ships.length }} ship(s) selected</div>
    <div v-else>Fleet "{{ fleetStore.getFleetNameById(Number(shipActionStore.fleetId)) }}" selected</div>
    <div class="grid grid-cols-7">
      <div :class="{
              'cursor-pointer p-1 m-1' : true,
              'bg-slate-600' : shipActionStore.pendingAction === 'move',
              'bg-slate-800 hover:bg-slate-600 ' : shipActionStore.pendingAction !== 'move'
           }"
           @click="shipActionStore.setPendingAction('move')">
        <img src="http://cdn.galexion.com/images/ship/actions/move.png" alt="Move (m)" title="Move (m)" class="w-8 h-8 p-1"/>
      </div>
      <div :class="{
              'cursor-pointer p-1 m-1' : true,
              'bg-slate-600' : shipActionStore.pendingAction === 'attack',
              'bg-slate-800 hover:bg-slate-600 ' : shipActionStore.pendingAction !== 'attack'
           }"
           @click="shipActionStore.setPendingAction('attack')">
        <img src="http://cdn.galexion.com/images/ship/actions/attack.png" alt="Attack (a)" title="Attack (a)" class="w-8 h-8 p-1"/>
      </div>
      <div :class="{
              'cursor-pointer p-1 m-1' : true,
              'bg-slate-600' : shipActionStore.pendingAction === 'settle',
              'bg-slate-800 hover:bg-slate-600 ' : shipActionStore.pendingAction !== 'settle'
           }"
             @click="shipActionStore.setPendingAction('settle')">
          <img src="http://cdn.galexion.com/images/ship/actions/settle.png" alt="Settle (s)" title="Settle (s)" class="w-8 h-8 p-1"/>
      </div>
      <div :class="{
              'cursor-pointer p-1 m-1' : true,
              'bg-slate-600' : shipActionStore.pendingAction === 'follow',
              'bg-slate-800 hover:bg-slate-600 ' : shipActionStore.pendingAction !== 'follow'
           }"
           @click="shipActionStore.setPendingAction('follow')">
        <img src="http://cdn.galexion.com/images/ship/actions/follow.png" alt="Follow (f)" title="Follow (f)" class="w-8 h-8 p-1"/>
      </div>
      <div :class="{
              'cursor-pointer p-1 m-1' : true,
              'bg-slate-600' : shipActionStore.pendingAction === 'followAttack',
              'bg-slate-800 hover:bg-slate-600 ' : shipActionStore.pendingAction !== 'followAttack'
           }"
           @click="shipActionStore.setPendingAction('followAttack')">
        <img src="http://cdn.galexion.com/images/ship/actions/followattack.png" alt="Follow & Attack (t)" title="Follow & Attack (t)" class="w-8 h-8 p-1"/>
      </div>
      <div v-if="shipActionStore.fleetId === 0" :class="{
              'cursor-pointer p-1 m-1' : true,
              'bg-slate-600' : shipActionStore.pendingAction === 'explore',
              'bg-slate-800 hover:bg-slate-600 ' : shipActionStore.pendingAction !== 'explore'
           }"
           @click="queueExplore()">
        <img src="http://cdn.galexion.com/images/ship/actions/search.png" alt="Explore (e)" title="Explore (e)" class="w-8 h-8 p-1"/>
      </div>
      <div :class="{
              'cursor-pointer p-1 m-1' : true,
              'bg-slate-600' : shipActionStore.pendingAction === 'repair',
              'bg-slate-800 hover:bg-slate-600 ' : shipActionStore.pendingAction !== 'repair'
           }"
           @click="queueRepair()">
        <img src="http://cdn.galexion.com/images/ship/actions/repair.png" alt="Repair (r)" title="Repair (R)" class="w-8 h-8 p-1"/>
      </div>
      <div v-if="shipActionStore.fleetId > 0" :class="{
              'cursor-pointer p-1 m-1 bg-slate-800 hover:bg-slate-600' : true,
           }"
           @click="detachShipsFromFleet()">
        <img src="http://cdn.galexion.com/images/ship/actions/decouple.png" alt="Detach selected ships (l)" title="Detach selected ships (l)" class="w-8 h-8 p-1"/>
      </div>
    </div>
    <div class="pt-2">
      <StandardButton v-if="shipActionStore.actions.length>0" @click="setActions">Set</StandardButton>
      <StandardButton color="red" @click="resetAction">X</StandardButton>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">

</style>