<script setup lang="ts">

import { useGameStore } from "@/stores/gameStore.ts";
import SmallModel from '@/components/Interface/Modal/ModelSizes/SmallModel.vue'
import { useFleetStore } from '@/stores/fleetStore.ts'
import Backend from '@/models/backend.ts'
import { ref } from 'vue'

// Get the planet data
const gameStore = useGameStore();
const fleetStore = useFleetStore();
const backend = new Backend()

const selectedFleet = ref()

const submit = () => {
  backend.setFleetForShips(selectedFleet.value,gameStore.modalData).then(()=>{
    gameStore.modalType=''
  })
}

</script>

<template>
  <SmallModel title="Select Fleet">
    <div class="p-2">
      <p class="pb-2 text-subtext">Select the fleet you wish to assign these {{ gameStore.modalData.length }} ship(s) to.</p>
    <select @change="submit" v-model="selectedFleet" class="w-full pointer-events-auto p-2 text-sm border rounded bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
      <template v-for="fleet in fleetStore.fleets.values()" :key="fleet.id">
        <option :value="fleet.id">{{ fleet.name }}</option>
      </template>
    </select>
      <p class="pt-2 text-subtext">Note: The newly added ships will be marked as "Reinforcements" and fly to the core of the selected fleet. Fleet orders will not apply to these ships until they have joined the core of the fleet.</p>
    </div>
  </SmallModel>
</template>

<style scoped lang="scss">
.table-container {
  height: 30rem;
  overflow-y: auto;
}
</style>
