import { defineStore } from 'pinia';
import type PlanetGroup from '@/types/PlanetGroup.ts'

export const usePlanetGroupStore = defineStore('planetGroupStore', {
    state: () => ({
        planetGroups: new Map<number, PlanetGroup>()
    }),
    getters: {
        findPlanetGroupById: (state) => (id: number): PlanetGroup | undefined => {
            return state.planetGroups.get(id);
        }
    },
    actions: {
        updatePlanetGroupById(id: number, data: PlanetGroup) {
            this.planetGroups.set(data.id, data);

        },
        deletePlanetGroupById(id: number) {
            if (this.planetGroups.has(id)) {
                this.planetGroups.delete(id);
            }
        },
        getNameFromId(id: number): string {
            const planetGroup = this.findPlanetGroupById(id);
            if (planetGroup) {
                return planetGroup.name;
            } else {
                return '';
            }
        }
    },
});
