<script setup lang="ts">

import { computed, defineComponent, defineProps, ref } from 'vue'
import {useAssetStore} from "@/stores/assetStore";
import Backend from "@/models/backend";
import { useGameStore } from '@/stores/gameStore'
import Icon from '@/components/Objects/Icons/Icon.vue'
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import BuildingEffects from '@/components/Objects/Building/BuildingEffects.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import BuildingBase from '@/components/Sections/Planets/PlanetTabs/BuildingBase.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
import { usePQAStore } from '@/stores/PQAStore.ts'
import PQA from '@/components/Sections/Planets/PlanetTabs/PlanetProductionQueueAutomation/PQA.vue'


/**
 * props
 */
const props = defineProps({
  productionObjectId: Number
});

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const PQAStore = usePQAStore()

/**
 * Computed
 */
const productionObjectData = computed(() => assetStore.findProductionObjectById(props.productionObjectId))

const buildingData = computed(() => {
  return assetStore.findBuildingByCode(productionObjectData.value.objectId)
})

/**
 * Set the production object
 */
const addProductionObject = () => {
  PQAStore.addToQueue(productionObjectData.value.id)
}

</script>

<template>
  <BuildingBase class="height-limit" v-if="!PQAStore.isInQueue(productionObjectId)">
    <template #image>
      <ImageRounded
        type="buildings"
        source-size="128"
        :file="productionObjectData.objectId"
        :size="8"/>
    </template>
    <template #button>
      <StandardButton
        @click.once="addProductionObject"
        :end-margin="false"
      >Add</StandardButton>
    </template>
    <template #title>
      {{ productionObjectData.productionObjectName }}
    </template>
    <template #default>
      {{ productionObjectData.description }}
    </template>
    <template #effects>
      <div class="flex justify-between w-full">
        <div>
          <BuildingEffects v-if="buildingData" :building="buildingData"> </BuildingEffects>
        </div>
        <div class="flex-shrink text-subtext">{{ productionObjectData.productionCost == 0 ? '?' : productionObjectData.productionCost }} <Icon type="production"></Icon></div>
      </div>
    </template>
  </BuildingBase>
</template>

<style scoped lang="scss">
.height-limit {
  height: 8.6rem;
}
</style>
