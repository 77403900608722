<script setup lang="ts">
import {defineProps, onMounted, ref, watch} from 'vue';
import ToolTip from "../../../../../../../galexion/frontend-web/galexion/src/components/Interface/ToolTip/ToolTip.vue";
import Icon from "../../../../../../../galexion/frontend-web/galexion/src/components/Objects/Icons/Icon.vue";
import Amount from "@/components/Objects/IconCount.vue";

// Define the props your component expects
const props = defineProps({
  type: String,
  amount: Number,
  planetId: Number,
  allowZero: {
    type: Boolean,
    default: false
  }
});

const url = ref('');

onMounted(() => {
  if(props.planetId) {
    url.value = 'planet/' + props.planetId + '/' + props.type;
  }
});

watch(() => props.planetId, (newValue) => {
  if(newValue) {
    url.value = 'planet/'  + newValue + '/' + props.type;
  }
});

</script>

<template>
  <span v-if="amount != 0 || allowZero">
    <tool-tip :url="url">
      <Icon :type="type"></Icon>
      <Amount :amount="amount" :allowZero="allowZero"></Amount>
    </tool-tip>
  </span>
</template>

<style scoped>
</style>
