<script setup lang="ts">
import * as yup from 'yup'
import SmallModel from "@/components/Interface/Modal/ModelSizes/SmallModel.vue";
import {reactive} from "vue";
import Backend from '@/models/backend.ts'
import FormProcess from '@/models/formProcess.ts'
import FormInput from "@/components/Interface/Modal/Forms/FormInput.vue";
import FormSubmit from "@/components/Interface/Modal/Forms/FormSubmit.vue";
import FormGenericError from '@/components/Interface/Modal/Forms/FormGenericError.vue'
import { useGameStore } from '@/stores/gameStore.ts'

const backend = new Backend()
const formProcess = new FormProcess()
const gameStore = useGameStore()


// Define the validation schema
const schema = yup.object().shape({
  shipGroupName: yup
    .string()
    .min(3, () => `Use at least 3 characters`)
    .max(16, () => `Do not use more than 16 characters`)
    .required(() => `This is required`)
})

// Default form values
const formValues = reactive<{ [key: string]: string }>({
  shipGroupName: ''
})

// Form errors
const formErrors = reactive<{ [key: string]: string }>({
  generic: '',
  shipGroupName: ''
});


// Handle form submission
const submit = () => {
  schema.validate(formValues)
    .then(() => {
      formProcess.resetFormErrors(formErrors)
      backend.createShipGroup(formValues.shipGroupName)
        .then(() => {
          gameStore.setTinySuccessToastMessage('Group created')
          gameStore.modalType = ''
        })
        .catch(error => formProcess.processBackendErrorResponse(error, formErrors))
    })
    .catch((error) => formProcess.processFrontendErrorResponse(error, formErrors))
}

</script>

<template>
  <SmallModel title="Create Group">
    <form method="post" @submit.prevent="submit" class="pl-4 pr-4 pt-4">
      <FormInput
          label="Group name"
          v-model="formValues.shipGroupName"
          :error="formErrors.shipGroupName"
      />
      <div class="flex justify-between">
        <FormGenericError :error="formErrors.generic"/>
        <FormSubmit text="Create" class="justify-end"/>
      </div>
    </form>
  </SmallModel>
</template>


<style scoped lang="scss">

</style>