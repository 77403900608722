<script setup lang="ts">

import { computed, watch } from 'vue'
import { useTableStore } from '@/stores/tableStore.ts'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'

import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import Pagination from '@/components/Interface/DataTable/Pagination.vue'

const tableStore = useTableStore()

const props = defineProps({
  selector: {
    type: Boolean,
    default: false
  },
  footerBar: {
    type: Boolean,
    default: true
  },
  noResultMessage: {
    type: String,
    default: 'No results found, try changing your filters.'
  },
  id: {
    type: String,
    default: 'id'
  },
  tableStoreName: {
    type: String,
    required: true
  },
  filters: {
    type: Function,
    default: () => (item) => true
  },
  heightLimit: {
    type: String,
    default: 'h-[calc(100vh-10rem)]'
  },
  data: {
    type: Map<any>,
    required: true
  },
  click: {
    type: Function,
    default: () => {}
  },
  doubleClick: {
    type: Function,
    default: () => {}
  },
  noActionBar: {
    type: Boolean,
    default: false
  }
});

//Sorting
const list = computed(() => {
  const list = Array.from(props.data.values()).sort((a, b) => {
    const sortColumn = tableStore[props.tableStoreName].sortColumn;
    const sortDirection = tableStore[props.tableStoreName].sortDirection;

    // Access the values to compare
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    // Handle numbers
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
    }

    // Handle strings (or other types)
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      if (sortDirection === 'asc') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    }

    // handle boolean
    if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
      return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
    }

    // Fallback case for other types or undefined values
    return 0;
  });
  //tableStore[props.tableStoreName].totalRows = list.length
  const filteredList = props.filters(list)
  tableStore[props.tableStoreName].totalFilteredRows = filteredList.length
  const start = Number(tableStore[props.tableStoreName].page * tableStore[props.tableStoreName].rowsPerPage);
  const end = start + Number(tableStore[props.tableStoreName].rowsPerPage);
  const sortedList = filteredList.slice(start, end);
  tableStore[props.tableStoreName].visibleRows = sortedList.length
  return sortedList
});

watch(() => tableStore[props.tableStoreName].rowsPerPage, (newVal) => {
    tableStore[props.tableStoreName].page = 0
    tableStore[props.tableStoreName].selectedRowIds=[]
    tableStore[props.tableStoreName].selectedAll=false
})

watch(() => tableStore[props.tableStoreName].page, (newVal) => {
  tableStore[props.tableStoreName].selectedAll=false
})

watch(
  () => tableStore[props.tableStoreName].filters,
  (newVal) => {
    tableStore[props.tableStoreName].page = 0;
    tableStore[props.tableStoreName].selectedRowIds=[]
    tableStore[props.tableStoreName].selectedAll=false
  },
  { deep: true }
);


//Select all button
const selectAllHandler = () => {
  if (!tableStore[props.tableStoreName].selectedAll) {
    // Select all visible rows
    for (const row of list.value) {
      selectRow(row.id)
    }
    tableStore[props.tableStoreName].selectedAll = true
  } else {
    // Deselect all visible rows
    for (const row of list.value) {
      deselectRow(row.id);
    }
  }
}

//Select one row
const selectRowHandler = (id:number) => {
  const selectedIndex = tableStore[props.tableStoreName].selectedRowIds.indexOf(id);
  if (selectedIndex === -1) {
    // Add the id if it's not already selected
    selectRow(id)
  } else {
    // Remove the id if it's already selected
    deselectRow(id)
  }
  console.log(tableStore[props.tableStoreName].selectedRowIds);
}

const clickHandler = (id:number) => {
  tableStore[props.tableStoreName].singleSelectedId=id
  props.click(id)
}

const doubleClickHandler = (id:number) => {
  props.doubleClick(id)
}

//Select row sub method
const selectRow = (id:number) => {
  const selectedIndex = tableStore[props.tableStoreName].selectedRowIds.indexOf(id);
  if (selectedIndex === -1) {
    console.log('selectRow',id)
    tableStore[props.tableStoreName].selectedRowIds.push(id);
  }
}

//Deselect row sub method
const deselectRow = (id:number) => {
  const selectedIndex = tableStore[props.tableStoreName].selectedRowIds.indexOf(id);
  if (selectedIndex !== -1) {
    console.log('deselectRow',id)
    tableStore[props.tableStoreName].selectedRowIds.splice(selectedIndex, 1);
  }
  tableStore[props.tableStoreName].selectedAll = false
}

// Select Sorting
const sortColumn = (column:string) => {
  if(tableStore[props.tableStoreName].sortColumn === column) {
    if(tableStore[props.tableStoreName].sortDirection === 'asc') {
      tableStore[props.tableStoreName].sortDirection = 'desc'
    } else {
      tableStore[props.tableStoreName].sortDirection = 'asc'
    }
  } else {
    tableStore[props.tableStoreName].sortColumn = column
    tableStore[props.tableStoreName].sortDirection = 'asc'
  }
}

</script>

<template>
  <div :class="'grid grid-rows-[auto_auto_1fr_auto] overflow-x-auto no-scrollbar ' + props.heightLimit">
    <!--- ActionBar--->
    <div v-if="!noActionBar" class="flex justify-end pb-1 h-10">
      <slot name="actionBar">
      </slot>
    </div>
    <div v-if="$slots.actionBar2" class="flex justify-end pb-1 h-10">
      <slot name="actionBar2">
      </slot>
    </div>
    <!--- Headers --->
    <div class="flex h-10 bg-slate-700">
      <div v-if="props.selector" class="flex items-center justify-center w-14">
        <input
          type="checkbox"
          :checked="tableStore[props.tableStoreName].selectedAll"
          @click.stop="selectAllHandler"
          class="w-6 h-6 accent-slate-600 cursor-pointer"
        >
      </div>
      <slot name="columns" :sort="sortColumn" :sortColumn="tableStore[tableStoreName].sortColumn" :sortDirection="tableStore[props.tableStoreName].sortDirection">
      </slot>
    </div>

    <!--- Rows --->
    <div class="overflow-y-scroll no-scrollbar" >
      <template v-for="item in list" :key="item.id">
        <div
          :class="{
              'flex border-b border-b-slate-700 h-11': true,
              'bg-slate-800': tableStore[props.tableStoreName].singleSelectedId==item.id,
              'cursor-pointer': true
            }"
          @dblclick.stop="doubleClickHandler(item.id)"
          @click.stop="clickHandler(item.id)"
        >
          <div v-if="props.selector" class="flex items-center justify-center w-14">
            <input
              type="checkbox"
              :checked="tableStore[props.tableStoreName].selectedRowIds.includes(item.id)"
              @click.stop="selectRowHandler(item.id)"
              class="w-6 h-6 accent-slate-600 bg-slate-600 cursor-pointer"
            >
          </div>
          <slot name="rows" :item="item" >
          </slot>
        </div>
      </template>
      <div v-if="tableStore[props.tableStoreName].totalFilteredRows == 0" class="p-6 w-full flex justify-center text-subtext">
        {{ props.noResultMessage }}
      </div>
    </div>

    <!--- Footer --->
    <div v-if="footerBar" class="flex justify-end h-10">
      <ActionBarBlock class="mr-1 px-2 flex items-center text-subtext">
        {{ tableStore[props.tableStoreName].totalFilteredRows }} Total
      </ActionBarBlock>
      <ActionBarBlock v-if="props.selector" class="mr-1 px-2 flex items-center text-subtext">
       {{ tableStore[props.tableStoreName].selectedRowIds.length }} Selected
      </ActionBarBlock>
      <ActionBarFiller class="mr-1"></ActionBarFiller>
      <ActionBarBlock class="flex px-1 mr-1 items-center">
        <Pagination :tableStoreName="tableStoreName"></Pagination>
      </ActionBarBlock>
      <ActionBarBlock class="flex px-1 items-center">
        <select v-model="tableStore[props.tableStoreName].rowsPerPage" class="!bg-slate-700">
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
          <option>1000</option>
        </select>
      </ActionBarBlock>
    </div>
  </div>
</template>

<style scoped lang="scss">
select {
  @apply bg-slate-600 p-1 mt-0.5 text-slate-400 focus:outline-none
}
</style>