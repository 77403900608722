<script setup lang="ts">

import type NewsItem from "@/types/NewsItem.ts";
import ModalTrigger from "@/components/Interface/Modal/ModalTrigger.vue";
import {useGameStore} from "@/stores/gameStore.ts";
import Position from '@/components/Interface/Position.vue'
import PlayerName from '@/components/Objects/PlayerName.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import NewsItemHeader from '@/components/Interface/Headers/NewsItemHeader.vue'
import ToastItem from '@/components/Interface/Toast/ToastItem.vue'
import ImageRoundedUrl from '@/components/Interface/Images/ImageRoundedUrl.vue'
import { usePlayerRelationStore } from '@/stores/playerRelations.ts'
import NewsItemBaseStructure from '@/components/Sections/News/Types/Base/NewsItemBaseStructure.vue'
import NewsItemBase from '@/components/Sections/News/Types/Base/NewsItemBase.vue'
import { computed } from 'vue'

const gameStore = useGameStore();
const playerRelationStore = usePlayerRelationStore();

const props = defineProps<{
  newsItem: NewsItem,
  toast: boolean
}>();

const title = computed(() => {
  return props.newsItem.contentData.winnerPlayerId === gameStore.player?.id ? 'You won a battle' : 'You lost a battle';
});

</script>

<template>
  <NewsItemBase
    :toast="toast"
    :toast-image="playerRelationStore.getProfileImageByPlayerId(newsItem.contentData.opponentPlayerId)"
    :toast-text="title"
  >
    <ModalTrigger type="battleReport" :data="{'battleReportId': props.newsItem.contentData.battleReportId}">
      <NewsItemBaseStructure
        :newsItem="props.newsItem"
        :item-image="playerRelationStore.getProfileImageByPlayerId(newsItem.contentData.opponentPlayerId)"
        :header="title"
      >
        <span v-if="newsItem.contentData.winnerPlayerId === gameStore.player?.id">
            You have won
          </span>
        <span v-else>
            You have lost
          </span>
        the battle on <Position @click.stop :x="props.newsItem.contentData.xPos" :y="props.newsItem.contentData.yPos" /> against <PlayerName :playerId="props.newsItem.contentData.opponentPlayerId" />
        <br>
        <div class="pb-1 pt-8">
          <StandardButton>Replay Battle</StandardButton>
        </div>
      </NewsItemBaseStructure>
    </ModalTrigger>
  </NewsItemBase>
</template>

<style scoped lang="scss">

</style>