import {defineStore} from "pinia";
import type PlayerShipDesign from "@/types/PlayerShipDesign.ts";

export const useShipDesignStore = defineStore('shipDesignStore', {
    state: () => ({
        playerShipDesigns: new Map<number, PlayerShipDesign>()
    }),
    getters: {
        getShipDesignById: (state) => (id: number): PlayerShipDesign | undefined => {
            return state.playerShipDesigns.get(id)
        }
    },
    actions: {
        getShipDesignNameById(id: number): string {
            const shipDesign = this.getShipDesignById(id);
            return shipDesign ? shipDesign.name : '';
        },
        setShipDesign(shipDesign: PlayerShipDesign) {
            this.playerShipDesigns.set(shipDesign.id, shipDesign);
        }
    }
})