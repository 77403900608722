<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore.ts'

const gameStore = useGameStore()

</script>

<template>
  <div v-if="gameStore.pendingRequestId != ''" class="fixed inset-0 flex items-center justify-center text-white z-100">
    <div v-if="gameStore.pendingRequestPopupVisible" class="flex flex-col items-center bg-slate-950 border border-slate-700 p-10">
      <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
      <div class="pt-2">Processing...</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.z-100 {
  z-index: 1000 !important;
}
</style>
