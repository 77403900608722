<script lang="ts">
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'vue-chartjs';
import { defineComponent, ref, computed, watch } from 'vue';
import { useStatsStore } from '@/stores/statsStore.ts'; // Import the Pinia store

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export default defineComponent({
  name: 'App',
  components: {
    Line,
  },
  setup() {
    // Use the Pinia store
    const statsStore = useStatsStore();

    // Computed property to get chart data from the store
    const chartData = computed(() => {
      // Retrieve the selected key's data from the store
      const selectedData = statsStore.stats.get(statsStore.selectedKey);

      return {
        labels: selectedData?.labels || [],
        datasets: [
          {
            label: selectedData?.datasets?.[0]?.label || 'Planets',
            backgroundColor: 'rgba(75, 192, 192, 0.1)', // Slightly darker fill color
            borderColor: 'rgba(75, 192, 192, 0.6)', // Line color
            data: selectedData?.datasets?.[0]?.data || [], // Fetch data from the store
            fill: true, // Fill below the line
            tension: 0.3, // Smoothness of the line
            pointRadius: 0, // Removes the points on the line
          },
        ],
      };
    });

    // Define chart options
    const options = ref({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true, // Start y-axis from 0
        },
      },
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
        title: {
          display: false
        },
        tooltip: {
          enabled: false, // Disable tooltips
        },
      },
      animation: {
        duration: 0, // Disable all animations
      }
    });

    // Watch for changes in the selected key and update the chart
    watch(() => statsStore.selectedKey, () => {
      // Optionally, trigger any logic when the selected key changes
      console.log(`Selected Key changed to: ${statsStore.selectedKey}`);
    });

    return {
      chartData,
      options,
    };
  },
});
</script>

<template>
    <Line :data="chartData" :options="options"/>
</template>

<style scoped lang="scss">

</style>
