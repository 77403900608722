<script setup lang="ts">

import { computed, defineComponent, defineProps } from 'vue'
import {useAssetStore} from "@/stores/assetStore";
import Backend from "@/models/backend";
import { useGameStore } from '@/stores/gameStore'
import ModalTrigger from '@/components/Interface/Modal/ModalTrigger.vue'
import BuildingEffects from '@/components/Objects/Building/BuildingEffects.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import BuildingBase from '@/components/Sections/Planets/PlanetTabs/BuildingBase.vue'
import ImageRounded from '@/components/Interface/Images/ImageRounded.vue'
/**
 * Available planet production objects tab component
 */
defineComponent({
  name: 'PlanetBuildingTabObject',
})

const props = defineProps({
  buildingCode: String,
  planetId: Number
});

const assetStore = useAssetStore()
const gameStore  = useGameStore()

const buildingData = computed(() => assetStore.findBuildingByCode(props.buildingCode))

const deleteBuilding = () => {

  gameStore.modal2Type = 'confirmationDelete';
  gameStore.modal2Data = {
    title: 'Are you sure?',
    message: 'Are you sure you want to delete this building?',
    actionText: 'Yes',
    cancelText: 'No',
    action: () => {
      const backend = new Backend();
      backend.deleteBuildingFromPlanet(props.planetId, buildingData.value.buildingCode);
      gameStore.setTinySuccessToastMessage('Building deleted');
    },
  };
}

</script>

<template>
  <BuildingBase>
    <template #image>
      <ModalTrigger type="building" :data="{'buildingCode': buildingData.buildingCode}">
        <ImageRounded
          type="buildings"
          source-size="128"
          :file="buildingData.buildingCode"
          :size="8"/>
      </ModalTrigger>
    </template>
    <template #button>
      <StandardButton
        color="red"
        @click="deleteBuilding"
        :end-margin="false"
      >X</StandardButton>
    </template>
    <template #title>
      {{ buildingData.buildingName }}
    </template>
    <template #default>
      {{ buildingData.buildingDescription }}
    </template>
    <template #effects>
      <BuildingEffects :building="buildingData"/>
    </template>
  </BuildingBase>
</template>

<style scoped lang="scss">
</style>
