<script setup lang="ts">
// Import defineProps to define the component props
import {computed, defineProps, defineComponent} from 'vue';
import {useAssetStore} from "@/stores/assetStore";
import {usePlanetStore} from "@/stores/planetStore";
import {useShipDesignStore} from "@/stores/shipDesignStore.ts";
import { usePQAStore } from '@/stores/PQAStore.ts'


/**
 * Props
 */
const props = defineProps({
  planetId: Number
});

/**
 * Stores / models
 */
const assetStore = useAssetStore()
const planetStore = usePlanetStore()
const shipDesignStore = useShipDesignStore()
const PQAStore = usePQAStore()

/**
 * Computed
 */
const planetData = computed(() => planetStore.findPlanetById(props?.planetId))
const shipDesignData = computed(() => shipDesignStore.getShipDesignById(planetData.value.productionShipDesignId))
const productionObjectData = computed(() => assetStore.findProductionObjectById(planetData.value.productionObjectId))

const widthPercentage = computed(() => {
  let progress = 0;
  if (shipDesignData.value) {
    progress = (planetData.value.productionProgress / shipDesignData.value.productionCost);
  } else {
    progress = (planetData.value.productionProgress / productionObjectData.value.productionCost)
  }
  if(progress > 1) {
    progress = 1
  }
  return progress * 100
});

const name = computed(() => {
  if (shipDesignData.value) {
    return shipDesignData.value.name
  } else {
    return productionObjectData.value.productionObjectName
  }
});

const pqaName = computed(() => {
  const pqa = PQAStore.findQueueById(planetData.value.planetQueueAutomationId)
  if(pqa) {
    return pqa.name
  }
  return ''
})

</script>

<template>
  <div v-if="planetData.productionObjectId != 0" class="w-full pr-2">
    <div class="pt-0.5">
      <div class="flex-grow flex flex-col">
        <div class="flex-shrink">
          <div class="flex">
            <div class="flex-grow text-xs">
              {{ name }}
            </div>
          </div>
        </div>
        <div class="flex-1 flex items-center justify-center">
            <div class="w-full rounded bg-gray-700">
              <div class="bg-blue-600 text-xs text-blue-100 text-center p-0.5 ps-2 leading-none rounded whitespace-nowrap" :style="'width:' + widthPercentage + '%'"></div>
            </div>
        </div>
        <div v-if="planetData.planetQueueAutomationId" class="flex-shrink">
          <div class="flex">
            <div class="flex-grow text-xxs text-subtext">
              {{ pqaName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="w-full pr-2">
    <div class="pt-0.5">
      <div class="flex-grow flex flex-col">
        <div class="flex-shrink">
          <div class="flex">
            <div class="flex-grow text-xs">
              &nbsp;
            </div>
          </div>
        </div>
        <div class="flex-1 flex items-center justify-center">
          <div class="w-full rounded">
            <div class="text-xs text-blue-100 text-center p-0.5 ps-2 leading-none rounded whitespace-nowrap" style="width:0%"></div>
          </div>
        </div>
        <div v-if="planetData.planetQueueAutomationId" class="flex-shrink">
          <div class="flex">
            <div class="flex-grow text-xxs text-subtext">
              {{ pqaName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
