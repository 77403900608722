<script setup lang="ts">
import { useGameStore } from '@/stores/gameStore.ts'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import TinyModel from '@/components/Interface/Modal/ModelSizes/Type2/TinyModel2.vue'

const gameStore = useGameStore()

const doAction = () => {
  gameStore.modal2Data.action()
  gameStore.modal2Type = ''
}

</script>

<template>
  <TinyModel :title="gameStore.modal2Data.title" style="z-index: 100">
    <div class="text-subtext p-2">
      {{ gameStore.modal2Data.message }}
      <div class="flex justify-between pt-3">
        <standard-button color="red" @click="gameStore.modal2Type=''">
          {{ gameStore.modal2Data.cancelText }}
        </standard-button>
        <standard-button @click="doAction">
          {{ gameStore.modal2Data.actionText }}
        </standard-button>
      </div>
    </div>
  </TinyModel>
</template>


<style scoped lang="scss">

</style>