<script setup lang="ts">

import { useGameStore } from '@/stores/gameStore'


import FullScreenModel from "@/components/Interface/Modal/ModelSizes/FullScreenModel.vue";
import ShipDesignComponentSelected
  from "@/components/Interface/Modal/Models/CreateShipDesign/ShipDesignComponentSelected.vue";
import SignDesignComponentAvailable
  from "@/components/Interface/Modal/Models/CreateShipDesign/SignDesignComponentAvailable.vue";
import {useShipDesignCreationStore} from "@/stores/shipDesignCreationStore.ts";
import {useAssetStore} from "@/stores/assetStore.ts";
import ShipDesignForm from "@/components/Interface/Modal/Models/CreateShipDesign/ShipDesignForm.vue";
import ShipDesignDetails from '@/components/Interface/Modal/Models/CreateShipDesign/ShipDesignDetails.vue'
import { computed } from 'vue'


const shipDesignStore = useShipDesignCreationStore()
const shipDesignCreationStore = useShipDesignCreationStore()
const gameStore = useGameStore()
const assetStore = useAssetStore()

const isAvailable = (componentCode: string) => {
  const component = assetStore.findShipComponentByCode(componentCode)
  // If there is no frame, the only show frames
  if(shipDesignStore.hasFrame) {
    if(component.group === 'frame') {
      return false
    }
    if(!shipDesignStore.hasEngine && component.group === 'engine') {
      return true
    }
    if(!shipDesignStore.hasEngine && component.group !== 'engine') {
      return false
    }
    const groups = ['scanner','special']
    if(groups.includes(component.group)) {
      for (const comp of shipDesignCreationStore.components) {
        if(comp.component.group === component.group) {
          return false;
        }
      }
      return true
    }
    return true
  } else {
    return component.group === 'frame'
  }
}

const sortedAvailable = computed(() => {
  return [...gameStore.playerShipComponents.entries()].sort((a, b) => {
    const aCode = a[1]; // Get code from playerShipComponents Map
    const bCode = b[1]; // Get code from playerShipComponents Map
    const aSortKey = assetStore.findShipComponentByCode(aCode).sortKey; // Get sortKey from shipDesignStore
    const bSortKey = assetStore.findShipComponentByCode(bCode).sortKey; // Get sortKey from shipDesignStore
    return aSortKey.localeCompare(bSortKey); // Compare sortKey for sorting
  });
});

</script>

<template>

  <FullScreenModel title="Create ship design">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-1 bg-slate-900">
      <div class="bg-slate-800">
        <div class="content-header">Details</div>
        <div class="p-2">
          <ShipDesignDetails></ShipDesignDetails>

          <div v-if="shipDesignStore.designError">
            <div class="text-red-500 p-2 mt-2">
            {{ shipDesignStore.designError }}
            </div>
          </div>

          <ShipDesignForm></ShipDesignForm>
        </div>
      </div>
      <div>
        <div class="content-header">Selected components</div>
        <div class="p-2 gap-4 grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 model-content overflow-y-auto content-start">
          <template v-for="selectedComponent in shipDesignStore.components" :key="selectedComponent.id">
            <ShipDesignComponentSelected :componentCode="selectedComponent.component.code" :index="selectedComponent.id"/>
          </template>
        </div>
      </div>
      <div>
        <div class="content-header">Available components</div>
        <div class="p-2 gap-4 grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 model-content overflow-y-auto content-start">
          <template v-for="(value, index) in sortedAvailable" :key="index">
            <div v-if="isAvailable(value[0])">
              <SignDesignComponentAvailable :componentCode="value[0]" />
            </div>
          </template>
        </div>
      </div>
    </div>
  </FullScreenModel>

</template>

<style scoped lang="scss">
.model-content{
  height: calc(100vh - 8rem);
}
</style>