<script setup lang="ts">
import { useMapStore } from "@/stores/mapStore.ts";
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import { zoomFactor } from '@/enums/zoomFactor.ts'

const mapStore = useMapStore();

const zoom = (zoom: number) => {
  mapStore.zoomFactor = zoom
};

const presets = [
  { name: 'Planet', value: zoomFactor.Planet },
  { name: 'Solar', value: zoomFactor.Solar },
  { name: 'Local', value: zoomFactor.Local },
  { name: 'Sector', value: zoomFactor.Sector },
  { name: 'Quadrant', value: zoomFactor.Quadrant },
  { name: 'Max', value: zoomFactor.Max },
];
</script>

<template>
  <div class="pt-2 pointer-events-auto hidden sm:block">
    <div class="inline-flex">
      <div  v-for="preset in presets" :key="preset.value">
        <StandardButton
          :class="{
            'bg-slate-700': mapStore.zoomFactor === preset.value
          }"
          @click="zoom(preset.value)">
          {{ preset.name }}
        </StandardButton>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
/* Add any additional styles if needed */
</style>
