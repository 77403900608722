import {defineStore} from 'pinia'
import { markRaw } from 'vue'
import * as THREE from 'three'
import type MapCoordinate from '@/types/MapCoordinate.ts'
import { updateCoordinateShips } from '@/models/Map/mapShipScaleAndPositionCalculations.ts'

export const useMapShipStore = defineStore('mapShipStore', {
    state: () => ({
        shipInstancedMesh: null as THREE.InstancedMesh<THREE.BufferGeometry, THREE.ShaderMaterial> | null,
        shipAvailableInstanceIds: markRaw<number[]>([]),
        shipMapCoordinateLookup: markRaw(new Map())
    }),
    actions: {
        initShipInstancedMesh(maxCount: number) {
            // Create a simple triangle geometry using BufferGeometry
            const geometry2 = new THREE.BufferGeometry();
            const vertices = new Float32Array([
                0.0, 0.1, 0.0,  // Top point
                0.0, -0.1, 0.0, // Bottom point
                0.2, 0.0, 0.0   // Right point (more pointy triangle)
            ]);

            geometry2.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

            const material2 = new THREE.ShaderMaterial({
                vertexShader: `
        attribute vec3 instanceColor;
        varying vec3 vColor;
    
        void main() {
          vColor = instanceColor;  // Pass the color to the fragment shader
          vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
          gl_Position = projectionMatrix * mvPosition;
        }
    `,
                fragmentShader: `
        varying vec3 vColor;
    
        void main() {
          // Discard the fragment if color is default (i.e., black or zero)
          if (vColor.r == 0.0 && vColor.g == 0.0 && vColor.b == 0.0) {
              discard;  // Skip rendering this fragment
          }

          gl_FragColor = vec4(vColor, 1.0);  // Use the passed color for the fragment
        }
    `,
                vertexColors: true,
            });

            const colors = new Float32Array(maxCount * 3);  // 3 values per instance (r, g, b)
            geometry2.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(colors, 3));

            

            this.shipInstancedMesh = markRaw(new THREE.InstancedMesh(geometry2, material2, maxCount));

            const dummy = new THREE.Object3D();

            for(let i:number = 0; i < maxCount; ++i) {
                dummy.position.set(-10000, -10000, -10000);  // Out of visible range
                dummy.updateMatrix();
                this.shipInstancedMesh.setMatrixAt(i, dummy.matrix);
                this.shipAvailableInstanceIds.push(i);
            }

            this.shipInstancedMesh.instanceMatrix.needsUpdate = true;

            return this.shipInstancedMesh
        },
        addShip(x: number, y: number,color: number):number {
            if(this.shipInstancedMesh) {
                const instanceId = this.getAvailableShipInstanceId();
                if (instanceId !== null && instanceId !== undefined) {
                    // Reuse the same dummy Object3D instance
                    const dummy = new THREE.Object3D();  // Or reuse a global dummy object

                    // Set the new position for the instance
                    dummy.position.set(x, y, 0.1);
                    dummy.updateMatrix();

                    // Apply the matrix to the instanced mesh at the specific instance ID
                    this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

                    const colorAttribute = this.shipInstancedMesh.geometry.attributes.instanceColor;
                    if (colorAttribute) {
                        // Update the color buffer with the new color
                        const color2 = new THREE.Color(color);

                        colorAttribute.setXYZ(instanceId, color2.r, color2.g, color2.b);  // Assuming color is a THREE.Color object
                        colorAttribute.needsUpdate = true;
                    }

                    // Mark the instance matrix for update
                    this.shipInstancedMesh.instanceMatrix.needsUpdate = true;

                    // Store the map coordinate for later reference
                    this.shipMapCoordinateLookup.set(instanceId, { x: x, y: y });

                    return instanceId
                }
            }
            return 0;
        },
        removeShip(instanceId: number) {
            if(this.shipInstancedMesh) {
                const dummy = new THREE.Object3D();

                // Move the ship out of visible range and set its scale to 0 (effectively hiding it)
                dummy.position.set(-10000, -10000, -10000);  // Out of visible range
                //dummy.scale.set(0, 0, 0);  // Scale to 0 to make it invisible
                dummy.updateMatrix();
                this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

                // Reset the color buffer for the instance to black (which the shader will discard)
                const colorAttribute = this.shipInstancedMesh.geometry.attributes.instanceColor;
                if (colorAttribute) {
                    const blackColor = new THREE.Color(0x000000);  // Black color (0, 0, 0)
                    colorAttribute.setXYZ(instanceId, blackColor.r, blackColor.g, blackColor.b);
                    colorAttribute.needsUpdate = true;
                }

                // Mark the instance matrix for update
                this.shipInstancedMesh.instanceMatrix.needsUpdate = true;

                // Release the instance ID back into the pool
                this.releaseShipInstanceId(instanceId);
            }
        },
        getAvailableShipInstanceId(): number {
            if(this.shipAvailableInstanceIds.length === 0) {
                throw new Error('No more available instance ids for ship objects');
            }
            return this.shipAvailableInstanceIds.pop()!;
        },
        releaseShipInstanceId(instanceId: number) {
            this.shipAvailableInstanceIds.push(instanceId)
            this.shipMapCoordinateLookup.delete(instanceId);
        },
        scaleAndPositionShip(instanceId: number, mapCoordinate: MapCoordinate,totalPositions:number, position:number, zoomFactor: number) {
            if(this.shipInstancedMesh) {
                const dummy = new THREE.Object3D();

                const data = updateCoordinateShips(mapCoordinate, totalPositions, position, zoomFactor);
                dummy.position.set(data.x, data.y, 0.1)  // You can modify the z position if needed
                dummy.scale.set(data.scale, data.scale, data.scale);  // Apply uniform scaling
                dummy.updateMatrix();
                this.shipInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                this.shipInstancedMesh.instanceMatrix.needsUpdate = true;
            }
        },
        findCoordinatesByInstanceId(instanceId: number): { x: number, y: number } {
            if(this.shipMapCoordinateLookup.has(instanceId)) {
                return this.shipMapCoordinateLookup.get(instanceId);
            }
            return {x: 0, y: 0}
        }
    }
});