<script setup lang="ts">
import { useTableStore } from '@/stores/tableStore.ts'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import PlanetIcon from '@/components/Objects/Icons/PlanetIcon.vue'
import Icon from '@/components/Objects/Icons/Icon.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import { useMapCoordinateStore } from '@/stores/mapCoordinateStore.ts'
import { useMapStore } from '@/stores/mapStore.ts'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import { useShipStore } from '@/stores/shipStore.ts'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import PlanetCompatibility from '@/components/Objects/PlanetCompatibility.vue'

const mapCoordinateStore = useMapCoordinateStore();
const mapStore = useMapStore();
const shipStore = useShipStore();
const tableStore = useTableStore();

// Load filtered ships list
const filters = (coords:Map<number,any>) => {
  return Array.from(coords.values())
    .filter(coord => coord.playerId === 0)
    .filter((coord) => {
      // If no search query, return all
      if (!tableStore.mapCoords.filters.nameSearch || tableStore.mapCoords.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return coord.name.toLowerCase().includes(tableStore.mapCoords.filters.nameSearch.toLowerCase());
    })
    .filter((coord) => {
      if(tableStore.mapCoords.filters.selectedCompatible === '') {
        return true;
      }

      if(tableStore.mapCoords.filters.selectedCompatible === coord.compatibility) {
        return true;
      }
    });
};

const onClick = (id:number) => {
  const data = mapCoordinateStore.planets.get(id);
  mapStore.focusMap(data?.xPos, data?.yPos);
};

</script>

<template>
  <FerionDataTable
    :data="mapCoordinateStore.planets"
    tableStoreName="mapCoords"
    :selector="false"
    :filters="filters"
    :heightLimit="'h-[calc(100vh-7.9rem)]'"
    :click="onClick"
  >
    <!--- ActionBar --->
    <template #actionBar>
      <ActionBarFiller class="mr-1"></ActionBarFiller>
      <ActionBarSearch v-model="tableStore.mapCoords.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1" width="16"/>
      <ActionBarSelect name="Compatibility" width="6" v-model="tableStore.mapCoords.filters.selectedCompatible" class="mr-1" >
        <option value="" selected>All</option>
        <option value="A">Paradise</option>
        <option value="B">Lush</option>
        <option value="C">Habitable</option>
        <option value="D">Incompatible</option>
        <option value="E">Toxic</option>
      </ActionBarSelect>
    </template>
    <!--- Headers --->
    <template #columns="{ sort, sortColumn, sortDirection }">
      <HeaderFixedWidthIcon width="4" column="type" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        <div class="flex justify-center">
          <PlanetIcon :size="2" :type="5"></PlanetIcon>
        </div>
      </HeaderFixedWidthIcon>
      <HeaderTextFlex min-width="12"  column="name" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
        Name
      </HeaderTextFlex>
      <HeaderFixedWidthText width="6" column="eta" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center"  title="Distance from selected Ship/Fleet">
        Distance
      </HeaderFixedWidthText>
      <HeaderFixedWidthText width="6" column="compatibility" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
        Compatibility
      </HeaderFixedWidthText>
      <HeaderFixedWidthTextNoSort width="3" align="center">
        <Icon type="settle" title="Settler Inbound"/>
      </HeaderFixedWidthTextNoSort>
    </template>
    <!--- Rows --->
    <template #rows="{ item }" >
      <CellFixedWidth width="4">
        <PlanetIcon :size="item.size" :type="item.type"></PlanetIcon>
      </CellFixedWidth>
      <CellFlexWidth min-width="12">
        {{ item.name }}
      </CellFlexWidth>
      <CellFixedWidth width="6" align="center">
        <span class="text-subtext">{{ item.eta>0 && item.eta !== Infinity ? item.eta:'-' }}</span>
      </CellFixedWidth>
      <CellFixedWidth width="6" align="center">
        <PlanetCompatibility :compatibility="item.compatibility"/>
      </CellFixedWidth>
      <CellFixedWidth width="3" class="text-subtext" align="center">
        <Icon v-if="shipStore.isPlanetTargetedForSettlement(item.xPos,item.yPos)" type="settle" title="Settler Inbound"/>
      </CellFixedWidth>
    </template>

  </FerionDataTable>
</template>

<style scoped lang="scss">

</style>