<template>
  <div>
    <label class="block mb-2 text-sm font-medium text-white">{{ label }}</label>
    <input
        :type="type"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :class="inputClass"
        :placeholder="placeholder"
        ref="formFocus"
    />
    <div class="pt-1 pb-1 text-red-500" v-if="error">{{ error }}</div>
    <div v-else>&nbsp;</div>
  </div>
</template>

<script setup lang="ts">
import {nextTick, onMounted, ref} from 'vue';

const props = defineProps({
  modelValue: String,
  label: String,
  type: {
    type: String,
    default: 'text'
  },
  placeholder: String,
  error: String,
  inputClass: {
    type: String,
    default: 'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 bg-gray-700 border-gray-600 dark:placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500'
  }
});

const formFocus = ref();

onMounted(() => {
  //auto focus the input field
  nextTick().then(() => {
    formFocus.value.focus();
  });
})

</script>

<style scoped>
/* Add any specific styles here */
</style>
