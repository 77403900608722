<script setup lang="ts">

import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'
import BuildingEffects from '@/components/Objects/Building/BuildingEffects.vue'

const props = defineProps<{
  buildingCode: string
}>();

const assetStore = useAssetStore()

const building = computed(() => {
  return assetStore.findBuildingByCode(props.buildingCode)
});
</script>

<template>
  <div class="content-header flex justify-between">
    <div>
      {{ building.buildingName }}
    </div>
    <div class="text-slate-500">
      Building
    </div>
  </div>
  <div class="p-2">
    <BuildingEffects :building="building"> </BuildingEffects>
  </div>
</template>

<style scoped lang="scss">

</style>