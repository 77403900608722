<script setup lang="ts">

import { computed } from 'vue'
import { useShipStore } from '@/stores/shipStore.ts'

const props = defineProps({
  shipDesignId: Number
});

const shipStore = useShipStore()

const number = computed(() => {
  return shipStore.getNumberOfShipsUsingDesignId(props.shipDesignId)
})

</script>

<template>
  <span v-if="number>0" class="text-yellow-500">{{ number }}</span>
</template>

<style scoped lang="scss">

</style>