<script setup lang="ts">

import { useFleetStore } from '@/stores/fleetStore.ts'
import { useShipStore } from '@/stores/shipStore.ts'

const shipStore = useShipStore()
const fleetStore = useFleetStore()

const tabs = [
  {
    'id': 'ships',
    'name': 'Ships',
    'target' : '/command/ships',
    'activeKey': 'CommandShips'
  },
  {
    'id': 'fleets',
    'name': 'Fleets',
    'target' : '/command/fleets',
    'activeKey': 'CommandFleets'
  },
  {
    'id': 'designs',
    'name': 'Ship designs',
    'target' : '/command/ship-designs',
    'activeKey': 'CommandShipDesigns'
  }
]

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <router-link
          v-for="tab in tabs"
          :key="tab.name"
          :to="tab.target"
          :class="{
            'active text-blue-500 border-blue-500': $route.name?.toString().startsWith(tab.activeKey),
            'text-gray-500 border-transparent': !$route.name?.toString().startsWith(tab.activeKey),
            'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
          }"
        >
          {{ tab.name }}
          <span v-if="tab.id === 'ships' && shipStore.countShipsWithoutFleet()>0" class="text-white">({{ shipStore.countShipsWithoutFleet() }})</span>
          <span v-if="tab.id === 'fleets' && shipStore.countFleetsWithShips()>0" class="text-white">({{ shipStore.countFleetsWithShips() }})</span>
        </router-link>
      </li>
    </ul>
  </div>
  <router-view></router-view>
</template>

<style scoped>

</style>