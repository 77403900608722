<script setup lang="ts">

const props = defineProps({
  compatibility: String,
  short: {
    type: Boolean,
    default: false,
    required: false
  }
});

</script>

<template>
<span v-if="props.compatibility==='A'" class="text-green-500" title="Paradise">
  <span v-if="short" class="text-xs relative -top-0.5">&#9679;</span><span v-else>Paradise</span>
</span>
<span v-else-if="props.compatibility==='B'" class="text-cyan-500" title="Lush">
  <span v-if="short" class="text-xs relative -top-0.5">&#9679;</span><span v-else>Lush</span>
</span>
<span v-else-if="props.compatibility==='C'" class="text-subtext" title="Habitable">
  <span v-if="short" class="text-xs relative -top-0.5">&#9679;</span><span v-else>Habitable</span>
</span>
<span v-else-if="props.compatibility==='D'" class="text-amber-400" title="Incompatible">
  <span v-if="short" class="text-xs relative -top-0.5">&#9679;</span><span v-else>Incompatible</span>
</span>
<span v-else-if="props.compatibility==='E'" class="text-red-500" title="Toxic">
  <span v-if="short" class="text-xs relative -top-0.5">&#9679;</span><span v-else>Toxic</span>
</span>
</template>

<style scoped lang="scss">

</style>