import {defineStore} from 'pinia'
import { markRaw } from 'vue'
import * as THREE from 'three'

export const useMapPlanetStore = defineStore('mapPlanetStore', {
    state: () => ({
        planetInstancedMesh: null as THREE.InstancedMesh<THREE.PlaneGeometry, THREE.ShaderMaterial> | null,
        planetAvailableInstanceIds: markRaw<number[]>([]),
        planetMapCoordinateLookup: markRaw(new Map())
    }),
    actions: {
        initPlanetInstancedMesh(maxCount: number) {
            const geometry2 = new THREE.PlaneGeometry(1, 1);  // Use PlaneGeometry instead of CircleGeometry

            // Load the sprite sheet texture
            const textureLoader = new THREE.TextureLoader();
            const spriteTexture = textureLoader.load('https://cdn.galexion.com/images/map/planet_sprite_sheet_256.webp');

            const material2 = new THREE.ShaderMaterial({
                uniforms: {
                    spriteTexture: { value: spriteTexture },  // Pass the sprite texture as a uniform
                },
                vertexShader: `
            attribute vec3 instanceColor;
            attribute vec2 instanceUVOffset;  // UV offset for the sprite
            varying vec3 vColor;
            varying vec2 vUv;
        
            void main() {
              vColor = instanceColor;  // Pass the color to the fragment shader

              // Calculate the UV coordinates based on the instance's offset
              vUv = instanceUVOffset + uv / vec2(7.0, 5.0);  // Adjust based on the grid size (4x4)

              vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
              gl_Position = projectionMatrix * mvPosition;
            }
        `,
                fragmentShader: `
            uniform sampler2D spriteTexture;  // The sprite sheet texture
            varying vec3 vColor;
            varying vec2 vUv;

            void main() {
              vec4 textureColor = texture2D(spriteTexture, vUv);  // Sample the texture at the UV coordinates
              gl_FragColor = vec4(vColor * textureColor.rgb, textureColor.a);  // Combine instance color with texture color
            }
        `,
                vertexColors: true,
                transparent: true  // Enable transparency if the sprite has transparency
            });

            // Generate the instance color attribute
            const colors = new Float32Array(maxCount * 3);  // 3 values per instance (r, g, b)
            geometry2.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(colors, 3));

            // Generate the UV offset attribute for each instance
            const uvOffsets = new Float32Array(maxCount * 2);  // 2 values per instance (u, v offset)
            geometry2.setAttribute('instanceUVOffset', new THREE.InstancedBufferAttribute(uvOffsets, 2));

            // Initialize the InstancedMesh
            this.planetInstancedMesh = markRaw(new THREE.InstancedMesh(geometry2, material2, maxCount));
            this.planetInstancedMesh.name = 'ownerCircle';

            const dummy = new THREE.Object3D();

            // Setup initial positions and UV offsets for the instances
            for (let i = 0; i < maxCount; ++i) {
                // Set dummy position out of range
                dummy.position.set(-10000, -10000, -10000);
                dummy.updateMatrix();
                this.planetInstancedMesh.setMatrixAt(i, dummy.matrix);

                // // Assign UV offsets for each instance (using a 4x4 sprite grid as an example)
                // const col = i % 4;  // 4 columns
                // const row = Math.floor(i / 4) % 4;  // 4 rows
                // uvOffsets[i * 2] = col / 4;  // U offset
                // uvOffsets[i * 2 + 1] = row / 4;  // V offset

                this.planetAvailableInstanceIds.push(i);
            }

            this.planetInstancedMesh.instanceMatrix.needsUpdate = true;
            geometry2.attributes.instanceUVOffset.needsUpdate = true;

            return this.planetInstancedMesh;
        },
        addPlanet(x: number, y: number, type: number, size: number): number {
            if(this.planetInstancedMesh) {
                // Adjust the type to be 0-based
                type = type - 1

                const instanceId = this.getAvailablePlanetInstanceId();
                if (instanceId !== null && instanceId !== undefined) {
                    // Reuse the same dummy Object3D instance
                    const dummy = new THREE.Object3D();  // Or reuse a global dummy object

                    // Set the new position for the instance
                    dummy.position.set(x, y, 0);
                    dummy.scale.set(3, 3, 3);  // Apply scaling based on the size parameter
                    dummy.updateMatrix();

                    // Apply the matrix to the instanced mesh at the specific instance ID
                    this.planetInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

                    // Update the color buffer with the new color
                    const color = 0xFFFFFF;  // Green color for the player's planets
                    const colorAttribute = this.planetInstancedMesh.geometry.attributes.instanceColor;
                    if (colorAttribute) {
                        const color2 = new THREE.Color(color);
                        colorAttribute.setXYZ(instanceId, color2.r, color2.g, color2.b);
                        colorAttribute.needsUpdate = true;
                    }

                    // Update the UV offset buffer based on the type and size (to pick the right sprite from the sheet)
                    const uvOffsetAttribute = this.planetInstancedMesh.geometry.attributes.instanceUVOffset;
                    if (uvOffsetAttribute) {
                        const col = type % 7;  // 7 types horizontally, get the column
                        const row = (5 - size);  // 5 sizes vertically, size 1 is at the top

                        const uOffset = col / 7;  // Calculate the U offset based on 7 columns
                        const vOffset = row / 5;  // Calculate the V offset based on 5 rows

                        uvOffsetAttribute.setXY(instanceId, uOffset, vOffset);  // Set UV offset for this instance
                        uvOffsetAttribute.needsUpdate = true;
                    }


                    // Mark the instance matrix for update
                    this.planetInstancedMesh.instanceMatrix.needsUpdate = true;

                    // Store the instanceId for the mapCoordinate (so we can reverse lookup it later)
                    this.planetMapCoordinateLookup.set(instanceId, { x: x, y: y });

                    return instanceId;
                }
            }

            return 0;
        },
        removePlanet(instanceId: number) {
            if(this.planetInstancedMesh) {
                const dummy = new THREE.Object3D();
                dummy.position.set(10000, 10000, 10000);  // Out of visible range
                dummy.updateMatrix();
                this.planetInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                this.releasePlanetInstanceId(instanceId);
                this.planetInstancedMesh.instanceMatrix.needsUpdate = true;
            }
        },
        getAvailablePlanetInstanceId(): number {
            if(this.planetAvailableInstanceIds.length === 0) {
                throw new Error('No more available instance ids for planet objects');
            }
            return this.planetAvailableInstanceIds.pop()!;
        },
        releasePlanetInstanceId(instanceId: number) {
            this.planetAvailableInstanceIds.push(instanceId)
            this.planetMapCoordinateLookup.delete(instanceId);
        },
        findCoordinatesByInstanceId(instanceId: number): { x: number, y: number } {
            if(this.planetMapCoordinateLookup.has(instanceId)) {
                return this.planetMapCoordinateLookup.get(instanceId);
            }
            return {x: 0, y: 0}
        }
    }
});