<script setup lang="ts">
import {computed, defineComponent, defineProps, ref} from "vue";
import { useFleetStore } from '@/stores/fleetStore.ts'
import FleetActions from '@/components/Sections/Command/Fleets/FleetDetails/FleetActions.vue'
import ShipsList from '@/components/Sections/Command/Ships/ShipsList.vue'

const props = defineProps({
  fleetId: Number
});

const currentTab = ref('actions')

</script>

<template>
  <div class="text-sm font-medium text-center border-b text-gray-400 border-gray-700">
    <ul class="flex flex-wrap">
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'actions',
               'text-blue-500 border-blue-500': currentTab === 'actions',
               'text-gray-500 border-transparent': currentTab !== 'actions',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'actions'">
          Actions
        </a>
      </li>
      <li class="me-2">
        <a href="#"
           :class="{
               'active': currentTab === 'ships',
               'text-blue-500 border-blue-500': currentTab === 'ships',
               'text-gray-500 border-transparent': currentTab !== 'ships',
               'inline-block p-4 border-b-2 rounded-t-lg hover:border-gray-300 hover:text-gray-300': true
           }"
           @click="currentTab = 'ships'">
          Ships
        </a>
      </li>
    </ul>
  </div>
  <div v-if="currentTab === 'ships'" class="content-height-limit-fleet-details ">
    <ShipsList :withDetails="false" :lockedToFleetId="props.fleetId" class="mt-1"></ShipsList>
  </div>
  <div v-if="currentTab === 'actions'" class="content-height-limit-fleet-details overflow-y-auto pr-1">
    <FleetActions :fleetId="props.fleetId" />
  </div>
</template>

<style scoped lang="scss">
.nav-item {
  cursor: pointer;
}
</style>
