<script setup lang="ts">

import { useOreConversionStore } from '@/stores/oreConversionStore.ts'
import OreTrade from '@/components/Sections/Resources/Elements/OreTrade.vue'

const oreConversionStore = useOreConversionStore()

const debug = false
</script>

<template>

  <!--- Level 1 --->

  <OreTrade
    :left="2.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="1"
    :debug="debug"
  />

  <OreTrade
    :left="12.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="2"
    :debug="debug"
  />

  <OreTrade
    :left="22.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="3"
    :debug="debug"
  />

  <OreTrade
    :left="32.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="4"
    :debug="debug"
  />

  <OreTrade
    :left="42.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="5"
    :debug="debug"
  />

  <OreTrade
    :left="52.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="6"
    :debug="debug"
  />

  <OreTrade
    :left="62.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="7"
    :debug="debug"
  />

  <OreTrade
    :left="72.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="8"
    :debug="debug"
  />

  <OreTrade
    :left="82.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="9"
    :debug="debug"
  />

  <OreTrade
    :left="92.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="10"
    :debug="debug"
  />

  <OreTrade
    :left="102.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="11"
    :debug="debug"
  />

  <OreTrade
    :left="112.5"
    :top="16"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="12"
    :debug="debug"
  />

  <!--- Level 2 --->

  <OreTrade
    :left="7.5"
    :top="48"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="13"
    :debug="debug"
  />

  <OreTrade
    :left="27.5"
    :top="48"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="14"
    :debug="debug"
  />

  <OreTrade
    :left="47.5"
    :top="48"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="15"
    :debug="debug"
  />

  <OreTrade
    :left="67.5"
    :top="48"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="16"
    :debug="debug"
  />

  <OreTrade
    :left="87.5"
    :top="48"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="17"
    :debug="debug"
  />


  <OreTrade
    :left="107.5"
    :top="48"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="18"
    :debug="debug"
  />

  <!--- Level 3 --->

  <OreTrade
    :left="17.5"
    :top="80"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="19"
    :debug="debug"
  />

  <OreTrade
    :left="57.5"
    :top="80"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="20"
    :debug="debug"
  />

  <OreTrade
    :left="97.5"
    :top="80"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="21"
    :debug="debug"
  />

  <!--- Level 4 --->

  <OreTrade
    :left="37.5"
    :top="112"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="22"
    :debug="debug"
  />

  <OreTrade
    :left="77.5"
    :top="112"
    :conversion-in="oreConversionStore.data.planetDesertToNucloid"
    :conversion-out="oreConversionStore.data.planetDesertToVerrotan"
    :ore-id="23"
    :debug="debug"
  />

</template>

<style scoped lang="scss">

</style>