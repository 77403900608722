<script setup lang="ts">
import { useShipActionStore } from '@/stores/shipActionStore.ts'
import Icon from '@/components/Objects/Icons/Icon.vue'
import IconCount from '@/components/Objects/IconCount.vue'
import { useShipStore } from '@/stores/shipStore.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import ActionBarBlock from '@/components/Interface/DataTable/ActionBar/ActionBarBlock.vue'
import ActionBarFiller from '@/components/Interface/DataTable/ActionBar/ActionBarFiller.vue'
import CellFlexWidth from '@/components/Interface/DataTable/Cell/CellFlexWidth.vue'
import CellFixedWidth from '@/components/Interface/DataTable/Cell/CellFixedWidth.vue'
import FerionDataTable from '@/components/Interface/DataTable/FerionDataTable.vue'
import ActionBarSelect from '@/components/Interface/DataTable/ActionBar/ActionBarSelect.vue'
import HeaderFixedWidthIcon from '@/components/Interface/DataTable/Headers/HeaderFixedWidthIcon.vue'
import HeaderFixedWidthText from '@/components/Interface/DataTable/Headers/HeaderFixedWidthText.vue'
import HeaderTextFlex from '@/components/Interface/DataTable/Headers/HeaderTextFlex.vue'
import ActionBarSearch from '@/components/Interface/DataTable/ActionBar/ActionBarSearch.vue'
import { useTableStore } from '@/stores/tableStore.ts'
import MapFleetShipMultiSelectMenu from '@/components/Interface/Menu/MapFleetShipMultiSelectMenu.vue'
import type PlayerShip from '@/types/PlayerShip.ts'
import Percentage from '@/components/Interface/Text/Percentage.vue'
import HeaderFixedWidthTextNoSort from '@/components/Interface/DataTable/Headers/HeaderFixedWidthTextNoSort.vue'
import StandardButton from '@/components/Interface/Buttons/StandardButton.vue'
import Backend from '@/models/backend.ts'

const gameStore = useGameStore();
const tableStore = useTableStore();
const shipStore = useShipStore()
const shipActionStore = useShipActionStore()

const props = defineProps<{
  fleetId: number,
  xPos: number,
  yPos: number
}>()

// Load filtered ships list
const filters = (ships:Map<number,PlayerShip>) => {
  return Array.from(ships.values())
    .filter((ship) => {
      if(ship.fleetId === props.fleetId && ship.xPos === props.xPos && ship.yPos === props.yPos) {
        return true;
      }
    })
    .filter((ship) => {
      // If no category is selected, return all ships
      if (!tableStore.mapShips.filters.selectedAction || tableStore.mapShips.filters.selectedAction === '') {
        return true;
      }
      // Otherwise, filter based on the selected category
      return ship.currentActionName === tableStore.mapShips.filters.selectedAction;
    })
    .filter((ship) => {
      // If no search query, return all ships
      if (!tableStore.mapShips.filters.nameSearch || tableStore.mapShips.filters.nameSearch === '') {
        return true;
      }
      // Otherwise, filter based on the ship name (case-insensitive search)
      return ship.shipName.toLowerCase().includes(tableStore.mapShips.filters.nameSearch.toLowerCase());
    });
};

// Double-click event
const doubleClick = (id:number) => {
  shipActionStore.initShipsFromIds([id]);
  tableStore.mapShips.selectedAll = false
  gameStore.modalType=''
};

const setFleetRallyPoint = () => {
  const backend = new Backend()
  backend.fleetSetRallyPoint(props.fleetId, props.xPos, props.yPos)
};

</script>

<template>
  <div class="py-1 text-white bg-slate-900">
    <FerionDataTable
      :data="shipStore.ships"
      tableStoreName="mapShips"
      :selector="true"
      :filters="filters"
      :heightLimit="'h-[calc(100vh-23.75rem)]'"
      :double-click="doubleClick"
    >
      <!--- ActionBar --->
      <template #actionBar>
        <ActionBarBlock class="mr-1 flex items-center">
          <MapFleetShipMultiSelectMenu></MapFleetShipMultiSelectMenu>
        </ActionBarBlock>
        <ActionBarFiller class="mr-1"></ActionBarFiller>
        <ActionBarSearch v-model="tableStore.mapShips.filters.nameSearch" name="Name" placeHolder="Search..." class="mr-1"/>
        <ActionBarSelect name="Type" width="6" v-model="tableStore.mapShips.filters.selectedType" class="mr-1">
          <option value="">All</option>
          <option value="scout">Scout</option>
          <option value="settler">Settler</option>
          <option value="bomber">Bomber</option>
          <option value="fighter">Fighter</option>
        </ActionBarSelect>
      </template>
      <!--- Headers --->
      <template #columns="{ sort, sortColumn, sortDirection }">
        <HeaderTextFlex min-width="12" column="shipName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          Name
        </HeaderTextFlex>
        <HeaderFixedWidthIcon width="5" column="healthPercentage" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_hit_points"></Icon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthIcon width="3" column="maxSpeed" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_max_speed"></Icon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthIcon width="3" column="shields" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_shields"></Icon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthIcon width="3" column="attackShips" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_attack_ships"></Icon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthIcon width="3" column="attackPlanets" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_attack_planets"></Icon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthIcon width="3" column="scanningRange" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_scanning_range"></Icon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthIcon width="3" column="repair" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          <Icon type="ship_repair"></Icon>
        </HeaderFixedWidthIcon>
        <HeaderFixedWidthText width="10" column="currentActionName" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort" align="center">
          Status
        </HeaderFixedWidthText>
        <HeaderFixedWidthTextNoSort width="8" align="center">
          Action
        </HeaderFixedWidthTextNoSort>
        <HeaderFixedWidthIcon width="3" column="etaNextHop" :sortColumn="sortColumn" :sortDirection="sortDirection" @sort="sort">
          ETA
        </HeaderFixedWidthIcon>
      </template>
      <!--- Rows --->
      <template #rows="{ item }" >
        <CellFlexWidth min-width="12">
          <div class="flex-col">
            <div>{{ item.shipName }}</div>
          </div>
        </CellFlexWidth>
        <CellFixedWidth width="5">
          <Percentage :value="item.healthPercentage"/>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.maxSpeed" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.shields" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.attackShips" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.attackPlanets" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.scanningRange" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          <IconCount :amount="item.repair" :no-plus="true"/>
        </CellFixedWidth>
        <CellFixedWidth width="10" align="center">
          <span v-if="item.catchingUpToFleet == 1" class="text-amber-400">
            Reinforcement
          </span>
          <span v-else class="text-green-500">
            Core Fleet
          </span>
        </CellFixedWidth>
        <CellFixedWidth width="8" align="center">
          <StandardButton v-if="item.catchingUpToFleet == 1" @click="setFleetRallyPoint">Set core</StandardButton>
        </CellFixedWidth>
        <CellFixedWidth width="3">
          {{ item.etaNextHop }}
        </CellFixedWidth>
      </template>
    </FerionDataTable>
  </div>
</template>

<style scoped lang="scss">
</style>