<script setup lang="ts">

import Icon from "@/components/Objects/Icons/Icon.vue";
import {defineProps} from "vue";
import router from "@/router";

const props = defineProps<{
  x: number,
  y: number,
}>();

const gotoMap = () => {
  router.push('/map/galaxy/' + props.x + '/' + props.y);
}

</script>

<template>
    <Icon type="map_location" :title="'' + props.x + ' x ' + props.y " class="cursor-pointer" @click="gotoMap"></Icon>
</template>

<style scoped lang="scss">

</style>