<script setup lang="ts">

import { formatShort } from '@/models/formatNumber.ts'
import { useGameStore } from '@/stores/gameStore.ts'
import { computed } from 'vue'
import { useAssetStore } from '@/stores/assetStore.ts'

const gameStore = useGameStore()
const assetStore = useAssetStore()

const props = defineProps({
  oreId: {
    type: Number,
    required: true
  },
  top: {
    type: Number,
    required: true
  },
  left: {
    type: Number,
    required: true
  },
  debug: {
    type: Boolean,
    required: false,
    default: false
  }
})

const ore = computed(() => {
  return assetStore.findOreById(props.oreId)
})


</script>

<template>



  <div :class="{
    'absolute ': true,
    'border' : debug
  }" :style="'top:' + top + 'rem;left:' + left + 'rem;width:5rem;z-index:14;'">
    <div class="text-center flex-col" :style="'width:5rem;'">
      <img alt="" :class="{
            'ms-2':true,
            'opacity-50':debug
          }" style="width:4rem;" :src="gameStore.cdn + '/images/icons/ore/' + oreId + '.png'">
      <div  class="bg-slate-800 border rounded border-slate-700">
        <div>{{ ore.oreName }}</div>
        <div class="text-subtext">{{ formatShort(234234234) }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>