import {defineStore} from 'pinia'
import { markRaw } from 'vue'
import * as THREE from 'three'

export const useMapStarStore = defineStore('mapStarStore', {
    state: () => ({
        starInstancedMesh: null as THREE.InstancedMesh<THREE.PlaneGeometry, THREE.ShaderMaterial> | null,
        starAvailableInstanceIds: markRaw<number[]>([]),
        starMapCoordinateLookup: markRaw(new Map())
    }),
    actions: {
        initStarInstancedMesh(maxCount: number) {
            const geometry2 = new THREE.PlaneGeometry(1, 1);  // Use PlaneGeometry instead of CircleGeometry

            // Load the sprite sheet texture
            const textureLoader = new THREE.TextureLoader();
            const spriteTexture = textureLoader.load('https://cdn.galexion.com/images/map/star_sprite_sheet_256.png');

            const material2 = new THREE.ShaderMaterial({
                uniforms: {
                    spriteTexture: { value: spriteTexture },  // Pass the sprite texture as a uniform
                },
                vertexShader: `
            attribute vec3 instanceColor;
            attribute vec2 instanceUVOffset;  // UV offset for the sprite
            varying vec3 vColor;
            varying vec2 vUv;
        
            void main() {
              vColor = instanceColor;  // Pass the color to the fragment shader

              // Calculate the UV coordinates based on the instance's offset
              vUv = instanceUVOffset + uv / vec2(10.0, 4.0);  // Adjust based on the grid size (4x4)

              vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(position, 1.0);
              gl_Position = projectionMatrix * mvPosition;
            }
        `,
                fragmentShader: `
            uniform sampler2D spriteTexture;  // The sprite sheet texture
            varying vec3 vColor;
            varying vec2 vUv;

            void main() {
              vec4 textureColor = texture2D(spriteTexture, vUv);  // Sample the texture at the UV coordinates
              gl_FragColor = vec4(vColor * textureColor.rgb, textureColor.a);  // Combine instance color with texture color
            }
        `,
                vertexColors: true,
                transparent: true  // Enable transparency if the sprite has transparency
            });

            // Generate the instance color attribute
            const colors = new Float32Array(maxCount * 3);  // 3 values per instance (r, g, b)
            geometry2.setAttribute('instanceColor', new THREE.InstancedBufferAttribute(colors, 3));

            // Generate the UV offset attribute for each instance
            const uvOffsets = new Float32Array(maxCount * 2);  // 2 values per instance (u, v offset)
            geometry2.setAttribute('instanceUVOffset', new THREE.InstancedBufferAttribute(uvOffsets, 2));

            // Initialize the InstancedMesh
            this.starInstancedMesh = markRaw(new THREE.InstancedMesh(geometry2, material2, maxCount));
            this.starInstancedMesh.name = 'ownerCircle';

            const dummy = new THREE.Object3D();

            // Setup initial positions and UV offsets for the instances
            for (let i:number = 0; i < maxCount; ++i) {
                // Set dummy position out of range
                dummy.position.set(-10000, -10000, -10000);
                dummy.updateMatrix();
                this.starInstancedMesh.setMatrixAt(i, dummy.matrix);

                // Assign UV offsets for each instance (using a 10x4 sprite grid)
                const col = i % 10;  // 10 columns
                const row = Math.floor(i / 10) % 4;  // 4 rows
                uvOffsets[i * 2] = col / 10;  // U offset (0 to 1 for 10 columns)
                uvOffsets[i * 2 + 1] = row / 4;  // V offset (0 to 1 for 4 rows)

                this.starAvailableInstanceIds.push(i);
            }


            this.starInstancedMesh.instanceMatrix.needsUpdate = true;
            geometry2.attributes.instanceUVOffset.needsUpdate = true;

            return this.starInstancedMesh;
        },
        addStar(x: number, y: number, starType: number, starSize: number): number {

            if(this.starInstancedMesh) {
                // adjust the type to be 0-based
                starType = starType - 1;

                // Convert size from backend to size for the sprite sheet
                let size = 1;
                if (starSize == 4) {
                    size = 1
                } else if (starSize == 8) {
                    size = 2
                } else if (starSize == 15) {
                    size = 3
                } else if (starSize == 20) {
                    size = 4
                } else {
                    alert('unknown starsize ' + starSize)
                }

                const instanceId = this.getAvailableStarInstanceId();
                if (instanceId !== null && instanceId !== undefined) {
                    // Reuse the same dummy Object3D instance
                    const dummy = new THREE.Object3D();  // Or reuse a global dummy object

                    // Set the new position for the instance
                    dummy.position.set(x, y, 0);
                    dummy.scale.set(40, 40, 40);  // Apply scaling based on the size parameter
                    dummy.updateMatrix();

                    // Apply the matrix to the instanced mesh at the specific instance ID

                    this.starInstancedMesh.setMatrixAt(instanceId, dummy.matrix);

                    // Update the color buffer with the new color
                    const color = 0xFFFFFF;  // Green color for the player's planets
                    const colorAttribute = this.starInstancedMesh.geometry.attributes.instanceColor;
                    if (colorAttribute) {
                        const color2 = new THREE.Color(color);
                        colorAttribute.setXYZ(instanceId, color2.r, color2.g, color2.b);
                        colorAttribute.needsUpdate = true;
                    }

                    // Update the UV offset buffer based on the type and size (to pick the right sprite from the sheet)
                    const uvOffsetAttribute = this.starInstancedMesh.geometry.attributes.instanceUVOffset;

                    if (uvOffsetAttribute) {
                        // Calculate the column and row for the UV offsets
                        const col = starType % 10;  // 10 types horizontally (0-9), this gives the column index
                        const row = 4 - size;  // 4 sizes vertically (1-4), size 1 is at the top (row 3), size 4 is at the bottom (row 0)

                        // Calculate the UV offsets
                        const uOffset = col / 10;  // U offset is the horizontal position in the grid (divided by 10 columns)
                        const vOffset = row / 4;   // V offset is the vertical position in the grid (divided by 4 rows)

                        // Set the UV offset for the specific instance
                        uvOffsetAttribute.setXY(instanceId, uOffset, vOffset);
                        uvOffsetAttribute.needsUpdate = true;
                    }

                    // Mark the instance matrix for update
                    this.starInstancedMesh.instanceMatrix.needsUpdate = true;

                    // Store the instanceId for the mapCoordinate (so we can reverse lookup it later)
                    this.starMapCoordinateLookup.set(instanceId, { x: x, y: y });

                    return instanceId;
                }
            }
            return 0;
        },
        removeStar(instanceId: number) {
            if(this.starInstancedMesh) {
                const dummy = new THREE.Object3D();
                dummy.position.set(10000, 10000, 10000);  // Out of visible range
                dummy.updateMatrix();
                this.starInstancedMesh.setMatrixAt(instanceId, dummy.matrix);
                this.releaseStarInstanceId(instanceId);
                this.starInstancedMesh.instanceMatrix.needsUpdate = true;
            }
        },
        getAvailableStarInstanceId(): number {
            if (this.starAvailableInstanceIds.length === 0) {
                throw new Error('No more available instance ids for stars');
            }
            return this.starAvailableInstanceIds.pop()!;
        },
        releaseStarInstanceId(instanceId: number) {
            this.starAvailableInstanceIds.push(instanceId)
            this.starMapCoordinateLookup.delete(instanceId);
        },
        findCoordinatesByInstanceId(instanceId: number): { x: number, y: number } {
            if(this.starMapCoordinateLookup.has(instanceId)) {
                return this.starMapCoordinateLookup.get(instanceId);
            }
            return {x: 0, y: 0}
        }
    }
});