import Pusher from 'pusher-js'
import { useGameStore } from '@/stores/gameStore'
import PublicRouter from '@/models/router/public-router'
import type PublicRouterUpdate from '@/types/PublicRouterUpdate'
import PrivateRouter from '@/models/router/private-router'
import Backend from '@/models/backend'
import pako from 'pako'


export default class WebSocketHandler {
    private pusher: Pusher;
    private gameStore = useGameStore();
    private publicChannelMessageCounter = 0;
    private privateChannelMessageCounter = 0;
    private backend = new Backend();
    private lastTimestamp = Date.now();
    private sleepThreshold = 5 * 60 * 1000; // 5 minute

    constructor() {
        console.log('connect to ' + import.meta.env.VITE_WS_HOST);
        this.gameStore.loadingText = 'Connecting'

        this.pusher = new Pusher('app-key', {
            wsPort: import.meta.env.VITE_WS_PORT,
            wsHost: import.meta.env.VITE_WS_HOST,
            wssPort: import.meta.env.VITE_WSS_PORT,
            forceTLS: import.meta.env.VITE_WS_FORCE_TLS === 'true',
            disableStats: true,
            enabledTransports: ['ws','wss'],
            authEndpoint: import.meta.env.VITE_API_URL + 'auth/broadcast?token=' + this.gameStore.playerToken,
            cluster: 'mt1',
        });

        // Websocket connection established
        this.pusher.connection.bind('connected', () => {
            console.log('Websocket connected');
            if (!this.gameStore.websocketInitialSyncCompleted) {
                this.subscribeToChannels();
            }
            this.gameStore.loadingText = 'Syncing Assets'
        });

        // Websocket connection lost
        this.pusher.connection.bind('disconnected', () => {
            console.log('Websocket disconnected');
            this.outOfSync();
        });

        // Websocket error
        this.pusher.connection.bind('error', (err: string) => {
            console.log('Websocket error', err);
            this.outOfSync();
        });

        // unavailable
        this.pusher.connection.bind('unavailable', () => {
            console.log('Websocket unavailable');
            this.outOfSync();
        });

        // Check for wake-up from sleep state
        document.addEventListener('visibilitychange', this.checkForWakeUp.bind(this));
        setInterval(this.checkForWakeUp.bind(this), 1000);
    }

    private checkForWakeUp() {
        const now = Date.now();
        if (document.hidden) {
            // Page is not visible, update the timestamp
            this.lastTimestamp = now;
        } else {
            // Page is visible, check if the time difference exceeds the threshold
            if (now - this.lastTimestamp > this.sleepThreshold) {
                // The page was likely in a sleep state
                console.log('Page came back from a sleep state');
                this.outOfSync();
            }
            // Update the timestamp
            this.lastTimestamp = now;
        }
    }

    /**
     * Wait for a websocket connection to be established
     *
     * @param callback
     * @param interval
     */
    public waitForConnection(callback: () => void, interval: number) {
        if (this.checkIfPublicConnected() && this.checkIfPrivateConnected()) {
            callback();
        } else {
            setTimeout(() => {
                this.waitForConnection(callback, interval);
            }, interval);
        }
    }

    /**
     * Check if the channels are connected
     */
    public checkIfPublicConnected(): boolean {
        // check if channel exists
        if (this.pusher.channel('public') === undefined) {
            return false;
        }

        return this.pusher.channel('public').subscribed;
    }

    public checkIfPrivateConnected(): boolean {
        // check if channel exists
        if (this.pusher.channel('private-player-' + this.gameStore.authPlayerId) === undefined) {
            return false;
        }

        return this.pusher.channel('private-player-' + this.gameStore.authPlayerId).subscribed;
    }

    public subscribeToChannels() {
        // Subscribe to public channel
        const publicChannel = this.pusher.subscribe('public');
        publicChannel.bind('pusher:subscription_succeeded', () => {
            console.log('Subscribed to public channel');
        });
        publicChannel.bind('update', (data: PublicRouterUpdate) => {
            const decompressedData = this.decodeData(data.data);
            console.log('Public update', decompressedData);
            this.checkIfOutOfSync(data.message_count, 'public');
            const router = new PublicRouter();
            router.route(decompressedData.event, decompressedData.data);
        });

        // Subscribe to private channel
        const privateChannel = this.pusher.subscribe('private-player-' + this.gameStore.authPlayerId);
        privateChannel.bind('pusher:subscription_succeeded', () => {
            console.log('Subscribed to private channel : ' + 'private-player-' + this.gameStore.authPlayerId);
        });
        privateChannel.bind('update', (data: any) => {
            const decompressedData = this.decodeData(data.data);
            console.log('Private update', data.message_count);
            this.checkIfOutOfSync(data.message_count, 'private');
            const router = new PrivateRouter();
            Object.values(decompressedData).forEach((item) => {
                router.route(item.event, item.data);
            })
        });
    }

    public checkIfOutOfSync(messageCount: number, channel: string) {
        if (channel === 'public') {
            if (this.publicChannelMessageCounter === 0 || this.publicChannelMessageCounter === (messageCount - 1) || this.publicChannelMessageCounter === messageCount) {
                this.publicChannelMessageCounter = messageCount;
            } else {
                console.log('Public Channel Out of sync');
                this.outOfSync();
                this.publicChannelMessageCounter = messageCount;
            }
        } else {
            if (this.privateChannelMessageCounter === 0 || this.privateChannelMessageCounter === (messageCount - 1) || this.privateChannelMessageCounter === messageCount) {
                this.privateChannelMessageCounter = messageCount;
            } else {
                console.log('Private Channel Out of sync');
                this.outOfSync();
                this.privateChannelMessageCounter = messageCount;
            }
        }
    }

    private outOfSync() {
        if (!this.gameStore.gameOutOfSync) {
            this.gameStore.gameOutOfSync = true;
        }
    }

    // Decompression function
    private decodeData(compressedData) {
        // Decode base64 to binary string
        const compressedBytes = Uint8Array.from(atob(compressedData), c => c.charCodeAt(0));

        // Decompress using pako.inflateRaw(), because gzdeflate() doesn't include zlib headers
        const decompressedBytes = pako.inflateRaw(compressedBytes);

        // Convert bytes to string
        const jsonString = new TextDecoder('utf-8').decode(decompressedBytes);

        // Parse JSON string
        return JSON.parse(jsonString);
    }

}
